import { createStyles } from '@material-ui/core';

export default createStyles((theme) => ({
  buttonHover: {
    '&:hover': {
      background: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer',
    },
  },
}));
