import { createAction } from './common';
import types from '../types';

export const login = createAction(types.LOGIN);
export const logout = createAction(types.LOGOUT);
export const setAuthData = createAction(types.SET_AUTH_DATA);
export const clearAuthData = createAction(types.CLEAR_AUTH_DATA);
export const reconnect = createAction(types.RECONNECT_AUTH_DATA);
export const check = createAction(types.CHECK_AUTH_DATA);
export const getCurrentUser = createAction(types.GET_CURRENT_USER);
export const setCurrentUser = createAction(types.SET_CURRENT_USER);
export const clearCurrentUser = createAction(types.CLEAR_CURRENT_USER);
export const logoutByBlock = createAction(types.LOGOUT_BY_BLOCK);
