import { put } from 'redux-saga/effects';
// import {
//   setUser,
//   setUsers,
//   updateUsers,
//   updateUsersAfterCreate,
// } from '../actions/users';
import API from '../api';
import { setAccountsPay, getAccountsPayHistory } from '../actions/payments';
import { setIsLoading, setSettings } from '../actions/common';
import { history } from '../store';
import routes from '../routes';

export function* addAccountPaymentSaga(action) {
  try {
    const responce = yield API.rest.payments.setNewPayment(action.$payload);
    if (responce.id) {
      action.$payload.onFinish();
      yield put(getAccountsPayHistory({ size: 10, page: 0, sort: 'saveDate,desc' }));
    }
  } catch (err) {
    console.log('err', err);
  }
}
export function* getPaymentsHistorySaga(data) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.payments.getAllPayments({ ...data.$payload });
    const settings = {
      page: data.$payload.page, size: data.$payload.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setAccountsPay(json));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    console.log('err', err);
  }
}
export function* setFileCommitsSaga(action) {
  try {
    const responce = yield API.rest.payments.commitFilePayments(({ fileId: action.$payload.fileId }), ({ 'Content-Type': 'application/x-www-form-urlencoded' }));
    if (responce?.status === 200) {
      action.$payload.onFinish();
      yield put(getAccountsPayHistory());
    }
    // yield put(setAccountsPay(responce));
  } catch (err) {
    console.log('err', err);
  }
}

export function* cancelPaymentSaga({ $payload }) {
  try {
    yield API.rest.payments.cancelPayment($payload);
    history.push(routes.payments());
  } catch (err) {
    console.log('err', err);
  }
}
