import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import participantReducer from './participants';
import settingsReducer from './settings';
import usersReducer from './users';
import authReducer from './auth';
import errorsReducer from './errors';
import operationsReducer from './operations';
import dictionariesReducer from './dictionaries';
import currentUserReducer from './current-user';
import goodsReducer from './goods';
import markCodesReducer from './mark-codes';
import goodsProperties from './GoodsProperties';
import accountsProperties from './accounts';
import payments from './payments';
import charges from './charges';

export default (history) => combineReducers({
  router: connectRouter(history),
  participants: participantReducer,
  settings: settingsReducer,
  users: usersReducer,
  auth: authReducer,
  errors: errorsReducer,
  operations: operationsReducer,
  dictionaries: dictionariesReducer,
  currentUser: currentUserReducer,
  goods: goodsReducer,
  markCodes: markCodesReducer,
  goodsProperties,
  accountsProperties,
  payments,
  charges,
});
