import React from 'react';
import { Select } from '@crpt-ui/core';
import fp from 'lodash/fp';
import { Translate } from '../translate';

class SelectField extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !fp.isEqual(this.props, nextProps);
  }

  render() {
    const {
      data, input: {
        name, value, onChange, ...restInput
      }, meta, label, options, onSelect, ...rest
    } = this.props;
    return (
      <Select
        {...rest}
        margin="normal"
        name={name}
                // options={[{ label: 'Не выбрано', value: 'empty'}].concat(options)}
        options={options}
        label={Translate(label)}
        helperText={meta.error
                && (meta.dirty || meta.submitFailed) && meta.touched ? meta.error : undefined}
        error={meta.error && meta.touched}
        inputProps={restInput}
        onChange={(v) => {
          onChange(v);
          onSelect && onSelect(v.target.value);
        }}
        value={value === '' ? 'empty' : value}
        required={!meta.error && (!meta.dirty || !meta.submitFailed) && meta.touched}
        inputlabelprops={{
          shrink: true,
        }}
      />
    );
  }
}

export default SelectField;
