import fp from 'lodash/fp';
import { put, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import API from '../api';
import { clearCurrentUser, setAuthData, setCurrentUser } from '../actions/auth';
import { addError } from '../actions/errors';
import { getAuthErrorText } from '../helpers/http';
import { checkPassword } from '../actions/users';
import { clearErrorSaga } from './index';
import { getCurrentUser } from './selectors';
import { history } from '../store';
import routes from '../routes';

export function storeTokens({ access_token, refresh_token, token_type } = {}) {
  localStorage.setItem('access_token', access_token || '');
  localStorage.setItem('refresh_token', refresh_token || '');
  localStorage.setItem('token_type', token_type || '');
}

export function* logoutByBlockSaga() {
  yield storeTokens();
  yield put(setAuthData({ isNotAuth: true }));
  yield put(clearCurrentUser());
  yield history.push(routes.home());
}

export function* loginSaga(action) {
  try {
    const json = yield API.rest.auth.signin(action.$payload.data);
    storeTokens(json);
    yield put(setAuthData({
      ...json,
    }));
    yield call(getCurrentUserSaga, {});
    yield call(clearErrorSaga);
    yield call(checkPasswordSaga);

    // action.$payload.onFinish();
  } catch (err) {
    const message = getAuthErrorText(err);
    yield put(addError({ response: { data: { message } } }));
  }
}

export function* logoutSaga(action) {
  try {
    yield call(clearErrorSaga);
    yield API.rest.auth.signout(action.$payload.data);
    storeTokens();
    yield put(setAuthData({ isNotAuth: true }));
    yield put(clearCurrentUser());
    yield put(replace({ pathname: routes.home(), search: null }));
  } catch (err) {
  }
}

export function* reconnectSaga(action) {
  try {
    // if(action?.$payload?.error?.response?.data?.error_description?.indexOf('Access token expired') === 0) {
    const json = yield API.rest.auth.reconnect(action.$payload);
    yield put(setAuthData({
      ...json,
    }));
    // yield put(action.$payload.onFinish);
    // }
  } catch (err) {
  }
}

export function* checkAuthSaga(action) {
  try {
    const data = fp.omitBy(
      fp.isEmpty,
      {
        access_token: localStorage.getItem('access_token') || '',
        refresh_token: localStorage.getItem('refresh_token') || '',
        token_type: localStorage.getItem('token_type') || '',
      },
    );
    if (data.access_token) {
      yield API.rest.auth.check(data.access_token);

      yield put(setAuthData({
        ...data,
      }));
      yield call(getCurrentUserSaga, {});
      yield call(clearErrorSaga);
      action.$payload.onFinish(false);
    } else {
      yield put(setAuthData({ isNotAuth: true }));
      action.$payload.onFinish(true);
    }
  } catch (err) {
    yield put(setAuthData({ isNotAuth: true }));
    action.$payload.onFinish(true);
  }
}

export function* getCurrentUserSaga({ $payload }) {
  try {
    const json = yield API.rest.users.current();
    yield put(setCurrentUser({
      ...json,
    }));
    $payload?.onFinish && $payload.onFinish();
  } catch (err) {
    yield put(addError(err));
  }
}

function* checkPasswordSaga() {
  try {
    const currentUser = yield select(getCurrentUser);
    const json = yield API.rest.users.checkPassword(currentUser.id);
    yield put(checkPassword({
      ...json,
    }));
  } catch (err) {
    yield put(addError(err));
  }
}
