/* eslint-disable */
import {defaultTo, get} from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import DatePicker from "../DatePicker/DatePicker";

export const generateFilter = (Component) => (props = {}) => ({
                                                                column,
                                                                form,
                                                                formState,
                                                                ...instance
                                                              }) =>
  React.createElement(Component, {
    ...props,
    column,
    label: defaultTo(props.label, column.Header),
    form,
    formState,
    instance,
  });

export const DatePickerFilter = React.memo(
  ({column, endOfDay, form, formState, instance, ...rest }) => {
    const value = useMemo(() => get(formState, 'values.value.date', ''),
      [formState.values],
    );

    const onChange = useCallback(date => {
      endOfDay
        ? form.change('value', { date: new Date(date.setHours(23, 59, 59, 0)) })
        : form.change('value', { date })
    }, []);

    return (
      <div style={{ margin: '-24px -16px 0' }}>
        <DatePicker
          {...rest}
          name="value"
          date={value}
          onDateChange={onChange}
          isFilter
        />
      </div>
    );
  },
);

export const makeDatepickerFilter = generateFilter(DatePickerFilter);

DatePickerFilter.propTypes = {
  // set date at the end of the day
  endOfDay: PropTypes.bool,
};

DatePickerFilter.defaultProps = {
  endOfDay: false,
};

