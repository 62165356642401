import { Input } from '@crpt-ui/core';
import React from 'react';
import fp from 'lodash/fp';
import { Translate } from '../translate';

class TextField extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !fp.isEqual(this.props, nextProps);
  }

  render() {
    const {
      input: {
        name, onChange, value, ...restInput
      }, label, meta, ...rest
    } = this.props;
    return (
      <Input
        {...rest}
        label={Translate(label)}
        name={name}
        helperText={meta.error
                && (meta.dirty || meta.submitFailed) && meta.touched ? meta.error : undefined}
        error={meta.error && (meta.dirty || meta.submitFailed) && meta.touched}
        InputProps={restInput}
        onChange={onChange}
        value={value}
        margin="normal"
        required={!meta.error && (!meta.dirty || !meta.submitFailed) && meta.touched}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

export default TextField;
