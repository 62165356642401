import { ButtonBase, withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Logo, LogoShort } from '../../../pages/auth/logo';

const HeaderLogo = withStyles({
  root: {
    padding: 20,
  },
})(({
  expanded, hovered, countryCode, ...rest
}) => (
  <ButtonBase
    TouchRippleProps={{
      style: {
        color: '#fff',
      },
    }}
    {...rest}
  >
    {expanded || hovered
      ? <div className="menuLogo"><Logo country={countryCode} /></div>
      : <div className="menuLogo"><LogoShort country={countryCode} /></div>}
  </ButtonBase>
));

export const mapStateToProps = (state) => ({
  countryCode: state.settings.common.countryCode,
});

const ConnectedComponent = connect(mapStateToProps, {})(HeaderLogo);

export default ConnectedComponent;
