import { put, call } from 'redux-saga/effects';
import API from '../api';
import { addError } from '../actions/errors';
import { setIsLoading, setSettings } from '../actions/common';
import { setAccruals, setAccrualsCodesCount } from '../actions/accruals';

export function* loadAccrualsSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.accruals.list(action.$payload);
    const settings = {
      page: action.$payload.page, size: action.$payload.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setAccruals({ ...json }));
    yield put(setSettings({ ...settings }));
    yield call(
      loadAccrualsCodesCountSaga,
      action,
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadAccrualsCodesCountSaga(action) {
  try {
    const json = yield API.rest.accruals.codesCount(action.$payload);
    yield put(setAccrualsCodesCount({ ...json }));
  } catch (err) {
  }
}

export function* downloadAccrualsSaga(action) {
  try {
    yield API.rest.accruals.xlsx(action.$payload.query);
    action.$payload.onFinish();
  } catch (err) {
    action.$payload.onFinish();
    yield put(addError(err));
  }
}
