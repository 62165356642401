import { connect } from 'react-redux';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { CommonDialog } from '../../dialog';
import { Translate } from '../../translate';

const Footer = ({ settings }) => {
  const [open, setOpen] = useState(false);
  console.log(window.version);
  return (
    <>
      <CommonDialog
        open={open}
        title={Translate('Версии приложения')}
        onClose={() => setOpen(false)}
        onSubmit={() => setOpen(false)}
        labelCancel={false}
        labelSubmit="Закрыть"
      >
        <ul className="versionList">
          <li>
            {Translate('Версия Frontend')}
            :
            <strong>
              v.
              {window.version}
            </strong>
          </li>
          <li>
            {Translate('Версия Backend')}
            :
            <strong>
              v.
              {settings.version}
            </strong>
          </li>
          <li>
            {Translate('Версия Gateway')}
            :
            <strong>
              v.
              {settings.gwVersion}
            </strong>
          </li>
        </ul>
      </CommonDialog>
      <Tooltip title={Translate('Просмотр версий')} arrow>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p
          onClick={() => setOpen(true)}
          style={{
            fontSize: '13px', textAlign: 'center', width: '40px', cursor: 'pointer',
          }}
        >
          v.
          {settings.version}
        </p>
      </Tooltip>
    </>
  );
};

export const mapStateToProps = (state) => ({
  settings: state.settings.common,
});

const ConnectedComponent = connect(mapStateToProps, {})(Footer);

export default ConnectedComponent;
