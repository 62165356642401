import { put, call } from 'redux-saga/effects';
import API from '../api';
import { addError } from '../actions/errors';
import { setCodesLimits } from '../actions/balance';

export function* checkBeforeEmissionSaga({ $payload }) {
  try {
    const {
      countryId, issuedCount, participantId, productGroupMarkingId,
    } = $payload;
    const debt_check = yield API.rest.balance.debt_check({ countryId, participantId, productGroupMarkingId });
    if (!debt_check.result && debt_check.isDebt) {
      const message = 'Выполнение операции запрещено по причине наличия задолженности';
      yield put(addError({ response: { data: { message } } }));
      $payload.onFinish(false);
    } else {
      const limit_check = yield API.rest.balance.limit_check({ countryId, participantId, issuedCount });
      if (!limit_check.result) {
        const available_count = yield API.rest.balance.limit_check({ countryId, participantId, issuedCount: 0 });
        const message = `Вы превысили допустимое количество КМ, доступных для заказа! Доступно для заказа ${available_count.count || 0} КМ`;
        yield put(addError({ response: { data: { message } } }));
        $payload.onFinish(false);
      } else {
        $payload.onFinish(true);
      }
    }
  } catch (err) {
    yield put(addError(err));
  }
}

function* checkOperatorBalance(productGroupMarkingId, kmCount) {
  try {
    const check = yield API.rest.balance.operator_check({ kmCount, productGroupMarkingId });
    console.log('check', check);
    return check.enough;
  } catch (e) {
    return true;
  }
}

export function* checkBeforeMarkingSaga({ $payload }) {
  try {
    const {
      countryId, participantId, productGroupMarkingId, kmCount,
    } = $payload;
    const debt_check = yield API.rest.balance.debt_check({ countryId, participantId, productGroupMarkingId });
    if (debt_check.result) {
      const operator_check = yield call(
        checkOperatorBalance,
        productGroupMarkingId,
        kmCount,
      );
      if (operator_check) {
        $payload.onFinish(true);
      } else {
        const message = 'У Оператора недостаточно средств для совершения операции. Обратитесь к Оператору';
        yield put(addError({ response: { data: { message } } }));
        $payload.onFinish(false);
      }
    } else {
      const message = debt_check.isDebt
        ? 'Выполнение операции запрещено по причине наличия задолженности'
        : 'Выполнение операции запрещено по причине отсутствия предоплаты';
      yield put(addError({ response: { data: { message } } }));
      $payload.onFinish(false);
    }
  } catch (err) {
    yield put(addError(err));
  }
}

export function* checkBeforePrintSaga({ $payload }) {
  try {
    const { countryId, participantId, productGroupMarkingId } = $payload;
    const debt_check = yield API.rest.balance.debt_check({ countryId, participantId, productGroupMarkingId });
    if (!debt_check.result && debt_check.isDebt) {
      const message = 'Выполнение операции запрещено по причине наличия задолженности';
      yield put(addError({ response: { data: { message } } }));
      $payload.onFinish(false);
    } else {
      $payload.onFinish(true);
    }
  } catch (err) {
    yield put(addError(err));
  }
}

export function* checkBeforePrintWithMarkingSaga({ $payload }) {
  try {
    const {
      countryId, participantId, productGroupMarkingId, charges, kmCount,
    } = $payload;
    const check = yield API.rest.balance.balance_sufficiency_check({
      countryId, participantId, productGroupMarkingId, charges,
    });
    if (check.result) {
      const operator_check = yield call(
        checkOperatorBalance,
        productGroupMarkingId,
        kmCount,
      );
      if (operator_check) {
        $payload.onFinish(true);
      } else {
        const message = 'У Оператора недостаточно средств для совершения операции. Обратитесь к Оператору';
        yield put(addError({ response: { data: { message } } }));
        $payload.onFinish(false);
      }
    } else {
      const message = check.payableCount > 0
        ? `Выполнение операции запрещено по причине недостаточности средств на ЛС УОТ. Доступное количество: ${check.payableCount || 0} кодов.`
        : check.isDebt
          ? 'Выполнение операции запрещено по причине наличия задолженности'
          : 'Выполнение операции запрещено по причине отсутствия предоплаты';
      yield put(addError({ response: { data: { message } } }));
      $payload.onFinish(false);
    }
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadCodesLimitSaga({ $payload }) {
  try {
    const { countryId, participantId } = $payload;
    const limit_check = yield API.rest.balance.limit_check({ countryId, participantId, issuedCount: 0 });
    if (!limit_check.result) {
      yield put(setCodesLimits(0));
    } else {
      yield put(setCodesLimits(limit_check.count));
    }
  } catch (err) {
    yield put(addError(err));
  }
}
