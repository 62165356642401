import { put } from 'redux-saga/effects';
import {
  setOperation,
  setOperationHistory,
  setOperations,
  setOperationType,
} from '../actions/operations';
import API from '../api';
import { addError } from '../actions/errors';
import { setIsLoading, setSettings } from '../actions/common';

export function* setOperationTypeSaga(action) {
  try {
    yield put(setOperationType(action.$payload.data));
    action.$payload.onFinish();
  } catch (err) {
    yield put(addError(err));
  }
}

export function* createOperationSaga(action) {
  try {
    const json = yield API.rest.operations.create(action.$payload.data);
    action.$payload.onFinish(json.id, true);
  } catch (err) {
    const shouldDisable = err?.response?.data?.status === 504;
    action.$payload.onFinish(false, !shouldDisable);
    yield put(addError(err));
  }
}

export function* createMarkingOperationSaga(action) {
  try {
    const json = yield API.rest.markCodes.marking(action.$payload.data);
    action.$payload.onFinish(json.operationId);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* createMarkCodeOrderOperationSaga(action) {
  try {
    const json = yield API.rest.markCodes.create(action.$payload.data);
    action.$payload.onFinish(json.operationId);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadOperationsSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.operations.list(action.$payload);
    const settings = {
      // eslint-disable-next-line max-len
      page: action.$payload.page, size: action.$payload.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setOperations({ ...json }));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadOperationSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.operations.item(action.$payload.id);
    yield put(setOperation({ ...json }));
    yield put(setIsLoading(false));
    action.$payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadOperationHistorySaga(action) {
  try {
    // eslint-disable-next-line max-len
    const json = yield API.rest.operations.history(action.$payload.operationId, action.$payload.query);
    const settings = {
      // eslint-disable-next-line max-len
      page: action.$payload.query.page, size: action.$payload.query.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setOperationHistory({ ...json }));
    yield put(setSettings({ ...settings }));
  } catch (err) {
    const settings = {
      page: 0, size: action.$payload.query.size, totalPages: 0, totalElements: 0,
    };
    yield put(setSettings({ ...settings }));
    yield put(addError(err));
  }
}

export function* createAggregationSaga(action) {
  try {
    const json = yield API.rest.operations.create(action.$payload.data);
    action.$payload.onFinish(json.id, true);
  } catch (err) {
    const shouldDisable = err?.response?.data?.status === 504;
    action.$payload.onFinish(false, !shouldDisable);
    yield put(addError(err));
  }
}

export function* cancelTransgranSaga(action) {
  try {
    const json = yield API.rest.operations.create(action.$payload.body);
    yield put(setOperation({ ...json }));

    action.$payload.onFinish(json.id);
  } catch (err) {
    action.$payload.onFinish();
    action.$payload.onFinish(false);
    yield put(addError(err));
  }
}

export function* cancelAggregationSaga(action) {
  try {
    const json = yield API.rest.operations.cancelAggregation(action.$payload);
    yield put(setOperation({ ...json }));
  } catch (err) {
    action.$payload.onFinish();
    yield put(addError(err));
  }
}

export function* downloadXlsxSaga(action) {
  try {
    yield API.rest.operations.xlsx(action.$payload);
  } catch (err) {
    yield put(addError({ response: { data: err.data } }));
  }
}
