/* eslint-disable */
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import * as Icons from '@crpt-ui/icons';
import { Grid } from '@material-ui/core';
import cn from 'classnames';

import { HELPERS } from '../../../helpers';
import { logout } from '../../../actions/auth';
import { removeError } from '../../../actions/errors';
import routes from '../../../routes';
import { usePageHeaderStyles } from './PageHeader.styled';
import MenuButton from "./components/menuButton";
import Button from "./components/button";
import {useTranslation} from "react-i18next";
import {LanguageSwitcher} from "../../language-switcher/languageSwitcher";
import {Translate} from "../../translate";

const PageHeader = ({ currentUser, logout, removeError, settings }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const classes = usePageHeaderStyles({ country: settings.countryCode });
  const title = HELPERS.getPageTitle(location.pathname);
  const payInfo = useSelector((state) => state.currentUser?.participant?.account);

  const balance = useMemo(() => {
    return  HELPERS.getBalance(payInfo?.balance, payInfo?.debt, '')
  }, [payInfo]);

  let payInfoMenu = payInfo ? {
    label: (
      payInfo ? (
        <div className="pay_info">
          <div className="pay_info__svg">
            <Icons.Wallet />
          </div>
          <div>
            <p>{t('Лицевой счет')} № {payInfo?.number || ''}</p>
            <div className="balance">
               {balance}
            </div>
          </div>
          <div className="pay_info__divider" />
        </div>
      ) : null),
  } : null;

  const userShortName = useMemo(
    () => currentUser.shortName,
    [currentUser],
  );

  const profileMenuItems = useMemo(() => [
    {
      component: RouterLink,
      icon: <Icons.Participant />,
      label: Translate('Профиль'),
      to: routes.profile(),
    },
    {
      component: RouterLink,
      icon: <div className={classes.icon} ><Icons.Exit /></div>,
      onClick: () => {
        logout();
        removeError();
      },
      label: Translate('Выйти'),
    },
  ], [logout, removeError]);

  return (
    <div className={cn(classes.header, classes.root)} style={{ zIndex: '8' }}>
      <Grid container className={classes.wrapper}>
        <Grid item>
          <Button>
            {t(title)}
          </Button>
        </Grid>
        <Grid item>
          {/*<div className={classes.testMessage}>*/}
          {/*  Тестовый режим заказа кодов маркировки в системе маркировки Российской Федерации*/}
          {/*</div>*/}
        </Grid>
        <Grid item style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <LanguageSwitcher />
        </Grid>
        <Grid item style={{ marginLeft: '20px' }}>
          <MenuButton
            icon={(
              <div className={classes.profileIcon}>
                <Icons.Participant />
              </div>
                          )}
            items={payInfoMenu ? [payInfoMenu, ...profileMenuItems] : profileMenuItems}
          >
            {userShortName}
          </MenuButton>
        </Grid>
      </Grid>
    </div>
  );
};

PageHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  removeError: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  settings: state.settings.common,
});

const ConnectedComponent = withRouter(connect(mapStateToProps, {
  logout,
  removeError,
})(PageHeader));

export default ConnectedComponent;
