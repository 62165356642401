// import fp from 'lodash/fp';
import { get } from 'lodash';
import types from '../types';
import { generateGuid } from '../helpers';
// import { HELPERS } from '../helpers';

const initialState = {
  errors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ERROR:
      const error = parseError(action);
      const errors = [...state.errors];
      errors.push({ id: generateGuid(), name: error });
      return {
        ...state,
        errors: [...errors],
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        errors: action?.$payload ? [...state.errors].filter((item) => item.id !== action.$payload) : [],
      };
    default:
      return state;
  }
};

export default reducer;

const parseError = ({ $payload }) => {
  const message = get($payload, 'response.data.message', null);
  const error_description = get($payload, 'response.data.error_description', null);
  const errors = get($payload, 'response.data.errors', null);

  const errorName = message || (error_description && error_description.indexOf('Access token expired') !== 0 && errors
    ? errors[0].defaultMessage
    : error_description);

  return getErrorMessage(errorName);
};

const getErrorMessage = (message) => {
  switch (message) {
    case 'User account is blocked': return 'Учетная запись заблокирована. Обратитесь в Службу Технической поддержки Оператора';
    case 'User account is locked': return 'Учетная запись заблокирована. Обратитесь в Службу Технической поддержки Оператора';
    case 'Participant is locked': return 'УОТ заблокирован. Обратитесь в Службу Технической поддержки Оператора';
    case 'Traffic participant in the status "Blocked". Contact the operator\'s support service': return 'УОТ заблокирован. Обратитесь в Службу Технической поддержки Оператора';
    case 'No message available': return 'Произошла неизвестная ошибка выполнения';
    case 'Invalid refresh token: null': return 'Срок действия токена истек';
    case 'Token was not recognised': return 'Токен не распознан';
    case 'Invalid file extension': return 'Файл должен быть в формате CSV';
    default: return message;
  }
};
