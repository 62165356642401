import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Button } from '@crpt-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as Icons from '@crpt-ui/icons';
import './index.scss';
import { Translate } from '../translate';

const DialogTitle = (props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className="commonDialog__title" {...other}>
      <Typography variant="h6"><strong>{children}</strong></Typography>
      <IconButton aria-label="close" onClick={onClose}>
        <Icons.Close />
      </IconButton>
    </MuiDialogTitle>
  );
};

export const CommonDialog = ({
  labelSubmit,
  labelCancel,
  onClose,
  onSubmit,
  open,
  children,
  text,
  title,
  isLoading,
  withBorderBottom,
  ...props
}) => (
  <Dialog
    onClose={onClose}
    className="commonDialog"
    open={open}
  >
    {
        title && (
          <DialogTitle onClose={onClose}>
            {title}
          </DialogTitle>
        )
      }
    <DialogContent dividers className="commonDialog__content">
      {
          text
          && <DialogContentText>{ text }</DialogContentText>
        }
      { children }
    </DialogContent>
    <DialogActions className="commonDialog__actions">
      {[
        labelCancel && (
        <Button
          size="sm"
          key="b-cancel"
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {Translate(labelCancel)}
        </Button>
        ),
        labelSubmit && (
        <Button
          size="sm"
          variant="contained"
          color="primary"
          type="submit"
          key="b-submit"
          onClick={onSubmit}
          disabled={props.invalid || isLoading}
          loading={isLoading}
        >
          {Translate(labelSubmit)}
        </Button>
        ),
      ]}
    </DialogActions>
  </Dialog>
);

CommonDialog.defaultProps = {
  labelCancel: 'Нет',
  labelSubmit: 'Да',
  withBorderBottom: true,
  isLoading: false,
  onClose: (e) => e,
  onSubmit: (e) => e,
};

CommonDialog.propTypes = {
  labelCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelSubmit: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  withBorderBottom: PropTypes.bool,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
};
