import fp from 'lodash/fp';
import types from '../types';
import { HELPERS } from '../helpers';
import { events, operationTypes, statuses } from '../pages/operations-page/operation-dictionary';
import { statuses as participantStatuses } from '../pages/turnover-participants-page/participantsDictionary';

const initialState = {
  list: [],
  history: [],
  type: null,
  item: {},
  serviceTypes: [],
  serviceSettings: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_OPERATION_TYPE:
      return {
        ...state,
        type: action.$payload,
      };
    case types.SET_OPERATION:
      return {
        ...state,
        item: parseOperation(action.$payload),
      };
    case types.SET_OPERATIONS:
      return {
        ...state,
        list: parseOperationList(action.$payload),
      };
    case types.SET_OPERATION_HISTORY:
      return {
        ...state,
        history: parseOperationHistoryList(action.$payload),
      };
    case types.SET_SERVICES:
      return {
        ...state,
        serviceTypes: [...action.$payload.content],
        serviceSettings: {
          page: action.$payload.number,
          size: action.$payload.size,
          totalPages: action.$payload.totalPages,
          totalElements: action.$payload.totalElements,
        },
      };
    default:
      return state;
  }
};

export default reducer;

const parseOperationList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => ({
      id: item.id,
      operationType: HELPERS.getNameFromDictionary(item.operationType, operationTypes),
      creationDate: HELPERS.formatDate(item.createdAt),
      codeCount: item.kmsCount,
      event: getEventName(item.events, item.type),
      status: HELPERS.getNameFromDictionary(item.status, statuses),
      endDate: HELPERS.formatDate(item.endAt),
      groupName: item?.markingGroup?.name || '',
      participant: {
        ...item?.participant || {},
        status: HELPERS.getNameFromDictionary(item?.participant?.status, participantStatuses),
      },
      createdBy: item?.createdBy || 'Нет данных',
    }),
  ),
)(data);

const getEventName = (eventsList, type) => {
  if (eventsList && eventsList.length > 0) {
    if (type === 'TRANSGRAN_REGISTRATION') {
      const sorted = fp.sortBy('createdAt')(eventsList).reverse();
      return HELPERS.getNameFromDictionary(sorted[0].type, events);
    }
    return HELPERS.getNameFromDictionary(eventsList[0].type, events);
  }
  return '';
};

const parseOperationHistoryList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => ({
      id: item.id,
      event: HELPERS.getNameFromDictionary(fp.get('event.type', item), events),
      startStatus: HELPERS.getNameFromDictionary(item.statusIn, statuses),
      finishStatus: HELPERS.getNameFromDictionary(item.statusOut, statuses),
      changeDate: HELPERS.formatDate(item.updatedAt),
    }),
  ),
)(data);

const parseOperation = (data) => ({
  ...data,
  creationDate: HELPERS.formatDate(data.createdAt),
  finishDate: HELPERS.formatDate(data.endAt),
  status: HELPERS.getNameFromDictionary(data.status, statuses),
  statusValue: data.status,
  operationCountry: data.operationCountry ? data.operationCountry.name : '-',
  markCodesCount: data.kmsCount,
  documentDate: HELPERS.formatOnlyDate(data.documentDate),
  shipmentDate: HELPERS.formatOnlyDate(data.shipmentDate),
  recipientCountry: fp.get('participant.registrationCountry.name', data),
  operatorInn: fp.get('operatorInn', data),
  operatorName: fp.get('operatorName', data),
  events: fp.map(
    (item) => ({
      ...item,
      event: HELPERS.getNameFromDictionary(item.type, events),
      startDate: HELPERS.formatDate(item.startAt),
      processingDate: HELPERS.formatDate(item.executeAt),
    }),
  )(data.events),
  productGtin: data.product ? data.product.gtin : '-',
  productName: data.product ? data.product.fullName : '-',
  productId: data.product ? data.product.id : '-',
  serialNumberType: data.autogenerated ? 'Автоматически' : 'Вручную',
});
