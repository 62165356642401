import { createStyles } from '@material-ui/core';

export const TodayStyles = (theme) => createStyles({
  root: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: '8px 16px',
  },
  button: {
    fontSize: '14px!important',
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: 40,
  },
});

export const SubmitStyles = (theme) => createStyles({
  root: {
    padding: '24px 16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: '24px',
    fontWeight: 'bold',
    background: theme.palette.primary.main,
    color: theme.palette.text.white,
    height: '60px',
    fontSize: '12px',
    lineHeight: '1.3333333333333333',
    paddingLeft: '40px',
    paddingRight: '40px',
    borderRadius: '4px',
    width: '100%',
  },
});

export const TrashStyles = (theme) => createStyles({
  button: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: '4px',
    height: '60px',
    width: '60px',
    '& span': {
      margin: '0 !important',
    },
  },
});
