import { useMemo } from 'react';
import format from '../../utils/format.i18n';
import { getDays } from './useMonth.utils';

export const dayLabelFormatFn = (date) => format(date, 'd', 'ru');
export const weekdayLabelFormatFn = (date) => format(date, 'eeeeee', 'ru');
export const monthLabelFormatFn = (date) => format(date, 'LLLL', 'ru');
export function useMonth({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = dayLabelFormatFn,
  monthLabelFormat = monthLabelFormatFn,
}) {
  const days = useMemo(
    () => getDays({
      year, month, firstDayOfWeek, dayLabelFormat,
    }),
    [year, month, firstDayOfWeek, dayLabelFormat],
  );
  const monthLabelText = monthLabelFormat(new Date(year, month));
  return {
    days,
    monthLabel: monthLabelText.charAt(0).toUpperCase() + monthLabelText.slice(1),
  };
}
