import React from 'react';
import { Button, Modal } from '@crpt-ui/core';
import { connect, useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { checkPassword, patchUser } from '../../../actions/users';
import routes from '../../../routes';
import { history } from '../../../store';
import { ChangePasswordModal } from './change-password-modal';
import { Translate } from '../../../components/translate';

const WarningPasswordModal = ({ data, currentUser }) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(checkPassword({ expirationNote: false }));
  };

  const handleClick = () => {
    dispatch(checkPassword({ expirationNote: false, openDialog: true }));
    history.push(routes.profile());
  };

  const changePassword = (data) => {
    const body = { password: data.password, currentPassword: data.currentPassword };
    dispatch(patchUser({
      data: { id: currentUser.id, body },
      closeFormFunc: () => {
        dispatch(checkPassword({}));
      },
    }));
  };

  return (
    <>
      <Modal open={data.expirationNote} onClose={onClose} maxWidth="sm">
        <h2><strong>{Translate('Внимание!')}</strong></h2>
        <p>{Translate('Срок действия Вашего пароля истекает.')}</p>
        <p>
          {Translate('Число дней до истечения срока действия Вашего пароля')}
          :
          {' '}
          {data.daysLimit}
          .
        </p>
        <p className="withLink">
          {Translate('Чтобы изменить пароль, перейдите по')}
          {' '}
          <Link style={{ cursor: 'pointer' }} onClick={handleClick}>{Translate('ссылке')}</Link>
          .
        </p>
        <div className="buttons mt-20">
          <Button
            size="sm"
            variant="contained"
            width={100}
            type="submit"
            name="contained"
            onClick={onClose}
          >
            {Translate('Закрыть')}
          </Button>
        </div>
      </Modal>
      {
                data.expiredToday && (
                <ChangePasswordModal
                  isExpired
                  title="Внимание!"
                  text="Истек срок действия пароля для входа в систему. Необходимо изменить пароль!"
                  onClose={() => {}}
                  onSave={changePassword}
                />
                )
            }
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.settings.password,
  currentUser: state.currentUser,
});

const ConnectedComponent = connect(mapStateToProps, {})(WarningPasswordModal);

export default ConnectedComponent;
