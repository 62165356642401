import React, {
  useRef, useContext, memo, useMemo,
} from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useDay } from '../hooks';
import DatePickerContext from '../DatePicker.context';
import getColor, { getWeekendTextColor } from '../utils/getColor';
import Styles from './Day.styles';

const COLORS = {
  main: '#F9F9F9',
  disable: '#D9D9D9',
  defaultWeekendColor: '#F34747',
  selectedWeekendColor: '#63666A',
  selectedTodayBgColor: '#63666A',
  selectedTodayTextColor: '#FFFFFF',
};

const WEEKEND_DAYS = ['Sat', 'Sun'];

const Day = memo(({ dayLabel, date, classes }) => {
  const dayRef = useRef(null);
  const {
    isToday,
    focusedDate,
    isDateFocused,
    isSingleDaySelected,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstSelectedDate,
    isLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    type,
    disabledDayFocus,
  } = useContext(DatePickerContext);
  const {
    isSelected,
    isSelectedStart,
    isSelectedEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstSelectedDate,
    isLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
    disabledDayFocus,
  });

  const countryCode = useSelector((state) => state.settings.common.countryCode);

  const activeColorBg = useMemo(() => {
    switch (countryCode) {
      case 'AM': return '#707070';
      case 'KG': return '#057EC6';
      case 'KZ': return '#0287B0';
      case 'RU': return '#F6F42E';
      default: return '#F6F42E';
    }
  }, [countryCode]);

  const selectedColor = useMemo(() => {
    switch (countryCode) {
      case 'AM': return '#ffffff';
      case 'KG': return '#ffffff';
      case 'KZ': return '#ffffff';
      case 'RU': return '#63666A';
      default: return '#ffffff';
    }
  }, [countryCode]);

  const isTodayDay = isToday !== undefined ? isToday(date) : false;
  const isSingleSelected = isSingleDaySelected !== undefined ? isSingleDaySelected(date) : false;

  const dayName = date.toString().split(' ')[0];

  if (!dayLabel) {
    return <div />;
  }

  const getBackgroundColorFn = getColor(
    isSingleSelected || isSelected,
    isSelectedEnd || isSelectedStart,
    isWithinHoverRange,
    disabledDate,
    isTodayDay,
  );

  const getWeekendTextColorFn = getWeekendTextColor(
    isSingleSelected || isSelected,
  );

  return (
    <button
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      className={clsx({ [classes.buttonHover]: !isSingleSelected && !disabledDate })}
      style={{
        width: 40,
        height: 40,
        lineHeight: '40px',
        textAlign: 'center',
        border: 0,
        outline: 'none',
        fontSize: '10px',
        fontWeight: '600',
        borderRadius: type === 'single' ? '4px' : isSelectedEnd || isSelectedStart ? `
          ${isSelectedEnd ? 0 : 4}px
          ${isSelectedStart ? 0 : 4}px
          ${isSelectedStart ? 0 : 4}px
          ${isSelectedEnd ? 0 : 4}px
        ` : '0px',
        color: (isSingleSelected || isSelected)
          ? selectedColor
          : WEEKEND_DAYS.includes(dayName)
            ? getWeekendTextColorFn({
              selectedColor,
              normalColor: COLORS.defaultWeekendColor,
            })
            : '#63666A',
        background: getBackgroundColorFn({
          selectedFirstOrLastColor: activeColorBg,
          normalColor: COLORS.main,
          selectedColor: activeColorBg,
          rangeHoverColor: type === 'single' ? COLORS.main : activeColorBg,
          disabledColor: COLORS.disable,
          selectedTodayColor: activeColorBg,
        }),
      }}
    >
      {dayLabel}
    </button>
  );
});

Day.propTypes = {
  dayLabel: PropTypes.string,
  date: PropTypes.instanceOf(Date),
};

export default withStyles(Styles)(Day);
