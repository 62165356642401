import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import fp from 'lodash/fp';
import { palette as kgThemePalette } from './kg-theme';
import { palette as kzThemePalette } from './kz-theme';
import { palette as amThemePalette } from './am-theme';
import { palette as defaultThemePalette } from './default';
import { loadSettings } from '../../actions/common';
import { setFavicon } from '../../helpers';

class Theme extends React.Component {
    state = {
      theme: null,
    };

    componentDidMount() {
      const { loadSettings } = this.props;
      loadSettings({
        onFinish: (data) => {
          const country = data ? fp.find((item) => item.key === 'country')(data).value : '';
          this.setTheme(country);
          setFavicon(country);
        },
      });
    }

    getPalette = (country) => {
      switch (country) {
        case 'KG': return kgThemePalette;
        case 'KZ': return kzThemePalette;
        case 'AM': return amThemePalette;
        default: return defaultThemePalette;
      }
    };

    setTheme = (country) => {
      let themeClassName = '';
      switch (country) {
        case 'KG': themeClassName = 'theme-kg'; break;
        case 'KZ': themeClassName = 'theme-kz'; break;
        case 'AM': themeClassName = 'theme-am'; break;
        default: themeClassName = 'theme-default';
      }
      document.getElementById('body').className = themeClassName;

      const palette = this.getPalette(country);

      const theme = createMuiTheme({
        overrides: {
          MuiDrawer: {
            paper: {
              width: '368px',
            },
          },
          MuiFilledInput: {
            root: {
              '&:hover': {
                backgroundColor: 'white',
              },
              '&$focused': {
                backgroundColor: 'white',
              },
              backgroundColor: 'white',
              borderRadius: '3px',
              overflow: 'hidden',
            },
            underline: {
              '&:after': {
                borderBottom: '2px solid #f6f42e',
                transition: 'transform 500ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
              },
              '&:before': {
                display: 'none',
              },
            },
          },
          MuiFormControlLabel: {
            label: {
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: 20 / 14,
              margin: '0 0 0 10px',
            },
            root: {
              '&:last-child': {
                marginBottom: '0',
              },
              alignItems: 'flex-start',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: '10px',
              verticalAlign: 'top',
              userSelect: 'none',
            },
          },
          MuiIconButton: {
            root: {
              padding: '0',
            },
          },
          MuiInput: {},
          MuiInputBase: {
            root: {
              '&&$disabled': {
                color: '#63666a !important',
              },
            },
          },
          MuiInputLabel: {},
          MuiFormLabel: {
            root: {
              fontSize: '16px',
            },
          },
          MuiList: {
            padding: {
              paddingBottom: '5px',
              paddingTop: '5px',
            },
          },
          MuiListItem: {
            button: {
              '&:hover': {
                backgroundColor: '#f7f7f7',
                color: '#888888 !important',
              },
              '&:focus': {
                backgroundColor: palette.primary.main,
                color: 'white',
              },
            },
            root: {
              '&:hover': {
                backgroundColor: palette.primary.main,
              },
            },
          },
          MuiMenuItem: {
            gutters: {
              paddingLeft: '20px',
              paddingRight: '20px',
            },
            root: {
              '&$selected': {
                backgroundColor: palette.primary.main,
                '&:hover': {
                  backgroundColor: palette.primary.main,
                },
              },
              '&:hover': {
                backgroundColor: '#f7f7f7',
              },
              alignItems: 'center',
              borderRadius: 0,
              display: 'flex',
              fontSize: '16px',
              height: 'auto',
              minHeight: '40px',
            },
          },
          MuiPaper: {
            root: {
              background: '#fff',
              borderRadius: '3px',
              boxSizing: 'border-box',
            },
          },
          MuiPopover: {
            paper: {
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            },
          },
          MuiSelect: {
            select: {
              '&:focus': {
                backgroundColor: 'transparent',
              },
            },
          },
          MuiSvgIcon: {
            root: {
              verticalAlign: 'top',
              fontSize: '20px',
            },
          },
          MuiTooltip: {
            popper: {
              opacity: '1',
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, .6)',
              borderRadius: '3px',
              minWidth: 'auto',
              color: 'white',
              padding: '10px 15px',
              fontSize: '14px',
              lineHeight: '20px',
            },
          },
          MuiTypography: {
            body1: {
              fontSize: 16,
              lineHeight: 24 / 16,
            },
            h1: {
              fontSize: 32,
              fontWeight: 700,
              letterSpacing: '1px',
              lineHeight: 40 / 32,
              textTransform: 'uppercase',
            },
            h2: {
              fontSize: 20,
              lineHeight: 30 / 20,
              fontWeight: 600,
            },
            h3: {
              fontSize: 16,
              lineHeight: 20 / 16,
              fontWeight: 600,
            },
            h4: {
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '30px',
              marginTop: 20,
              marginBottom: 20,

              '&:first-child': {
                marginTop: 0,
              },
              '&:last-child': {
                marginBottom: 0,
              },
            },
            h5: {
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: 1.5,
            },
          },
          MuiTable: {
            root: {
              boxShadow: 'inset 0px 1px 0px #f2f2f2, inset 1px 0px 0px #f2f2f2',
              tableLayout: 'fixed',
            },
          },
          MuiDialog: {
            paperWidthXs: {
              maxWidth: 390,
            },
          },
          MuiDialogTitle: {
            root: {
              padding: '20px',
            },
          },
          MuiDialogContent: {
            root: {
              padding: '20px',
            },
          },
          MuiBackdrop: {
            root: {
              backgroundColor: 'rgba(0, 0, 0, .2)',
            },
          },
          MuiRadio: {
            root: {
              padding: 0,
              userSelect: 'none',

              '&:hover': {
                background: 'transparent',
              },
            },
          },
          MuiCheckbox: {
            root: {
              padding: 0,
              userSelect: 'none',

              '&:hover': {
                background: 'transparent',
              },
            },
          },
          MuiAppBar: {
            root: {
              backgroundColor: '#fff',
            },
          },
        },
        palette,
        spacing: 10,
        typography: {
          fontFamily: ['Roboto', '"Segoe UI"', 'sans-serif'].join(','),
          useNextVariants: true,
        },
      });

      this.setState({ theme });
    };

    render() {
      const { children } = this.props;
      const { theme } = this.state;

      return theme
        ? (
          <MuiThemeProvider theme={theme}>
            { children }
          </MuiThemeProvider>
        )
        : (<></>);
    }
}

export const mapStateToProps = (state) => ({
  countryCode: state.settings.common.countryCode,
});

const ConnectedComponent = connect(mapStateToProps, {
  loadSettings,
})(Theme);

export default ConnectedComponent;
