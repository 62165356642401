import React from 'react';
import DatePickerInput from '../DatePicker/DatePickerInput';
import { Translate } from '../translate';
// import { DatePickerInput } from "@crpt-ui/core"

const DatePickerField = ({
  input: {
    name, onChange, value, ...restInput
  }, label, withoutMargin, meta, maxDate, isMaxDateUnlimited, ...rest
}) => (
  <div className={withoutMargin ? '' : 'datePickerField'}>
    <DatePickerInput
      {...rest}
      label={Translate(label)}
      name={name}
      helperText={meta.error
        && (meta.dirty || meta.submitFailed) && meta.touched ? meta.error : undefined}
      error={meta.error && (meta.dirty || meta.submitFailed) && meta.touched}
      required={!meta.error && (!meta.dirty || !meta.submitFailed) && meta.touched}
      InputProps={restInput}
      date={value}
        // date={new Date(2002, 5, 24, 18, 30)}
      onChange={onChange}
      maxDate={maxDate || (isMaxDateUnlimited ? null : new Date())}
      displayFormat="dd.MM.yyyy"
      autoComplete="off"
    />
  </div>
);

export default DatePickerField;
