import React, { memo } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@crpt-ui/icons';
import format from '../utils/format.i18n';
import {
  TitleStyles,
  CloseStyles,
} from './Header.styles';

const Header = ({
  classes, startDate, endDate, type, onClose, isFilter,
}) => (
  <div className={classes.root}>
    <p className={classes.title}>
      {!startDate && !endDate
          && 'Дата'}
      {startDate && !endDate
          && format(startDate, 'dd MMMM', 'ru') + (type !== 'single' ? ' - ...' : '')}
      {startDate && endDate
          && `${format(startDate, 'dd MMMM', 'ru')} - ${format(endDate, 'dd MMMM', 'ru')}`}
    </p>
    <span>
      {!isFilter && <MemoizedCloseButton onClose={onClose} />}
    </span>
  </div>
);

const MemoizedCloseButton = memo(
  withStyles(CloseStyles)(({ classes, onClose }) => (
    <Close
      className={classes.root}
      onClick={onClose}
    />
  )),
);

MemoizedCloseButton.propTypes = {
  onClose: PropTypes.func,
};

Header.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  type: PropTypes.string,
  onClose: PropTypes.func,
  isFilter: PropTypes.bool,
};

export default memo(withStyles(TitleStyles)(Header));
