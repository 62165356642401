import { DefaultFilter } from '@crpt-ui/datatable/lib/Filters';
import { makeSelectFilter } from '@crpt-ui/datatable/lib/Filters/SelectFilter';
import React from 'react';
import { HELPERS } from '../../helpers';
import { Translate } from '../../components/translate';
import { makeRangeDatepickerFilter } from '../../components/filters';

export const statuses = [
  {
    label: 'Активный',
    value: 'ACTIVE',
  },
  {
    label: 'Заблокирован',
    value: 'BLOCKED',
  },
];

export const filters = [
  {
    label: 'inn',
    value: 'ИНН (аналог в стране)',
  },
  {
    label: 'gcp',
    value: 'GCP',
  },
  {
    label: 'status',
    value: 'Статус',
  },
];

export const headers = [
  {
    Header: Translate('ИНН (аналог в стране)'),
    id: 'inn',
    accessor: 'inn',
    width: 140,
    Filter: DefaultFilter,
  },
  {
    accessor: (row) => row.gcp,
    id: 'gcp',
    Header: Translate('GCP'),
    width: 130,
    Filter: DefaultFilter,
  },
  {
    accessor: (row) => row.shortName,
    id: 'shortName',
    Header: Translate('Краткое наименование'),
  },
  {
    accessor: (row) => row.supervisorName,
    id: 'supervisorName',
    Header: Translate('ФИО руководителя'),
  },
  {
    accessor: (row) => row.phone,
    id: 'phone',
    Header: Translate('Контактный телефон'),
  },
  {
    accessor: (row) => row.registrationDate,
    id: 'registrationDate',
    Filter: makeRangeDatepickerFilter(),
    Header: Translate('Дата регистрации'),
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Cell: ({ cell }) => HELPERS.getStatusName(cell.value),
    Header: Translate('Статус'),
    width: 130,
    Filter: makeSelectFilter(statuses),
  },
  {
    accessor: (row) => {
      const sum = HELPERS.getBalance(row?.account?.balance, row?.account?.debt, '');
      return !sum.includes('-')
        ? (<span>{sum}</span>)
        : (
          <span className="sumSubtract">
            -
            {sum}
          </span>
        );
    },
    id: 'account',
    Header: Translate('Текущее состояние баланса'),
  },
];
