/* eslint-disable */
import React, { memo, useCallback } from 'react';
import {  withStyles } from '@material-ui/core';
import { Button } from '@crpt-ui/core';
import * as Icons from '@crpt-ui/icons';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import PropTypes from 'prop-types';

import format from '../utils/format.i18n';
import {
  TodayStyles,
  SubmitStyles,
  TrashStyles,
} from './Footer.styles';
import clsx from 'clsx';

const isButtonsDisabled = (result) => {
  if (result === null) {
    return true;
  }
  if (result !== null) {
    if (Object.values(result).length === 2) {
      return !(result.startDate && result.endDate);
    }
    return false;
  }
};


const MemoizedControlButtons = memo(
  withStyles(SubmitStyles)(({ classes, buttonsDisabled, handleReset, handleSubmit, currentDateIsBlocked }) =>
      <div className={classes.root}>
        <Button
          className={classes.button}
          disabled={buttonsDisabled || currentDateIsBlocked}
          color="primary"
          fullWidth={true}
          size="md"
          variant="contained"
          onClick={handleSubmit}
        >
          Применить
        </Button>
        <MemoizedTrashButton onReset={handleReset} isDisabled={buttonsDisabled} />
      </div>
));

MemoizedControlButtons.propTypes = {
  buttonsDisabled: PropTypes.bool.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const Footer = memo(
  ({
    classes,
    activeMonths,
    goToMonth,
    onResetDates,
    onSubmit,
    result,
    isTodayDay,
    onTodayDateSelect,
    isFilter,
    currentDateIsBlocked,
  }) => {
    const today = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
    const { month, year } = activeMonths[0];
    const todayMonth = getMonth(today);
    const todayYear = getYear(today);
    const isCurrentMonth = month === todayMonth && year === todayYear;
    const hanldeClickToday = () => {
      if (!isTodayDay) {
        if (!isCurrentMonth) {
          goToMonth(today);
        }
        return onTodayDateSelect(today);
      }
    };
    const buttonsDisabled = isButtonsDisabled(result);

    const handleSubmit = useCallback(() => onSubmit(result), [result]);
    const handleReset = useCallback(() => {
      onSubmit(null);
      onResetDates();
    }, []);

    return (
      <div>
        <div className={classes.root}>
          <div className={classes.container}>
            <Button
              className={clsx(classes.button)}
              color="secondary"
              onClick={hanldeClickToday}
              variant="text"
              size="sm"
            >
              Сегодня {format(today, 'dd.MM.yyyy')}
            </Button>
          </div>
          {!isFilter &&
            React.createElement(MemoizedControlButtons, {
              buttonsDisabled,
              handleReset,
              handleSubmit,
              currentDateIsBlocked,
            })}
        </div>
      </div>
    );
  },
);

const MemoizedTrashButton = memo(
  withStyles(TrashStyles)(({ classes, onReset, isDisabled }) =>
    <Button
      className={classes.button}
      disabled={isDisabled}
      size="md"
      icon={<Icons.Trash />}
      variant="contained"
      color="secondary"
      onClick={onReset}
    />
));

MemoizedTrashButton.propTypes = {
  onReset: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

Footer.propTypes = {
  activeMonths: PropTypes.array.isRequired,
  goToMonth: PropTypes.func.isRequired,
  onResetDates: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  result: PropTypes.object,
  isTodayDay: PropTypes.bool,
  onTodayDateSelect: PropTypes.func,
  isFilter: PropTypes.bool,
  currentDateIsBlocked: PropTypes.bool,
};

export default withStyles(TodayStyles)(Footer);
