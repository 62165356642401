import types from '../types';

export const createAction = (type) => (payload) => ({
  type,
  $payload: payload || {},
});

export const createBoolAction = (type) => (payload) => ({
  type,
  $payload: payload,
});

export const redirect = (pathname, method = 'push', search = null) => ({
  type: types.REDIRECT_TO,
  $payload: {
    pathname,
    method,
    search,
  },
});

export const setSettings = createAction(types.SET_SETTINGS);

export const setCommonSettings = createAction(types.SET_COMMON_SETTINGS);

export const loadSettings = createAction(types.LOAD_SETTINGS);

export const clearSettings = createAction(types.SET_CLEAR_SETTINGS);

export const uploadFile = createAction(types.UPLOAD_FILE_MARK_CODES);

export const uploadFileAnyType = createAction(types.UPLOAD_FILE_ANY_TYPE);

export const downloadFile = createAction(types.DOWNLOAD_FILE);
export const setIsLoading = createBoolAction(types.SET_IS_LOADING);
export const saveLanguage = createBoolAction(types.SET_LANGUAGE);
