import { createAction } from './common';
import types from '../types';

export const setProduct = createAction(types.SET_PRODUCT);
export const clearProduct = createAction(types.CLEAR_PRODUCT);
export const setClearProduct = createAction(types.SET_CLEAR_PRODUCT);
export const updateProduct = createAction(types.UPDATE_PRODUCT);
export const patchProduct = createAction(types.PATCH_PRODUCT);
export const loadProduct = createAction(types.LOAD_PRODUCT);
export const createProduct = createAction(types.CREATE_PRODUCT);
export const loadGoods = createAction(types.LOAD_GOODS_LIST);
export const setGoods = createAction(types.SET_GOODS_LIST);
export const getGoodCard = createAction(types.GET_GOOD_CARD);
export const setGoodCardProperties = createAction(types.SET_GOODS_CARD_PROPERTIES_LIST);
export const getGoodCardProperyValues = createAction(types.GET_GOODS_CARD_SOLO_PROPERTY_VALUES);
export const setGoodCardAttributeList = createAction(types.SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_LIST);
export const setGoodCardAttributeUnitList = createAction(types.SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_UNITS_LIST);
export const addGoodCardProperty = createAction(types.ADD_GOODS_CARD_PROPERY);
export const removeGoodCardProperty = createAction(types.REMOVE_GOODS_CARD_PROPERY);
export const clearGoodCardProperty = createAction(types.CLEAR_GOODS_CARD_PROPERY);
