import { put } from 'redux-saga/effects';
import API from '../api';
import { addError } from '../actions/errors';
import { setIsLoading, setSettings } from '../actions/common';
import {
  getGoodCard,
  getGoodCardProperyValues,
  setClearProduct,
  setGoodCardAttributeList,
  setGoodCardAttributeUnitList,
  setGoodCardProperties,
  setGoods,
  setProduct,
} from '../actions/goods';

export function* loadGoodsSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.products.list(action.$payload.data);
    const settings = {
      page: action.$payload.data.page, size: action.$payload.data.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setGoods({ ...json }));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
    action.$payload.onFinish(json.content);
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadProductSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.products.item(action.$payload.id);
    yield put(setProduct({
      data: {
        ...json,
      },
      type: action.$payload.type,
    }));
    yield put(setIsLoading(false));
    yield put(getGoodCard(json.tnved.productSubgroup.id));
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* createProductSaga(action) {
  try {
    const json = yield API.rest.products.create(action.$payload.data);
    action.$payload.onFinish(json.id);
  } catch (err) {
    if (err.response.status === 302) {
      yield put(addError({ response: { data: { error_description: `В системе уже есть товар  с gtin ${err.response.data.gtin}` } } }));
      action.$payload.onFinish(err.response.data.id);
    } else {
      yield put(addError(err));
    }
  }
}

export function* updateProductSaga(action) {
  try {
    const json = yield API.rest.products.update(action.$payload.data);
    action.$payload.onFinish(json.id);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* patchProductSaga(action) {
  try {
    const json = yield API.rest.products.patch(action.$payload.data);
    yield put(setProduct({
      data: {
        ...json,
      },
      type: 'view',
    }));
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* clearProductSaga() {
  try {
    yield put(setClearProduct({}));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* getGoodCardSaga(action) {
  try {
    const json = yield API.rest.goodsCard.getAllCard(action.$payload);
    yield put(setGoodCardProperties(json));
    yield put(getGoodCardProperyValues(json));

    // action.$payload.onFinish(json.id);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* getSoloGoodCardPropertyValuesSaga(action) {
  try {
    let AttributesValuesArray = [];
    let AttributesUnitsArray = [];
    for (let i = 0; i < action.$payload.length; i += 1) {
      if (action.$payload[i].attributeType.code) {
        let json = yield API.rest.goodsCard.getSoloPropertyValues(action.$payload[i].attributeType.code);
        let AttributeUnitJson = yield API.rest.goodsCard.getSoloPropertyValueUnits(action.$payload[i].attributeType.id);
        if (json.length > 0) {
          yield AttributesValuesArray.push({
            attributeCode: action.$payload[i].attributeType.code,
            attributeValues: json,
          });
        }
        if (AttributeUnitJson.length > 0) {
          yield AttributesUnitsArray.push({
            attributeCode: action.$payload[i].attributeType.code,
            attributeUnits: AttributeUnitJson,
          });
        }
      }
    }
    yield put(setGoodCardAttributeList(AttributesValuesArray));
    yield put(setGoodCardAttributeUnitList(AttributesUnitsArray));

    // const json = yield API.rest.goodsCard.getSoloCard(action.$payload);
    // action.$payload.onFinish(json.id);
  } catch (err) {
    yield put(addError(err));
  }
}
