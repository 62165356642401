import { makeStyles } from '@material-ui/core/styles';

export default (theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  grow: {
    transformOrigin: 'right top',
  },
});

export const paperStyles = makeStyles({
  root: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
  },
});
