import fp from 'lodash/fp';
import types from '../types';
import { add_, update_ } from '../helpers/redux';
import { HELPERS } from '../helpers';

const initialState = {
  list: [],
  item: {
    registrationCountry: {},
    groupUotDtos: [],
  },
};

const reducer = (state = initialState, action) => {
  const add = add_(state.list);
  const update = update_(state.list);
  switch (action.type) {
    case types.SET_PARTICIPANTS:
      return {
        ...state,
        list: parseParticipantsList(action.$payload),
      };
    case types.SET_PARTICIPANT:
      return {
        ...state,
        item: parseParticipantItem(action.$payload),
      };
    case types.CLEAR_PARTICIPANT:
      return {
        ...state,
        item: {
          registrationCountry: {},
          groupUotDtos: [],
        },
      };
    case types.UPDATE_PARTICIPANTS_AFTER_CREATE:
      return {
        ...state,
        list: add(parseParticipantItem(action.$payload)),
      };

    case types.UPDATE_PARTICIPANTS:
      return {
        ...state,
        list: update(parseParticipantItem(action.$payload)),
      };
    case types.ADD_PARTICIPANTS_GROUP:
      return {
        ...state,
        item: {
          ...state.item,
          groupUotDtos: [
            ...state.item.groupUotDtos,
            {
              countryId: action.$payload.newTableInfo.countryId,
              country: action.$payload.newTableInfo.country,
              goodsGroup: action.$payload.groupInfo.name,
              goodsGroupID: action.$payload.groupInfo.id,
              startDate: action.$payload.newTableInfo.startDate ? HELPERS.formatOnlyDate(action.$payload.newTableInfo.startDate) : '',
              endDate: action.$payload.newTableInfo.endDate ? HELPERS.formatOnlyDate(action.$payload.newTableInfo.endDate) : '',
            },
          ],
        },
      };
    case types.REMOVE_PARTICIPANTS_GROUP:
      return {
        ...state,
        item: {
          ...state.item,
          groupUOTDtos: [
            ...state.item.groupUotDtos.splice(action.$payload.id, 1),
          ],
        },
      };
    default:
      return state;
  }
};

export default reducer;

const parseParticipantsList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => ({
      ...item,
      registrationDate: HELPERS.formatDate(item.registrationDate),

    }),
  ),
)(data);

const parseParticipantItem = (data) => ({
  ...data,
  statusDate: HELPERS.formatDate(data.statusDate),
  registrationDate: HELPERS.formatDate(data.registrationDate),
  groupUotDtos: data?.groupUotDtos.map((SoloDto) => ({
    id: SoloDto.id,
    country: SoloDto?.groupMarkingRsDto?.operatorDto?.country?.name,
    goodsGroup: SoloDto?.groupMarkingRsDto?.name,
    goodsGroupID: SoloDto?.groupMarkingRsDto?.id,
    startDate: SoloDto?.startDate ? HELPERS.formatOnlyDate(SoloDto.startDate) : '',
    endDate: SoloDto?.endDate ? HELPERS.formatOnlyDate(SoloDto.endDate) : '',
    participantId: SoloDto.participantId,
  })),
});
