/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Paper, Popper, ClickAwayListener, Grow, withStyles } from '@material-ui/core';
import { Input } from '@crpt-ui/core';
import { parse, getYear } from 'date-fns';

import DatePicker from './DatePicker';
import formatDatesString from './utils/formatDatesString';
import { START_YEAR, YEARS_AFTER } from './Datepicker.constants';
import DatePickerInputStyles, { paperStyles } from './DatePickerInput.styles';

const Datepicker = ({
  onChange,
  onInputChange,
  date,
  minDate,
  maxDate,
  parse: parseFormat,
  format,
  disabled = false,
  displayFormat = 'dd.MM.yyyy',
  startYear = START_YEAR,
  yearsAfter = YEARS_AFTER,
  PaperProps,
  classes,
  ...props
}) => {
  const dateToValue = (d) => {
    if (!d) {
      return '';
    }
    if (typeof d === 'string') {
      const parsedDate = parse(date, parseFormat || '', new Date());
      if (parsedDate instanceof Date && !isNaN(parsedDate)) {
        return formatDatesString({ startDate: parsedDate }, { displayFormat });
      } else {
        console.error('Invalid parse format for received input value');
        return '';
      }
    } else {
      return formatDatesString({ startDate: date }, { displayFormat });
    }
  };

  const inputRef = useRef(null);
  const popperRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    value: dateToValue(date),
  });
  const paperClasses = paperStyles(PaperProps);

  useEffect(() => {
    setAnchorEl(inputRef.current);
  }, []);

  useEffect(() => {
    setState({
      value: dateToValue(date),
    });
  }, [date]);

  const handleClick = () => {
    if(!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clickAwayHandle = (event) => {
    const cursorX = event.pageX;
    const cursorY = event.pageY;
    const { right, left, top, bottom } = popperRef.current.popper.getBoundingClientRect();
    const isOutsideClick = cursorX > right || cursorX < left || cursorY < top || cursorY > bottom;
    const isAnchorClick = event.target === inputRef.current;

    if (isOutsideClick && !isAnchorClick) {
      setOpen(false);
    }
  };

  const handleDateSubmit = (d) => {
    setState({
      value: formatDatesString({ startDate: d }, { displayFormat }),
    });
    if (format) {
      onChange(formatDatesString({ startDate: d }, { displayFormat: format }));
    } else {
      onChange(d);
    }
    handleClose();
  };

  const handleChangeInput = (inputDate) => {
    const parsedDate = parse(inputDate, displayFormat, new Date());
    // Проверяем год на соответсвие с displayFormat (date-fns не умеет strict в отличии от moment)
    const yearToken = displayFormat.match(/y+/g)[0];
    const yearValue = String(getYear(parsedDate));

    if(!inputDate) {
      setState({value: inputDate});
      onChange(null);
      return;
    }

    if (yearToken.length !== yearValue.length) {
      setState({ value: inputDate });
      return;
    }

    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      if (format) {
        onChange(formatDatesString({ startDate: parsedDate }, { displayFormat: format }));
      } else {
        onChange(parsedDate);
      }
    }

    if (onInputChange) {
      onInputChange(inputDate);
    }

    setState({value: inputDate});
  };

  const getDate = (d) => {
    const parsedDate = parse(d, displayFormat, new Date());
    if (parsedDate instanceof Date && !isNaN(parsedDate)) {
      return parsedDate;
    } else {
      return null;
    }
  };

  return (
    <div>
      <Input
        value={state.value}
        onChange={e => handleChangeInput(e.target.value)}
        onClick={handleClick}
        disabled={disabled}
        inputRef={inputRef}
        {...props}
      />
      <Popper
        id="simple-menu"
        anchorEl={anchorEl}
        popperRef={popperRef}
        open={open}
        placement='bottom-end'
        transition
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={clickAwayHandle}>
            <Grow
              {...TransitionProps}
              className={classes.grow}
            >
              <Paper {...PaperProps} classes={paperClasses} >
                <DatePicker
                  onClose={handleClose}
                  onSubmit={handleDateSubmit}
                  date={getDate(state.value)}
                  minDate={minDate}
                  maxDate={maxDate}
                  startYear={startYear}
                  yearsAfter={yearsAfter}
                  disabledDayFocus
                />
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};
Datepicker.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  displayFormat: PropTypes.string,
  parse: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withStyles(DatePickerInputStyles, {
  name: 'DatePickerInput',
})(Datepicker);
