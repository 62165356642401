import { createAction, createBoolAction } from './common';
import types from '../types';

export const loadUsers = createAction(types.LOAD_USERS);
export const setUsers = createAction(types.SET_USERS);
export const setUser = createAction(types.SET_USER);
export const getUser = createAction(types.LOAD_USER);
export const createUser = createAction(types.CREATE_USER);
export const updateUser = createAction(types.UPDATE_USER);
export const updateUsers = createAction(types.UPDATE_USERS);
export const patchUser = createAction(types.PATCH_USER);
export const updateUsersAfterCreate = createAction(types.UPDATE_USERS_AFTER_CREATE);
export const loadUser = createAction(types.LOAD_USER);
export const checkPassword = createAction(types.CHECK_PASSWORD);
export const setPasswordError = createBoolAction(types.SET_PASSWORD_ERROR);
