// import React from 'react';
import * as R from 'ramda';
import fp from 'lodash/fp';
import moment from 'moment';
import { statuses } from '../pages/turnover-participants-page/participantsDictionary';
// eslint-disable-next-line import/no-named-as-default
import routes from '../routes';
import faviconKZ from '../assets/favicons/faviconKZ.ico';
import faviconRU from '../assets/favicons/fiviconRU.ico';
import faviconAM from '../assets/favicons/faviconAM.ico';
import favicon from '../assets/favicons/favicon.ico';

export const path = (data) => (p) => R.flip(R.path)(data, p.split('.'));

const routesTitles = [
  { link: routes.goods(), title: 'Товары' },
  { link: routes.operations(), title: 'Операции' },
  { link: routes.turnoverParticipants(), title: 'Участники' },
  { link: routes.markCodes(), title: 'Коды маркировки' },
  { link: routes.users(), title: 'Пользователи' },
  { link: routes.payments(), title: 'Платежи' },
  { link: routes.auth(), title: 'Вход' },
  { link: routes.profile(), title: 'Профиль' },
  { link: routes.accruals(), title: 'Начисления' },
];

export const HELPERS = {
  parseErrors: (errors) => {
    if (errors && errors.errors && errors.errors.length !== undefined) {
      return fp.map(
        (v) => v.defaultMessage,
      )(errors.errors).join(', ');
    }
    return errors && (errors.message || errors.error_description);
  },
  isPhone: (data) => (/^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d-]{10,10}$/).test(data),

  getMoney: (balance, debet, name) => {
    if (balance == null || debet == null) {
      return '';
    }

    let totalBalance = balance - debet / 100;
    let balanceValue = [];
    let buffer = [];
    let totalBalanceInRubles = `${totalBalance}`.split('.')[0].split('') ?? '';
    let totalBalanceInMonets = `${totalBalance}`.split('.')[1] ?? '';

    for (let i = (totalBalanceInRubles.length - 1); i >= 0; i -= 1) {
      buffer.unshift(totalBalanceInRubles[i]);
      if (buffer.length === 3) {
        balanceValue.push(buffer.join(''));
        buffer = [];
      } else if (i === 0) {
        balanceValue.push(buffer.join(''));
      }
    }
    return `${`${balanceValue.reverse().join(' ')}${totalBalanceInMonets ? `,${totalBalanceInMonets.length === 1 ? `${totalBalanceInMonets}0` : `${totalBalanceInMonets}`}` : ',00'}` ?? ''} ${name ?? ''}`;
  },

  getBalance: (balance, debet, name) => {
    if (balance == null || debet == null) {
      return '';
    }

    let totalBalance = (balance - debet) / 100;
    let balanceValue = [];
    let buffer = [];
    let totalBalanceInRubles = `${totalBalance}`.split('.')[0].split('') ?? '';
    let totalBalanceInMonets = `${totalBalance}`.split('.')[1] ?? '';

    for (let i = (totalBalanceInRubles.length - 1); i >= 0; i -= 1) {
      buffer.unshift(totalBalanceInRubles[i]);
      if (buffer.length === 3) {
        balanceValue.push(buffer.join(''));
        buffer = [];
      } else if (i === 0) {
        balanceValue.push(buffer.join(''));
      }
    }
    return `${`${balanceValue.reverse().join(' ')}${totalBalanceInMonets ? `,${totalBalanceInMonets.length === 1 ? `${totalBalanceInMonets}0` : `${totalBalanceInMonets}`}` : ',00'}` ?? ''} ${name ?? ''}`;
    // let totalBalanceInRubles = `${totalBalance}`.split('.')[0];
    // let totalBalanceInMonets = `${totalBalance}`.split('.')[1] ?? null;
    // totalBalanceInMonets = totalBalanceInMonets && totalBalanceInMonets.length === 2
    //   ? totalBalanceInMonets
    //   : totalBalanceInMonets && totalBalanceInMonets.length === 1
    //     ? totalBalanceInMonets + '0'
    //     : '00';
    //
    // return `${totalBalanceInRubles},${totalBalanceInMonets}`;
  },

  getOnlyNumsPhone: (value) => value.replace('+7', '').replace(/[^\d]/g, ''),

  formatPhone: (value, isNeedBracket = true) => {
    if (!value) {
      return value;
    }
    const prefix = '+7';
    const onlyNums = HELPERS.getOnlyNumsPhone(value);
    if (onlyNums.length === 0) {
      return isNeedBracket ? onlyNums : prefix;
    }
    if (onlyNums.length <= 3) {
      return isNeedBracket
        ? `${prefix}(${onlyNums}`
        : `${prefix}-${onlyNums}`;
    }
    if (onlyNums.length <= 6) {
      return isNeedBracket
        ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`
        : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 7)}`;
    }
    if (onlyNums.length <= 8) {
      return isNeedBracket
        ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}`
        : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}`;
    }
    return isNeedBracket
      ? `${prefix}(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`
      : `${prefix}-${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`;
  },

  getPageTitle: (path) => (path === '/'
    ? 'Домашняя страница'
    : fp.pipe(
      fp.find((item) => path.includes(item.link)),
      fp.get('title'),
    )(routesTitles)),

  getStatusName: (status) => statuses.find((v) => v.value === status)?.label,

  getNameFromDictionary: (item, list) => {
    const element = list.find((v) => v.value === item);
    return element ? element.label : '-';
  },
  formatDate: (date) => (date ? moment(date).format('DD.MM.YYYY HH:mm') : '-'),
  formatOnlyDate: (date) => (date ? moment(date).format('DD.MM.YYYY') : '-'),

};

export const sortNamesDictionaries = [
  { label: 'login', value: 'Логин' },
  { label: 'fullName', value: 'ФИО' },
  { label: 'email', value: 'Email' },
  { label: 'type', value: 'Тип пользователя' },
  { label: 'participant', value: 'УОТ' },
  { label: 'status', value: 'Статус' },
  { label: 'inn', value: 'ИНН' },
  { label: 'gcp', value: 'GCP' },
  { label: 'gtin', value: 'Код товара (GTIN)' },
  { label: 'gtin', value: 'GTIN' },
  { label: 'gtin', value: 'Код товара' },
  { label: 'tnved.rootCode', value: 'Позиция ТН ВЭД' },
  { label: 'rootCode', value: 'Позиция ТН ВЭД ' },
  { label: 'tnved.code', value: 'Код ТН ВЭД' },
  { label: 'name', value: 'Название товара (по субпозиции ТН ВЭД)' },
  { label: 'tnved.code', value: 'Полный код ТН ВЭД' },
  { label: 'code', value: 'Полный код ТН ВЭД' },
  { label: 'fullName', value: 'Полное наименование товара' },
  { label: 'fullName', value: 'Наименование товара' },
  { label: 'trademark', value: 'Товарный знак' },
  { label: 'manufacturedCountry.name', value: 'Страна производства' },
  { label: 'gln', value: 'GLN' },
  { label: 'shortName', value: 'Краткое наименование' },
  { label: 'supervisorName', value: 'ФИО руководителя' },
  { label: 'phone', value: 'Контактный телефон' },
  { label: 'registrationDate', value: 'Дата регистрации' },
  { label: 'issuedCount', value: 'Количество КМ в статусе "Эмитирован"' },
  { label: 'payedCount', value: 'Количество КМ в статусе "Нанесен/оплачен"' },
  { label: 'serialNumber', value: 'Серийный номер' },
  { label: 'dateCreate', value: 'Дата и время получения' },
  { label: 'country.name', value: 'Страна' },
  { label: 'initialStatus', value: 'Начальный статус КМ' },
  { label: 'resultStatus', value: 'Итоговый статус КМ' },
  { label: 'timestamp', value: 'Дата и время изменения' },
  { label: 'operationType', value: 'Тип операции' },
  { label: 'createdAt', value: 'Дата создания' },
  { label: 'kmsCount', value: 'Количество КМ' },
  { label: 'lastEventType', value: 'Событие' },
  { label: 'endAt', value: 'Дата окончания операции' },
  { label: 'statusIn', value: 'Начальный статус' },
  { label: 'statusOut', value: 'Итоговый статус' },
  { label: 'updatedAt', value: 'Дата изменения операции' },
  { label: 'operation', value: 'Операция' },
  { label: 'inn', value: 'ИНН (аналог в стране)' },
  { label: 'parent.code', value: 'Код' },
  { label: 'productGroupMarking.name', value: 'Товарная группа' }, // Русская о
  { label: 'tnved.productSubgroup.name', value: 'Тoварная группа' }, // Английская о

  { label: 'markingCodeLevel.name', value: 'Тип' },
  { label: 'parent.code', value: 'Родительский код маркировки' },

];

export const sortNamesOperationMarkCodesDictionaries = [
  { label: 'markingCode.status', value: 'Статус' },
  { label: 'markingCode.gtin', value: 'GTIN' },
  { label: 'markingCode.serialNumber', value: 'Серийный номер' },
  { label: 'markingCode.dateCreate', value: 'Дата и время получения' },
  { label: 'markingCode.country.name', value: 'Страна' },

];

export const getFilterValue = (item, withTime = true) => {
  const format = withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
  return item.value.value.date
    ? moment(item.value.value.date).format(format)
    : item.value.value;
};

const getFaviconByCountry = (country) => {
  switch (country) {
    case 'KZ': return faviconKZ;
    case 'KG': return favicon;
    case 'RU': return faviconRU;
    case 'AM': return faviconAM;
    default: return faviconAM;
  }
};

export const setFavicon = (countryCode) => {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = getFaviconByCountry(countryCode);
  document.getElementsByTagName('head')[0].appendChild(link);
};

export function generateGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${
    s4()}-${s4()}${s4()}`;
}

export const getPGAlias = (list, id) => fp.pipe(
  fp.map((item) => item.groupMarkingRsDto),
  fp.find((item) => item.id === id),
  fp.get('alias'),
)(list);

export const getExpDate72 = (endDate, time) => {
  const dateString = `${moment(endDate).format('DD.MM.YYYY')}T${time}`;
  return moment(dateString, 'DD.MM.YYYYTHH:mm').utc().format();
};

export default {
  path,
};
