import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import cn from 'classnames';
import { styles } from './DropDown.styles';

const DropDown = ({
  classes,
  items,
  renderButton,
  variant,
  horizontal,
  menuProps,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {React.createElement(renderButton, {
        onClick: handleClick,
        active: anchorEl,
      })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        marginThreshold={0}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontal || 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontal || 'center',
        }}
        {...menuProps}
      >
        {items
          && items.map((item, index) => (item.component ? item.component
            : (
              <MenuItem
                onClick={(e) => item.onClick(handleClose, e)}
                className={
                    cn({
                      [classes.menuItemDefault]: !variant || variant === 'default',
                      [classes.menuItemMenu]: variant === 'menu',
                      [classes.menuItemCurrent]: item.current,
                    })
                  }
                key={index}
              >
                {item.current
                    && <span className={classes.menuItemDot} />}
                <ListItemText primary={item.title} />
              </MenuItem>
            )))}
      </Menu>
    </div>
  );
};

DropDown.propTypes = {
  items: PropTypes.array,
  classes: PropTypes.object.isRequired,
  menuProps: PropTypes.object,
  renderButton: PropTypes.func,
  variant: PropTypes.oneOf(['menu', 'default']),
  horizontal: PropTypes.oneOf(['center', 'left', 'right']),
};

DropDown.defaultProps = {
  items: [],
  classes: {},
  variant: 'default',
  horizontal: 'center',
};

export default React.memo(withStyles(styles)(DropDown));
