import types from '../types';

const initialState = {
  accountHistory: null,
  totalPages: null,
  totalElements: null,
  item: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCOUNTS_PAY:
      return {
        ...state,
        accountHistory: action.$payload.content,
        totalPages: action.$payload.totalPages,
        totalElements: action.$payload.totalElements,
      };
    case types.SET_PAYMENT:
      return {
        ...state,
        item: action.$payload,
      };
    default:
      return state;
  }
};

export default reducer;
