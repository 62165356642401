import types from '../types';
import { HELPERS } from '../helpers';
import { productGroupsNames } from '../pages/balance-page/constants';

const initialState = {
  accountHistory: null,
  totalPages: null,
  totalElements: null,
  paymentAccounts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCOUNTS_BALANCES_HISTORY:
      return {
        ...state,
        accountHistory: action.$payload.content,
        totalPages: action.$payload.totalPages,
        totalElements: action.$payload.totalElements,
      };
    case types.SET_PAYMENT_ACCOUNTS: {
      return {
        ...state,
        paymentAccounts: parseAccounts(action.$payload),
      };
    }
    default:
      return state;
  }
};

const parseAccounts = (list) => list.map(
  (item, idx) => ({
    id: idx,
    paymentAccount: item.contractId,
    amount: HELPERS.getBalance(item.balance, 0),
    productGroupName: productGroupsNames[item.productGroupId],
    productGroupId: item.productGroupId,
  }),
);

export default reducer;
