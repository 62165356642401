import { ButtonBase as MuiButtonBase, withStyles } from '@material-ui/core';
import React from 'react';

const ButtonBase = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    boxShadow: '1px 0 0 #f2f2f2',
    fontFamily: theme.typography.fontFamily,
    fontSize: 11,
    fontWeight: 700,
    color: theme.palette.text.primary,
    height: 64,
    letterSpacing: '0.02em',
    lineHeight: 16 / 11,
    minWidth: 64,
    paddingLeft: 16,
    paddingRight: 16,
    textTransform: 'uppercase',

    '&:first-child': {
      boxShadow: '-1px 0 0 #f2f2f2, 1px 0 0 #f2f2f2',
    },
    '&:hover': {
      background: '#f7f7f7',
    },
  },
}))(MuiButtonBase);

const Button = ({ icon, children, ...rest }) => (
  <ButtonBase disableRipple disableTouchRipple {...rest}>
    {icon ? (
      <span style={{ marginRight: children ? 8 : undefined }}>{icon}</span>
    ) : null}
    {children}
  </ButtonBase>
);

export default Button;
