import { DefaultFilter } from '@crpt-ui/datatable/lib/Filters';
import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/lib/Filters/CheckboxGroupFilter';
import { Link } from '@crpt-ui/core';
import React from 'react';
import { makeDatepickerFilter } from '../../components/filters';
import { Translate } from '../../components/translate';
import { history } from '../../store';
import routes from '../../routes';

export const statuses = [
  { label: 'Эмитирован', value: 'ISSUED' },
  { label: 'Регистрация нанесения', value: 'MARK_REGISTRATION' },
  { label: 'Нанесен/не оплачен', value: 'MARKED' },
  { label: 'Нанесен', value: 'PAYED' },
  { label: 'Регистрация трансграна', value: 'IMPORT_REGISTRATION' },
  { label: 'Трансгран', value: 'IMPORT' },
  { label: 'Экспортирован', value: 'EXPORT' },
  { label: 'Списан', value: 'WRITTEN_OFF' },
  { label: 'Расформирован', value: 'DISAGGREGATED' },
];

export const operationTypes = [
  { label: 'Заказ на эмиссию КМ', value: 'MARK_CODE_ORDER' },
  { label: 'Трансгран', value: 'TRANSGRAN_REGISTRATION' },
  { label: 'Нанесение', value: 'MARKING' },
];
export const filters = [
  { label: 'status', value: 'Статус' },
  { label: 'serialNumber', value: 'Серийный номер' },
  { label: 'gtin', value: 'GTIN' },
  { label: 'operationType', value: 'Тип операции' },
  { label: 'dateCreate', value: 'Дата и время получения' },
  // { label: 'productSubgroupIds', value: 'Товарная группа' },
  { label: 'productGroupIds', value: 'Товарная группа' },
  { label: 'subGroupCode', value: 'Тип' },
  { label: 'parentMarkingCode', value: 'Родительский код маркировки' },

];

export const fileTypes = [
  { label: Translate('PDF файл'), value: 'PDF' },
  { label: Translate('CSV файл'), value: 'CSV' },
];

export const templates = [
  { label: Translate('Простой горизонтальный'), value: 'datamatrix_landscape' },
  { label: Translate('Простой вертикальный'), value: 'datamatrix_portrait' },
];

export const tabs = [
  { label: Translate('Общая информация') },
  { label: Translate('История') },
];

export const aggregatedTabs = [
  { label: Translate('Общая информация') },
  { label: Translate('Состав агрегата') },
  { label: Translate('История') },
];

export const aggregateHeaders = [
  {
    accessor: (row) => row.gtin,
    id: 'gtin',
    Header: Translate('GTIN'),
    width: 170,
  },
  {
    accessor: (row) => row.code,
    id: 'code',
    Header: Translate('Код'),
  },
  {
    accessor: (row) => row.serialNumber,
    id: 'serialNumber',
    Header: Translate('Серийный номер'),
    width: 170,
  },
  {
    accessor: (row) => row?.group?.name,
    id: 'productGroupIds',
    Header: Translate('Товарная группа'),
    width: 260,
  },
  {
    accessor: (row) => row?.markingCodeLevel?.name,
    id: 'subGroupCode',
    Header: Translate('Тип'),
    width: 170,
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Header: Translate('Статус'),
    width: 130,
  },
  {
    accessor: (row) => row.receivingDate,
    id: 'dateCreate',
    Header: Translate('Дата и время получения'),
    width: 200,
  },
  {
    accessor: (row) => row.country,
    id: 'country',
    Header: Translate('Страна'),
    width: 130,
  },
];

export const headers = (productsTypeList) => [
  {
    accessor: (row) => row.gtin,
    id: 'gtin',
    Header: Translate('GTIN'),
    width: 170,
    Filter: DefaultFilter,
  },
  {
    accessor: (row) => row.code,
    id: 'code',
    Header: Translate('Код'),
    width: 280,
  },
  {
    accessor: (row) => row.serialNumber,
    id: 'serialNumber',
    Header: Translate('Серийный номер'),
    Filter: DefaultFilter,
    width: 170,
  },
  {
    accessor: (row) => row?.group?.name,
    id: 'productGroupIds',
    Header: Translate('Товарная группа'),
    width: 260,
    Filter: makeCheckboxGroupFilter(
      productsTypeList?.map(((SoloList) => ({ label: SoloList.label, value: SoloList.value }))),
    ),
  },
  {
    accessor: (row) => row?.markingCodeLevel?.name,
    id: 'subGroupCode',
    Header: Translate('Тип'),
    width: 170,
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Header: Translate('Статус'),
    Filter: makeCheckboxGroupFilter(statuses),
    width: 130,
  },
  {
    accessor: (row) => row.parentMarkingCode,
    id: 'parentMarkingCode',
    Header: Translate('Родительский код маркировки'),
    Filter: DefaultFilter,
    width: 260,
  },
  {
    accessor: (row) => row.receivingDate,
    id: 'dateCreate',
    Header: Translate('Дата и время получения'),
    Filter: makeDatepickerFilter(),
    width: 330,
  },
  {
    accessor: (row) => row.country,
    id: 'country',
    Header: Translate('Страна'),
    width: 130,
  },
];

export const historyHeaders = [
  {
    accessor: (row) => row.startStatus,
    id: 'startStatus',
    Header: Translate('Начальный статус КМ'),
  },
  {
    accessor: (row) => row.finishStatus,
    id: 'finishStatus',
    Header: Translate('Итоговый статус КМ'),
  },
  {
    accessor: (row) => row.changeDate,
    id: 'changeDate',
    Header: Translate('Дата и время изменения'),
  },
  {
    accessor: (row) => row.operationType,
    id: 'operationType',
    Header: Translate('Тип операции'),
    Filter: makeCheckboxGroupFilter(operationTypes),
  },
  {
    accessor: (row) => row.operation,
    id: 'operation',
    Cell: ({ cell }) => (
      <span>
        {
                      cell.value
                        ? (
                          <Link onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            history.push(routes.operations(cell.value));
                          }}
                          >
                            Операция
                          </Link>
                        )
                        : (<>-</>)
                    }
      </span>
    ),
    Header: Translate('Операция'),
  },
];
