import types from '../types';

const initialState = {
  list: [],
  codeCount: '-',
  totalPages: null,
  totalElements: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACCRUALS:
      return {
        ...state,
        list: action.$payload.content,
        totalPages: action.$payload.totalPages,
        totalElements: action.$payload.totalElements,
      };
    case types.SET_ACCRUALS_CODES_COUNT:
      return {
        ...state,
        codeCount: action.$payload.markingCodesCount,
      };
    default:
      return state;
  }
};

export default reducer;
