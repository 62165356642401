export default function getColor(
  isSelected,
  isSelectedStartOrEnd,
  isWithinHoverRange,
  isDisabled,
  isToday,
) {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
    selectedTodayColor,
  }) => {
    if (isToday && isSelected) {
      return selectedTodayColor;
    }
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } if (isSelected) {
      return selectedColor;
    } if (isWithinHoverRange) {
      return rangeHoverColor;
    } if (isDisabled) {
      return disabledColor;
    }
    return normalColor;
  };
}

export const getWeekendTextColor = (
  isSelected,
) => ({
  selectedColor,
  normalColor,
}) => {
  if (isSelected) {
    return selectedColor;
  }
  return normalColor;
};
