import React from 'react';
import * as Icons from '@crpt-ui/icons';

export const productsIcons = {
  1: <Icons.Clothes />,
  2: <Icons.Shoes />,
  3: <Icons.Tobacco />,
  4: <Icons.Perfume />,
  5: <Icons.Tires />,
  6: <Icons.Photo />,
  7: <Icons.GoodsMedicines />,
  8: <Icons.Milk />,
  9: <Icons.Bicycle />,
  10: <Icons.Wheelchair />,
  11: <Icons.Water />,
  12: <Icons.TobaccoAlternative />,
  13: <Icons.Water />,
};

export const productGroupsNames = {
  1: 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
  2: 'Обувные товары',
  3: 'Табачная продукция',
  4: 'Духи и туалетная вода',
  5: 'Шины и покрышки пневматические резиновые новые',
  6: 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
  7: 'Лекарственные препараты для медицинского применения',
  8: 'Молочная продукция',
  9: 'Велосипеды и велосипедные рамы',
  10: 'Кресла-коляски',
  11: 'Алкоголь',
  12: 'Альтернативная табачная продукция',
  13: 'Питьевая вода',
};
