import { createStyles } from '@material-ui/core';

export default createStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    padding: '16px',
  },
  container: {
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  month: {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'none',
  },
  days: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    justifyContent: 'center',
    gap: '1px',
    msGridColumns: '(1fr)[7]',
    msGridRows: '(1fr)[5]',
    '& > *:nth-child(7n+1)': {
      msGridColumn: 1,
    },
    '& > *:nth-child(7n+2)': {
      msGridColumn: 2,
    },
    '& > *:nth-child(7n+3)': {
      msGridColumn: 3,
    },
    '& > *:nth-child(7n+4)': {
      msGridColumn: 4,
    },
    '& > *:nth-child(7n+5)': {
      msGridColumn: 5,
    },
    '& > *:nth-child(7n+6)': {
      msGridColumn: 6,
    },
    '& > *:nth-child(7n)': {
      msGridColumn: 7,
    },
    '& > *:nth-child(-n+7)': {
      msGridRow: 1,
    },
    '& > *:nth-child(n+8):nth-child(-n+14)': {
      msGridRow: 2,
    },
    '& > *:nth-child(n+15):nth-child(-n+21)': {
      msGridRow: 3,
    },
    '& > *:nth-child(n+22):nth-child(-n+28)': {
      msGridRow: 4,
    },
    '& > *:nth-child(n+29):nth-child(-n+36)': {
      msGridRow: 5,
    },
  },
}));
