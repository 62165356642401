import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

const locales = { ru };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function (date, formatStr = 'PP', locale = 'en') {
  return format(date, formatStr, {
    locale: locales[locale],
  });
}
