import types from '../types';
import { HELPERS } from '../helpers';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...state,
        ...action.$payload,
        shortName: getShortName(action.$payload.fullName),
        registrationDate: HELPERS.formatDate(action.$payload.registrationDate),
        lastPasswordUpdateDate: HELPERS.formatDate(action.$payload.lastPasswordUpdateDate),
        lastUpdateDate: HELPERS.formatDate(action.$payload.lastUpdateDate),
      };
    case types.CLEAR_CURRENT_USER:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;

const getShortName = (fullName) => fullName
  .split(' ')
  .map((item, idx) => (idx === 0 ? item : `${item[0]}.`))
  .join(' ');
