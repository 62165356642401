const types = {
  REDIRECT_TO: '[SAGA/COMMON]/REDIRECT_TO',
  PUSH_SEARCH_PROPS: '[SAGA/COMMON]/PUSH_SEARCH_PROPS',

  START_REQUEST: '[REQUEST]/START',
  FINISH_REQUEST: '[REQUEST]/FINISH',

  ADD_ERROR: '[ERROR]/ADD_ERROR',
  SET_ERROR: '[ERROR]/SET_ERROR',
  REMOVE_ERROR: '[ERROR]/REMOVE_ERROR',
  CLEAR_ERRORS: '[ERROR]/CLEAR_ERRORS',

  LOAD_PARTICIPANTS: '[SAGS/PARTICIPANTS]/LOAD_PARTICIPANTS',
  LOAD_PARTICIPANT: '[SAGS/PARTICIPANTS]/LOAD_PARTICIPANT',
  LOAD_PAY_CSV: '[SAGS/PARTICIPANTS]/LOAD_PAY_CSV',

  SET_PARTICIPANTS: '[SAGS/PARTICIPANTS]/SET_PARTICIPANTS',
  SET_PARTICIPANT: '[SAGS/PARTICIPANTS]/SET_PARTICIPANT',
  CLEAR_PARTICIPANT: '[CLEAR/PARTICIPANTS]/CLEAR_PARTICIPANT',
  CREATE_PARTICIPANT: '[SAGS/PARTICIPANTS]/CREATE_PARTICIPANT',
  UPDATE_PARTICIPANT: '[SAGS/PARTICIPANTS]/UPDATE_PARTICIPANT',
  PATCH_PARTICIPANT: '[SAGS/PARTICIPANTS]/PATCH_PARTICIPANT',
  UPDATE_PARTICIPANTS: '[SAGS/PARTICIPANTS]/UPDATE_PARTICIPANTS',
  UPDATE_PARTICIPANTS_AFTER_CREATE: '[SAGS/PARTICIPANTS]/UPDATE_PARTICIPANTS_AFTER_CREATE',
  GET_PARTICIPANT: '[SAGS/PARTICIPANTS]/GET_PARTICIPANT',
  ADD_PARTICIPANTS_GROUP: '[SAGS/PARTICIPANTS]/ADD_PARTICIPANTS_GROUP',
  REMOVE_PARTICIPANTS_GROUP: '[SAGS/PARTICIPANTS]/REMOVE_PARTICIPANTS_GROUP',
  DOWNLOAD_PARTICIPANT_XLSX: '[SAGS/PARTICIPANTS]/DOWNLOAD_PARTICIPANT_XLSX',

  LOAD_USERS: '[SAGS/USERS]/LOAD_USERS',
  SET_USERS: '[SAGS/USERS]/SET_USERS',
  LOAD_USER: '[SAGS/USERS]/LOAD_USER',
  SET_USER: '[SAGS/USERS]/SET_USER',
  UPDATE_USER: '[SAGS/PARTICIPANTS]/UPDATE_USER',
  UPDATE_USERS: '[SAGS/PARTICIPANTS]/UPDATE_USERS',
  CREATE_USER: '[SAGS/PARTICIPANTS]/CREATE_USER',
  UPDATE_USERS_AFTER_CREATE: '[SAGS/PARTICIPANTS]/UPDATE_USERS_AFTER_CREATE',
  PATCH_USER: '[SAGS/PARTICIPANTS]/PATCH_USER',
  SET_PASSWORD_ERROR: '[SAGS/PARTICIPANTS]/SET_PASSWORD_ERROR',

  CREATE_PRODUCT: '[SAGS/GOODS]/CREATE_PRODUCT',
  LOAD_PRODUCT: '[SAGS/GOODS]/LOAD_PRODUCT',

  SET_PRODUCT: '[SAGS/GOODS]/SET_PRODUCT',
  UPDATE_PRODUCT: '[SAGS/GOODS]/UPDATE_PRODUCT',
  PATCH_PRODUCT: '[SAGS/GOODS]/PATCH_PRODUCT',
  UPDATE_GOODS_AFTER_CREATE: '[SAGS/GOODS]/UPDATE_GOODS_AFTER_CREATE',
  LOAD_GOODS_LIST: '[SAGS/GOODS]/LOAD_GOODS_LIST',
  SET_GOODS_LIST: '[SAGS/GOODS]/SET_GOODS_LIST',
  CLEAR_PRODUCT: '[SAGS/GOODS]/CLEAR_PRODUCT',
  SET_CLEAR_PRODUCT: '[SAGS/GOODS]/SET_CLEAR_PRODUCT',
  GET_GOOD_CARD: '[SAGS/GOODS]/GET_GOOD_CARD',
  SET_GOODS_CARD_PROPERTIES_LIST: '[SAGS/GOODS]/SET_GOODS_CARD_PROPERTIES_LIST',
  GET_GOODS_CARD_SOLO_PROPERTY_VALUES: '[SAGS/GOODS]/GET_GOODS_CARD_SOLO_PROPERTY_VALUES',
  SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_LIST: '[SAGS/GOODS]/SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_LIST',
  SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_UNITS_LIST: '[SAGS/GOODS]/SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_UNITS_LIST',
  ADD_GOODS_CARD_PROPERY: '[SAGS/GOODS]/ADD_GOODS_CARD_PROPERY',
  REMOVE_GOODS_CARD_PROPERY: '[SAGS/GOODS]/REMOVE_GOODS_CARD_PROPERY',
  CLEAR_GOODS_CARD_PROPERY: '[SET/GOODS]/CLEAR_GOODS_CARD_PROPERY',

  SET_SETTINGS: '[SAGS/SETTINGS]/SET_SETTINGS',
  SET_DICT_SETTINGS: '[SAGS/SETTINGS]/SET_DICT_SETTINGS',
  SET_IS_LOADING: '[SAGS/SETTINGS]/SET_IS_LOADING',
  SET_COMMON_SETTINGS: '[SAGS/SETTINGS]/SET_COMMON_SETTINGS',
  SET_CLEAR_SETTINGS: '[SET/SETTINGS]/SET_CLEAR_SETTINGS',
  LOAD_SETTINGS: '[SAGS/SETTINGS]/LOAD_SETTINGS',
  SET_ERRORS: '[SAGS/ERRORS]/SET_ERRORS',
  CHECK_PASSWORD: '[SAGS/SETTINGS]/CHECK_PASSWORD',

  LOGIN: '[SAGS/AUTH]/LOGIN',
  LOGOUT: '[SAGS/AUTH]/LOGOUT',
  SET_AUTH_DATA: '[SAGS/AUTH]/SET_AUTH_DATA',
  CLEAR_AUTH_DATA: '[SAGS/AUTH]/CLEAR_AUTH_DATA',
  RECONNECT_AUTH_DATA: '[SAGS/AUTH]/RECONNECT_AUTH_DATA',
  LOGOUT_BY_BLOCK: '[SAGS/AUTH]/LOGOUT_BY_BLOCK',
  CHECK_AUTH_DATA: '[SAGS/AUTH]/CHECK_AUTH_DATA',
  GET_CURRENT_USER: '[SAGS/AUTH]/GET_CURRENT_USER',
  SET_CURRENT_USER: '[SAGS/AUTH]/SET_CURRENT_USER',
  CLEAR_CURRENT_USER: '[SAGS/AUTH]/CLEAR_CURRENT_USER',

  LOAD_OPERATIONS: '[SAGS/OPERATIONS]/LOAD_OPERATIONS',
  SET_OPERATIONS: '[SAGS/OPERATIONS]/SET_OPERATIONS',
  CREATE_OPERATION: '[SAGS/OPERATIONS]/CREATE_OPERATION',
  UPDATE_OPERATIONS_AFTER_CREATE: '[SAGS/OPERATIONS]/UPDATE_OPERATIONS_AFTER_CREATE',
  SET_OPERATION_TYPE: '[SAGS/OPERATIONS]/SET_OPERATION_TYPE',
  CHOOSE_OPERATION_TYPE: '[SAGS/OPERATIONS]/CHOOSE_OPERATION_TYPE',
  SET_OPERATION: '[SAGS/OPERATIONS]/SET_OPERATION',
  LOAD_OPERATION: '[SAGS/OPERATIONS]/LOAD_OPERATION',
  LOAD_OPERATION_HISTORY: '[SAGS/OPERATIONS]/LOAD_OPERATION_HISTORY',
  SET_OPERATION_HISTORY: '[SAGS/OPERATIONS]/SET_OPERATION_HISTORY',
  CREATE_MARKING_OPERATION: '[SAGS/OPERATIONS]/CREATE_MARKING_OPERATION',
  CREATE_MARK_ORDER_OPERATION: '[SAGS/OPERATIONS]/CREATE_MARK_ORDER_OPERATION',
  CANCEL_TRANSGRAN: '[SAGS/OPERATIONS]/CANCEL_TRANSGRAN',
  DOWNLOAD_XLSX: '[SAGS/OPERATIONS]/DOWNLOAD_XLSX',

  UPLOAD_FILE_MARK_CODES: '[SAGS/FILES]/UPLOAD_FILE_MARK_CODES',
  UPLOAD_FILE_ANY_TYPE: '[SAGS/FILES]/UPLOAD_FILE_ANY_TYPE',

  UPLOAD_FILE_SERIAL_NUMBERS: '[SAGS/FILES]/UPLOAD_FILE_SERIAL_NUMBERS',
  DOWNLOAD_FILE: '[SAGS/FILES]/DOWNLOAD_FILE',

  LOAD_COUNTRY_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_COUNTRY_DICTIONARY',
  SET_COUNTRY_DICTIONARY: '[SAGS/DICTIONARY]/SET_COUNTRY_DICTIONARY',
  LOAD_TNVED_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_TNVED_DICTIONARY',
  SET_TNVED_DICTIONARY: '[SAGS/DICTIONARY]/SET_TNVED_DICTIONARY',
  LOAD_TNVED_ROOT_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_TNVED_ROOT_DICTIONARY',
  SET_TNVED_ROOT_DICTIONARY: '[SAGS/DICTIONARY]/SET_TNVED_ROOT_DICTIONARY',
  LOAD_SHOES_TYPES_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_SHOES_TYPES_DICTIONARY',
  SET_SHOES_TYPES_DICTIONARY: '[SAGS/DICTIONARY]/SET_SHOES_TYPES_DICTIONARY',
  LOAD_SHOES_SIZES_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_SHOES_SIZES_DICTIONARY',
  SET_SHOES_SIZES_DICTIONARY: '[SAGS/DICTIONARY]/SET_SHOES_SIZES_DICTIONARY',
  LOAD_SHOES_COLORS_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_SHOES_COLORS_DICTIONARY',
  SET_SHOES_COLORS_DICTIONARY: '[SAGS/DICTIONARY]/SET_SHOES_COLORS_DICTIONARY',
  LOAD_TOP_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_TOP_SHOES_MATERIALS_DICTIONARY',
  SET_TOP_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/SET_TOP_SHOES_MATERIALS_DICTIONARY',
  LOAD_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_SHOES_MATERIALS_DICTIONARY',
  SET_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/SET_SHOES_MATERIALS_DICTIONARY',
  LOAD_BOTTOM_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_BOTTOM_SHOES_MATERIALS_DICTIONARY',
  SET_BOTTOM_SHOES_MATERIALS_DICTIONARY: '[SAGS/DICTIONARY]/SET_BOTTOM_SHOES_MATERIALS_DICTIONARY',
  LOAD_PRODUCT_TYPES: '[SAGS/DICTIONARY]/LOAD_PRODUCT_TYPES',
  SET_PRODUCT_TYPES: '[SAGS/DICTIONARY]/SET_PRODUCT_TYPES',
  LOAD_PRODUCT_SUB_TYPES: '[SAGS/DICTIONARY]/LOAD_PRODUCT_SUB_TYPES',
  SET_PRODUCT_SUB_TYPES: '[SAGS/DICTIONARY]/SET_PRODUCT_SUB_TYPES',
  LOAD_PARTICIPANT_DICTIONARY: '[SAGS/DICTIONARY]/LOAD_PARTICIPANT_DICTIONARY',

  LOAD_PRODUCT_GROUPS_UOD_LIST: '[SAGS/DICTIONARY]/LOAD_PRODUCT_GROUPS_UOD_LIST',
  SET_PRODUCT_GROUPS_UOD_LIST: '[SAGS/DICTIONARY]/SET_PRODUCT_GROUPS_UOD_LIST',
  CLEAR_PRODUCT_GROUPS_UOD_LIST: '[CLEAR/DICTIONARIES]/CLeAR_DICTIONARIES',

  LOAD_CODES: '[SAGS/MARK_CODES]/LOAD_CODES',
  SET_CODES: '[SAGS/MARK_CODES]/SET_CODES',
  CLEAR_CODES: '[SAGS/MARK_CODES]/CLEAR_CODES',
  LOAD_CODE: '[SAGS/MARK_CODES]/LOAD_CODE',
  SET_CODE: '[SAGS/MARK_CODES]/SET_CODE',
  LOAD_CODE_HISTORY: '[SAGS/MARK_CODES]/LOAD_CODE_HISTORY',
  SET_CODE_HISTORY: '[SAGS/MARK_CODES]/SET_CODE_HISTORY',
  DOWNLOAD_MARK_CODES: '[SAGS/MARK_CODES]/DOWNLOAD_MARK_CODES',
  PRINT_MARK_CODES_PDF: '[SAGS/MARK_CODES]/PRINT_MARK_CODES_PDF',

  GET_OPERATOR_SERVISE: '[SAGS/SERVICES]/GET_OPERATOR_SERVISE',
  SET_SERVICES: '[SET/SERVICES]/SET_SERVICES',

  GET_ACCOUNTS_BALANCES_HISTORY: '[SAGS/ACCOUNTS]/GET_ACCOUNTS_BALANCES_HISTORY',
  SET_ACCOUNTS_BALANCES_HISTORY: '[SET/ACCOUNTS]/SET_ACCOUNTS_BALANCES_HISTORY',

  ADD_ACCOUNTS_PAY: '[SAGS/PAYMENTS]/ADD_ACCOUNTS_PAY',
  GET_ACCOUNTS_PAY: '[SAGS/PAYMENTS]/GET_ACCOUNTS_PAY',
  SET_ACCOUNTS_PAY: '[SET/PAYMENTS]/SET_ACCOUNTS_PAY',
  SET_COMMIT_FILE_PAY: '[SAGS/PAYMENTS]/SET_COMMIT_FILE_PAY',
  SET_PAYMENT: '[SAGS/PAYMENTS]/SET_PAYMENT',
  CANCEL_PAYMENT: '[SAGS/PAYMENTS]/CANCEL_PAYMENT',

  LOAD_PAYMENT_ACCOUNTS: '[SAGS/PAYMENT_ACCOUNTS]/LOAD_PAYMENT_ACCOUNTS',
  SET_PAYMENT_ACCOUNTS: '[SAGS/PAYMENT_ACCOUNTS]/SET_PAYMENT_ACCOUNTS',

  LOAD_ACCRUALS: '[SAGS/ACCRUALS]/LOAD_ACCRUALS',
  SET_ACCRUALS: '[SAGS/ACCRUALS]/SET_ACCRUALS',
  DOWNLOAD_ACCRUALS: '[SAGS/ACCRUALS]/DOWNLOAD_ACCRUALS',
  SET_ACCRUALS_CODES_COUNT: '[SAGS/ACCRUALS]/SET_ACCRUALS_CODES_COUNT',

  LOAD_CODES_LIMIT: '[SAGS/OPERATION]/LOAD_CODES_LIMIT',
  SET_CODES_LIMIT: '[SAGS/OPERATION]/SET_CODES_LIMIT',
  CHECK_BEFORE_EMISSION: '[SAGS/OPERATION]/CHECK_BEFORE_EMISSION',
  CHECK_BEFORE_MARKING: '[SAGS/OPERATION]/CHECK_BEFORE_MARKING',
  CHECK_BEFORE_PRINT: '[SAGS/OPERATION]/CHECK_BEFORE_PRINT',
  CHECK_BEFORE_PRINT_WITH_MARKING: '[SAGS/OPERATION]/CHECK_BEFORE_PRINT_WITH_MARKING',

  CREATE_AGGREGATION: '[SAGS/AGGREGATION]/CREATE_AGGREGATION',
  UPLOAD_AGGREGATION: '[SAGS/AGGREGATION]/UPLOAD_AGGREGATION',
  CANCEL_AGGREGATION: '[SAGS/AGGREGATION]/CANCEL_AGGREGATION',

  SET_LANGUAGE: '[SAGS/LANGUAGE]/SET_LANGUAGE',

  redirectMethods: {
    push: 'push',
    replace: 'replace',
  },
};

export default types;
