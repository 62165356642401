export const styles = (theme) => ({
  menu: {
    '& .MuiMenu-paper': {
      borderRadius: '0px 0px 0px 3px',
      minWidth: '50px !important',
      width: '50px',
      boxShadow: '0px 7px 8px rgba(0, 0, 0, 0.11)',
      '& .MuiList-padding': {
        padding: 0,
      },
    },
  },
  menuItemDefault: {
    position: 'relative',
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 3,
    paddingRight: 3,
    textAlign: 'center',
    fontSize: 14,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemMenu: {
    position: 'relative',
    paddingTop: 3,
    paddingBottom: 3,
    height: 60,
    lineHeight: '60px',
    borderBottom: '1px solid #F2F2F2',
    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '16px',
      display: 'inline-block',
      verticalAlign: 'middle',
      textTransform: 'uppercase',
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  listIcon: {
    marginRight: 10,
  },
  menuItemCurrent: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  menuItemDot: {
    right: 15,
    width: 4,
    height: 4,
    position: 'absolute',
    background: '#60E84A',
    borderRadius: '50%',
  },
});
