import { createAction } from './common';
import types from '../types';

export const loadParticipants = createAction(types.LOAD_PARTICIPANTS);
export const setParticipants = createAction(types.SET_PARTICIPANTS);
export const setParticipant = createAction(types.SET_PARTICIPANT);
export const getParticipant = createAction(types.LOAD_PARTICIPANT);
export const createParticipant = createAction(types.CREATE_PARTICIPANT);
export const updateParticipant = createAction(types.UPDATE_PARTICIPANT);
export const clearParticipant = createAction(types.CLEAR_PARTICIPANT);
export const patchParticipant = createAction(types.PATCH_PARTICIPANT);
export const updateParticipants = createAction(types.UPDATE_PARTICIPANTS);
export const updateParticipantsAfterCreate = createAction(types.UPDATE_PARTICIPANTS_AFTER_CREATE);
export const addParcitipantsGroup = createAction(types.ADD_PARTICIPANTS_GROUP);
export const removeParcitipantsGroup = createAction(types.REMOVE_PARTICIPANTS_GROUP);
export const getPayCSV = createAction(types.LOAD_PAY_CSV);
export const downloadXlsx = createAction(types.DOWNLOAD_PARTICIPANT_XLSX);
