/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Controls from './components/Controls';
import Footer from './components/Footer';
import Header from './components/Header';
import Month from './components/Month';
import DatePickerContext from './DatePicker.context';
import { END_DATE, START_DATE, useDatepicker } from './hooks';
import { START_YEAR, YEARS_AFTER } from './Datepicker.constants';

const RangeDatepicker = ({
  onSubmit,
  onClose,
  startDate,
  endDate,
  minDate,
  maxDate,
  isFilter,
  onDateChange,
  startYear = START_YEAR,
  yearsAfter = YEARS_AFTER
}) => {
  const [state, setState] = useState({
    startDate: startDate ? startDate : null,
    endDate: endDate ? endDate : null,
    focusedInput: startDate ? END_DATE : START_DATE,
  });

  const getDate = (date, boundary) => {
    if (!date) {
      return date;
    }
    const copiedDate = new Date(date.getTime());
    if (boundary === START_DATE) {
      copiedDate.setHours(0, 0, 0, 0);
    } else {
      copiedDate.setHours(23, 59, 59, 999);
    }
    return copiedDate;
  };

  const handleTodayClick = (date) => {
    const startDate = getDate(date, START_DATE);
    const endDate = getDate(date, END_DATE);

    setState({
      startDate,
      endDate,
      focusedInput: END_DATE,
    });

    if (typeof onDateChange === 'function') {
      onDateChange({
        startDate,
        endDate,
      });
    }
  };

  const handleDateChange = (data) => {
    const startDate = getDate(data.startDate, START_DATE);
    const endDate = getDate(data.endDate, END_DATE);

    if (!data.focusedInput) {
      setState({
        startDate,
        endDate,
        focusedInput: START_DATE,
      });
    } else {
      setState({
        ...data,
        startDate,
        endDate,
      });
    }

    if (typeof onDateChange === 'function') {
      onDateChange({
        startDate,
        endDate,
      });
    }
  };
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isLastSelectedDate,
    isFirstSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    onResetDates,
    goToMonth,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    minBookingDate: minDate,
    maxBookingDate: maxDate,
  });

  const memoizedGoToMonth = useCallback((newDate) => goToMonth(newDate), []);
  const memoizedOnSubmit = useCallback((result) => onSubmit(result), []);
  const memoizedOnResetDates = useCallback(() => onResetDates(), []);

  return (
    <div>
      <DatePickerContext.Provider
        value={{
          focusedDate,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isLastSelectedDate,
          isFirstSelectedDate,
          onDateSelect,
          onDateFocus,
          onDateHover,
          type: 'range',
        }}
      >
        <div style={{ width: 320 }}>
          <Header
            focused={state.focusedInput}
            startDate={state.startDate}
            endDate={state.endDate}
            onClose={onClose}
            type={'range'}
            isFilter={isFilter}
          />

          <Controls
            goToMonth={memoizedGoToMonth}
            year={activeMonths[0].year}
            month={activeMonths[0].month}
            startYear={startYear}
            yearsAfter={yearsAfter}
          />

          <div>
            {activeMonths.map((month) => (
              <Month
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
              />
            ))}
          </div>
          <Footer
            activeMonths={activeMonths}
            goToMonth={memoizedGoToMonth}
            onResetDates={memoizedOnResetDates}
            onSubmit={memoizedOnSubmit}
            onTodayDateSelect={handleTodayClick}
            type={'range'}
            result={{
              startDate: state.startDate,
              endDate: state.endDate,
            }}
            isFilter={isFilter}
          />
        </div>
      </DatePickerContext.Provider>
    </div>
  );
};
RangeDatepicker.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  /** Indicate that RangeDatepicker use in table filter area
   * Hide close button in Header. Hide control buttons in Footer */
  isFilter: PropTypes.bool,
  onDateChange: PropTypes.func,
  startYear: PropTypes.number,
  yearsAfter: PropTypes.number,
};

RangeDatepicker.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
  isFilter: false,
};

export default RangeDatepicker;
