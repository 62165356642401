import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store, history } from './store';
import Layout from './components/layout';
import { APIConnector } from './api';
import Auth from './pages/auth/component/auth';
import Theme from './components/themes';
import Incereption from './api/inceptor';

Incereption(store);

function App() {
  return (
    <Provider store={store}>
      <APIConnector>
        <Theme>
          <Auth>
            <ConnectedRouter history={history}>
              <Layout />
            </ConnectedRouter>
          </Auth>
        </Theme>
      </APIConnector>
    </Provider>
  );
}

export default App;
