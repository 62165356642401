import { DefaultFilter } from '@crpt-ui/datatable/lib/Filters';
import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/lib/Filters/CheckboxGroupFilter';
import { Translate } from '../../components/translate';

export const tabs = [
  {
    label: Translate('Общая информация о товаре'),
  },
  {
    label: Translate('Атрибуты товара'),
  },
];

export const filters = [
  { label: 'gtin', value: 'Код товара (GTIN)' },
  { label: 'tnvedCode', value: 'Код ТН ВЭД' },
  { label: 'manufacturedCountryId', value: 'Страна производства' },
  { label: 'status', value: 'Статус' },
  { label: 'code', value: 'Полный код ТН ВЭД' },
  { label: 'rootCodes', value: 'Позиция ТН ВЭД ' },
  { label: 'productSubgroupId', value: 'Товарная группа' }, // русская о
  { label: 'productSubgroupId', value: 'Тoварная группа' }, // английская о
  { label: 'subgroupCodes', value: 'Тoвaрная группа' }, // английская о английская первая а

];

export const statuses = [
  { label: 'Черновик', value: 'DRAFT' },
  { label: 'Опубликован', value: 'PUBLISHED' },
  { label: 'Заблокирован', value: 'DELETED' },
];

export const codeToMeasureName = {
  2473: 'Единица измерения минерализации',
  22284: 'Единица измерения объема',
  22287: 'Единица измерения срока годности',
};

export const getHeaders = (countries, subGroups) => [
  {
    accessor: (row) => row.gtin,
    id: 'gtin',
    Header: Translate('Код товара (GTIN)'),
    Filter: DefaultFilter,
    width: 180,
  },
  {
    accessor: (row) => row.tnvedGroup,
    id: 'rootCodes',
    Header: Translate('Позиция ТН ВЭД'),
    width: 130,
  },
  {
    accessor: (row) => row.tnvedCode,
    id: 'tnvedCode',
    Header: Translate('Код ТН ВЭД'),
    width: 140,
    Filter: DefaultFilter,
  },
  {
    accessor: (row) => row.fullName,
    id: 'fullName',
    Header: Translate('Полное наименование товара'),
  },
  {
    accessor: (row) => row?.tnved?.productSubgroup?.name ?? '',
    id: 'productSubgroupId',
    Header: Translate('Товарная группа'),
    Filter: makeCheckboxGroupFilter(subGroups),
  },
  {
    accessor: (row) => row.trademark,
    id: 'trademark',
    Header: Translate('Товарный знак'),
    width: 150,
  },
  {
    accessor: (row) => row.manufacturedCountry,
    id: 'manufacturedCountryId',
    Header: Translate('Страна производства'),
    Filter: makeCheckboxGroupFilter(countries),
  },
  {
    accessor: (row) => row.gcp,
    id: 'gcp',
    Header: Translate('GCP'),
    width: 130,
  },
  {
    accessor: (row) => row.gln,
    id: 'gln',
    Header: Translate('GLN'),
    width: 130,
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Header: Translate('Статус'),
    width: 130,
    Filter: makeCheckboxGroupFilter(statuses),
  },
];

export const getSortOptionById = (sort) => {
  const id = sort.split(',')[0];
  switch (id) {
    case 'manufacturedCountryId': return sort.replace('manufacturedCountryId', 'manufacturedCountry');
    case 'productSubgroupId': return sort.replace('productSubgroupId', 'tnved.productSubgroup.name');
    case 'tnvedCode': return sort.replace('tnvedCode', 'tnved.code');
    case 'rootCodes': return sort.replace('rootCodes', 'tnved.rootCode');
    default: return sort;
  }
};
