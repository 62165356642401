import { put } from 'redux-saga/effects';
import API from '../api';
import { reconnect } from '../actions/auth';
import { setIsLoading, setSettings } from '../actions/common';
import { addError } from '../actions/errors';
import {
  clearMarkCodes,
  setMarkCode,
  setMarkCodes,
  setMarkCodesHistory,
} from '../actions/mark-codes';

export function* loadMarkCodesSaga(action) {
  try {
    let json;
    if (action.$payload.isShort) {
      json = yield API.rest.operations.markCodes(action.$payload.operationId, action.$payload.query);
    } else {
      yield put(setIsLoading(true));
      json = yield API.rest.markCodes.list(action.$payload.query);
    }
    const settings = {
      page: action.$payload.query.page, size: action.$payload.query.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setMarkCodes({ ...json }));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    const settings = {
      page: 0, size: action.$payload.query.size, totalPages: 0, totalElements: 0,
    };
    yield put(setSettings({ ...settings }));
    yield put(addError(err));
  }
}

export function* clearMarkCodesSaga() {
  try {
    yield put(setMarkCodes([]));
  } catch (err) {
    if (err?.response?.data?.error_description?.indexOf('Access token expired') === 0) {
      yield put(reconnect({
        onFinish: clearMarkCodes(),
        error: err,
      }));
    }
    yield put(addError(err));
  }
}

export function* loadMarkCodeSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.markCodes.item(action.$payload.id);
    yield put(setMarkCode({ ...json }));
    yield put(setIsLoading(false));
    action.$payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadMarkCodeHistorySaga(action) {
  try {
    const json = yield API.rest.markCodes.history(action.$payload.markCodeId, action.$payload.query);
    const settings = {
      page: action.$payload.query.page, size: action.$payload.query.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setMarkCodesHistory({ ...json }));
    yield put(setSettings({ ...settings }));
  } catch (err) {
    const settings = {
      page: 0, size: action.$payload.query.size, totalPages: 0, totalElements: 0,
    };
    yield put(setSettings({ ...settings }));
    yield put(addError(err));
  }
}

export function* downloadMarkCodesCsv(action) {
  try {
    const data = yield API.rest.markCodes.csv(action.$payload.data);
    action.$payload.onFinish(data);
  } catch (err) {
    yield put(addError({ response: { data: err.data } }));
    action.$payload.onFinish(true);
  }
}

export function* printMarkCodesPdf(action) {
  try {
    const data = yield API.rest.markCodes.pdf(action.$payload.data);
    action.$payload.onFinish(false, data);
  } catch (err) {
    if (err.status === 406) {
      yield put(addError({ response: { data: { error_description: 'При печати КМ произошла ошибка' } } }));
    } else {
      yield put(addError({ response: { data: err.data } }));
      action.$payload.onFinish(true);
    }
  }
}
