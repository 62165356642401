import { put } from 'redux-saga/effects';
// import {
//   setUser,
//   setUsers,
//   updateUsers,
//   updateUsersAfterCreate,
// } from '../actions/users';
import { setAccountsBalance, setPaymentAccounts } from '../actions/accounts';
import API from '../api';
import { setIsLoading } from '../actions/common';
// import { setSettings } from '../actions/common';

export function* loadAccountBalanceSaga(action) {
  try {
    const response = yield API.rest.accounts.getAccountsHistory(action.$payload);
    yield put(setAccountsBalance(response));
    // const settings = {
    //   page: action.$payload.page, size: action.$payload.size, totalPages: json.totalPages, totalElements: json.totalElements,
    // };
    // yield put(setUsers({ ...json }));
    // yield put(setSettings({ ...settings }));
  } catch (err) {
    // if (err?.response?.data?.error_description?.indexOf('Access token expired') === 0) {
    //     yield put(reconnect({
    //         onFinish: loadUsers({ ...action.$payload }),
    //         error: err,
    //       }));
    // }
    // yield put(addError(err));
  }
}

export function* loadPaymentAccountsSaga() {
  try {
    yield put(setIsLoading(true));
    const response = yield API.rest.paymentAccounts.list();
    yield put(setPaymentAccounts(response));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
  }
}
