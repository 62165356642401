import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Select } from '@crpt-ui/core';
import format from '../utils/format.i18n';

const getYearsElements = (yearsToMatch, startYear) => Array.from(Array(yearsToMatch).keys()).map((i) => ({
  label: `${startYear + i}`,
  value: `${startYear + i}`,
}));

const getMonthsElements = (currentYear) => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => (
  {
    label: format(new Date(currentYear, i, 6), 'LLLL', 'ru'),
    value: i,
  }
));
const getMonthsAndYears = (startYear, yearsAfter) => {
  const currentYear = new Date().getFullYear();
  const yearsToMatch = (currentYear - startYear) + yearsAfter;
  return {
    months: getMonthsElements(currentYear),
    years: getYearsElements(yearsToMatch, startYear),
  };
};

const Controls = memo(({
  goToMonth, month = 0, year = 2019, startYear, yearsAfter,
}) => {
  const { years, months } = getMonthsAndYears(startYear, yearsAfter);
  const onChangeDate = (value, type) => {
    if (type === 'month') {
      goToMonth(new Date(year, value, 5));
    } else if (type === 'year') {
      goToMonth(new Date(value, month, 5));
    }
  };
  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            label="Год"
            options={years}
            value={year}
            onChange={(e) => onChangeDate(e.target.value, 'year')}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Месяц"
            options={months}
            value={month}
            onChange={(e) => onChangeDate(e.target.value, 'month')}
          />
        </Grid>
      </Grid>
    </div>
  );
});

Controls.propTypes = {
  goToMonth: PropTypes.func,
  month: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
  year: PropTypes.number,
  startYear: PropTypes.number,
  yearsAfter: PropTypes.number,
};

export default Controls;
