import isWithinRange from 'date-fns/isWithinInterval';
import isSameDay from 'date-fns/isSameDay';
import eachDay from 'date-fns/eachDayOfInterval';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import startOfToday from 'date-fns/startOfToday';
import startOfMonth from 'date-fns/startOfMonth';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import { dateEquality } from '../../utils/dateEquality';

export const isInUnavailableDates = (unavailableDates = [], date) => unavailableDates.some((_date) => isSameDay(date, _date));
export function isDateSelected(date, startDate, endDate) {
  if (startDate && endDate) {
    return isWithinRange(date, { start: startDate, end: endDate });
  }
  return false;
}
export function isSingleDateSelected(date, selectedDate) {
  return dateEquality(date, selectedDate);
}
export function isFirstOrLastSelectedDate(date, startDate, endDate) {
  return !!(
    (startDate && isSameDay(date, startDate))
    || (endDate && isSameDay(date, endDate))
  );
}
export function isLastSelectedDate(date, startDate, endDate) {
  return !!(endDate && isSameDay(date, endDate));
}
export function isFirstSelectedDate(date, startDate) {
  return !!(startDate && isSameDay(date, startDate));
}
export function isDateBlocked({
  date,
  minBookingDate,
  maxBookingDate,
  isDateBlockedFn,
  startDate,
  endDate,
  minBookingDays = 1,
  unavailableDates = [],
}) {
  const compareMinDate = minBookingDate
    ? new Date(
      minBookingDate.getFullYear(),
      minBookingDate.getMonth(),
      minBookingDate.getDate(),
      0,
      0,
      0,
    )
    : minBookingDate;
  const compareMaxDate = maxBookingDate
    ? new Date(
      maxBookingDate.getFullYear(),
      maxBookingDate.getMonth(),
      maxBookingDate.getDate(),
      0,
      0,
      0,
    )
    : maxBookingDate;
  return !!(
    isInUnavailableDates(unavailableDates, date)
    || (compareMinDate && isBefore(date, compareMinDate))
    || (compareMaxDate && isAfter(date, compareMaxDate))
    || (startDate
      && !endDate
      && minBookingDays > 1
      && isWithinRange(date, {
        start: startDate,
        end: addDays(startDate, minBookingDays - 2),
      }))
    || (isDateBlockedFn && isDateBlockedFn(date))
  );
}
export function getDateMonthAndYear(date) {
  const today = startOfMonth(date);
  const year = getYear(today);
  const month = getMonth(today);
  return {
    year,
    month,
    date: today,
  };
}
export function getCurrentYearMonthAndDate() {
  return getDateMonthAndYear(startOfToday());
}
export function getInitialMonths(numberOfMonths, startDate) {
  const firstMonth = startDate
    ? getDateMonthAndYear(startDate)
    : getCurrentYearMonthAndDate();
  let prevMonthDate = firstMonth.date;
  let months = [firstMonth];
  if (numberOfMonths > 1) {
    months = Array.from(Array(numberOfMonths - 1).keys()).reduce((m) => {
      prevMonthDate = addMonths(m[m.length - 1].date, 1);
      return m.concat([getDateMonthAndYear(prevMonthDate)]);
    }, months);
  }
  return months;
}
export function getNextActiveMonth(activeMonth, numberOfMonths, counter) {
  const prevMonth = counter > 0 ? activeMonth.length - 1 : 0;
  let prevMonthDate = activeMonth[prevMonth].date;
  return Array.from(Array(numberOfMonths).keys()).reduce((m) => {
    if (m.length === 0) {
      prevMonthDate = addMonths(prevMonthDate, counter);
    } else {
      prevMonthDate = addMonths(prevMonthDate, counter >= 0 ? 1 : -1);
    }
    return counter > 0
      ? m.concat([getDateMonthAndYear(prevMonthDate)])
      : [getDateMonthAndYear(prevMonthDate)].concat(m);
  }, []);
}
export function getInputValue(date, displayFormat, defaultValue) {
  if (date && typeof displayFormat === 'string') {
    return format(date, displayFormat);
  } if (date && typeof displayFormat === 'function') {
    return displayFormat(date);
  }
  return defaultValue;
}
export function canSelectRange({
  startDate,
  endDate,
  isDateBlocked,
  minBookingDays,
  exactMinBookingDays,
  minBookingDate,
  maxBookingDate,
}) {
  const isStartDateAfterOrEqualMinDate = minBookingDate
    ? !isBefore(startDate, addDays(minBookingDate, -1))
    : true;
  const isStartDateBeforeOrEqualMaxDate = maxBookingDate
    ? !isAfter(addDays(startDate, minBookingDays - 1), maxBookingDate)
    : true;
  if (
    startDate
    && minBookingDays === 1
    && !endDate
    && !isDateBlocked(startDate)
  ) {
    return true;
  } if (
    (startDate && minBookingDays > 1 && !endDate && !exactMinBookingDays)
    || (startDate
      && minBookingDays > 0
      && exactMinBookingDays
      && isStartDateAfterOrEqualMinDate
      && isStartDateBeforeOrEqualMaxDate)
    || (startDate
      && minBookingDays > 0
      && exactMinBookingDays
      && !minBookingDate
      && !maxBookingDate)
  ) {
    return eachDay({
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    }).reduce((returnValue, date) => {
      if (!returnValue) {
        return returnValue;
      }
      return !isDateBlocked(date);
    }, true);
  } if (startDate && endDate && !exactMinBookingDays) {
    const minBookingDaysDate = addDays(startDate, minBookingDays - 1);
    if (isBefore(endDate, minBookingDaysDate)) {
      return false;
    }
    return eachDay({ start: startDate, end: endDate }).reduce(
      (returnValue, date) => {
        if (!returnValue) {
          return returnValue;
        }
        return !isDateBlocked(date);
      },
      true,
    );
  }
  return false;
}
export function isDateHovered({
  date,
  startDate,
  endDate,
  isDateBlocked,
  hoveredDate,
  minBookingDays,
  exactMinBookingDays,
}) {
  if (
    // exact min booking days
    hoveredDate
    && minBookingDays > 1
    && exactMinBookingDays
    && isWithinRange(date, {
      start: hoveredDate,
      end: addDays(hoveredDate, minBookingDays - 1),
    })
  ) {
    return eachDay({
      start: hoveredDate,
      end: addDays(hoveredDate, minBookingDays - 1),
    }).reduce((returnValue, date) => {
      if (!returnValue) {
        return returnValue;
      }
      return !isDateBlocked(date);
    }, true);
  } if (
    // min booking days
    startDate
    && !endDate
    && hoveredDate
    && isWithinRange(date, {
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    })
    && isSameDay(startDate, hoveredDate)
    && minBookingDays > 1
  ) {
    return eachDay({
      start: startDate,
      end: addDays(startDate, minBookingDays - 1),
    }).reduce((returnValue, date) => {
      if (!returnValue) {
        return returnValue;
      }
      return !isDateBlocked(date);
    }, true);
  } if (
    // normal
    startDate
    && !endDate
    && hoveredDate
    && !isBefore(hoveredDate, startDate)
    && isWithinRange(date, { start: startDate, end: hoveredDate })
  ) {
    return eachDay({ start: startDate, end: hoveredDate }).reduce(
      (returnValue, date) => {
        if (!returnValue) {
          return returnValue;
        }
        return !isDateBlocked(date);
      },
      true,
    );
  }
  return false;
}
