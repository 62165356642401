import React from 'react';
import Menu from '@crpt-ui/menu';
import * as Icons from '@crpt-ui/icons';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import routes from '../../routes';
import HeaderLogo from './header-logo';
import Footer from './footer';
import MenuItem from './menuItem';
import { Translate } from '../translate';

const itemsParticipant = [
  {
    label: 'Главная',
    value: routes.home(),
    icon: <Icons.Home />,
  },
  {
    label: 'Товары',
    value: routes.goods(),
    icon: <Icons.Goods />,
  },
  {
    label: 'Коды маркировки',
    value: routes.markCodes(),
    icon: <Icons.Scan />,
  },
  {
    label: 'Операции',
    value: routes.operations(),
    icon: <Icons.Action />,
  },
];

const itemsOperator = [
  {
    label: 'Главная',
    value: routes.home(),
    icon: <Icons.Home />,
  },
  {
    label: 'Участники',
    value: routes.turnoverParticipants(),
    icon: <Icons.Participant />,
  },
  {
    label: 'Пользователи',
    value: routes.users(),
    icon: <Icons.Profile />,
  },
  {
    label: 'Товары',
    value: routes.goods(),
    icon: <Icons.Goods />,
  },
  {
    label: 'Платежи',
    value: routes.payments(),
    icon: <Icons.Wallet />,
  },
  {
    label: 'Начисления',
    value: routes.accruals(),
    icon: <Icons.ExciseTaxes />,
  },
  {
    label: 'Операции',
    value: routes.operations(),
    icon: <Icons.Action />,
  },
];

export const MainMenu = ({ currentUser }) => {
  const history = useHistory();
  const location = useLocation();

  const menuItems = currentUser.type === 'OPERATOR' ? itemsOperator : itemsParticipant;

  const isActive = (path) => (path === '/'
    ? location.pathname === path
    : location.pathname.startsWith(path));

  return (
    <Menu expandable expandOnHover Header={HeaderLogo} Footer={Footer}>
      {menuItems.map((item, key) => (
        <MenuItem
          icon={item.icon || <Icons.Menu />}
          key={key}
          active={isActive(item.value)}
          onClick={() => history.push(item.value)}
        >
          {Translate(item.label)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export const mapStateToProps = ({ currentUser }) => ({ currentUser });

const ConnectedComponent = connect(mapStateToProps, {})(MainMenu);

export default ConnectedComponent;
