import fp from 'lodash/fp';
import types from '../types';

const initialState = {
  table: {
    page: 0, size: 10, totalElements: 0, totalPages: 0,
  },
  common: {},
  dict: { totalPages: 0 },
  isLoading: false,
  password: {},
  language: localStorage.getItem('language') || 'ru',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SETTINGS:
      return {
        ...state,
        table: { ...state.table, ...action.$payload },
      };
    case types.SET_DICT_SETTINGS:
      return {
        ...state,
        dict: { ...action.$payload },
      };
    case types.SET_COMMON_SETTINGS:
      return {
        ...state,
        common: parseSettings({ ...action.$payload }),
      };
    case types.SET_CLEAR_SETTINGS:
      return {
        ...state,
        table: {
          page: 0, size: 10, totalElements: 0, totalPages: 0,
        },
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.$payload,
      };
    case types.CHECK_PASSWORD:
      return {
        ...state,
        password: action.$payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.$payload,
      };
    default:
      return state;
  }
};

const parseSettings = (data) => ({
  countryCode: fp.find((item) => item.key === 'country')(data).value,
  version: fp.find((item) => item.key === 'version')(data).value,
  gwVersion: fp.find((item) => item.key === 'gwVersion')(data).value,
  mode: fp.find((item) => item.key === 'mode')(data).value,
  session: fp.find((item) => item.key === 'session')(data)?.value * 60000 || 30 * 60000,
});

export default reducer;
