import { put } from 'redux-saga/effects';
import moment from 'moment';
import {
  setParticipant,
  setParticipants,
  updateParticipants,
  updateParticipantsAfterCreate,
} from '../actions/participants';
import { loadAccountsBalance } from '../actions/accounts';
import API from '../api';
import { setIsLoading, setSettings } from '../actions/common';
import { addError } from '../actions/errors';

export function* loadParticipantsSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.list(action.$payload);
    const settings = {
      // eslint-disable-next-line max-len
      page: action.$payload.page, size: action.$payload.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setParticipants({ ...json }));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadParticipantSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.item(action.$payload.id);
    yield put(setParticipant({
      ...json,
    }));
    if (json?.account?.id) {
      yield put(loadAccountsBalance({ id: json.account.id, page: 0 }));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* createParticipantSaga(action) {
  try {
    const json = yield API.rest.participants.create(action.$payload.data);
    yield put(updateParticipantsAfterCreate({
      ...json,
    }));
    if (json?.id) {
      action.$payload.onFinishRequest(json.id);
    }
    //
  } catch (err) {
    yield put(addError(err));
  }
}

export function* updateParticipantSaga(action) {
  try {
    const json = yield API.rest.participants.update(action.$payload.data);
    yield put(updateParticipants({
      ...json,
    }));
    yield put(setParticipant({
      ...json,
    }));
    action.$payload.onFinishRequest(json.id);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* patchParticipantSaga(action) {
  try {
    const json = yield API.rest.participants.patch(action.$payload.data);
    yield put(setParticipant({
      ...json,
    }));
    action.$payload.onFinishRequest(json.id);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* getCSVSaga(action) {
  try {
    const date = moment().format('YYYYMMDDHHmmss');
    const fileName = `_download_${date}.csv_`;
    const json = yield API.rest.accounts.csv(action.$payload.id, action.$payload.query, fileName);
    yield put(updateParticipantsAfterCreate({
      ...json,
    }));
    if (json?.id) {
      action.$payload.onFinishRequest(json.id);
    }
    //
  } catch (err) {
    yield put(addError(err));
  }
}

export function* downloadParticipantXlsxSaga({ $payload }) {
  try {
    yield API.rest.participants.xlsx($payload.data);
    $payload.onFinish();
  } catch (err) {
    $payload.onFinish();
    yield put(addError({ response: { data: err.data } }));
  }
}
