import { makeCheckboxGroupFilter } from '@crpt-ui/datatable/lib/Filters/CheckboxGroupFilter';
import { DefaultFilter } from '@crpt-ui/datatable/lib/Filters';
import { makeDatepickerFilter } from '../../components/filters';
import { Translate } from '../../components/translate';

export const serialNumberTypes = [
  {
    label: Translate('Самостоятельно'),
    value: 'SELF_MADE',
  },
  {
    label: Translate('Автоматически'),
    value: 'OPERATOR',
  },
];

export const filters = [
  {
    label: 'gtin',
    value: 'Код товара',
  },
  {
    label: 'type',
    value: 'Тип операции',
  },
  {
    label: 'event',
    value: 'Событие',
  },
  {
    label: 'status',
    value: 'Статус',
  },
  {
    label: 'productGroupMarkingIds',
    value: 'Товарная группа',
  },
  {
    label: 'createdAt',
    value: 'Дата создания',
  },
  {
    label: 'endAt',
    value: 'Дата окончания операции',
  },
];

export const operationTypes = [
  { label: Translate('Заказ на эмиссию КМ'), value: 'MARK_CODE_ORDER' },
  { label: Translate('Трансгран'), value: 'TRANSGRAN_REGISTRATION' },
  { label: Translate('Нанесение'), value: 'MARKING' },
  { label: Translate('Агрегация'), value: 'AGGREGATION' },
  { label: Translate('Отмена трансграна'), value: 'CANCELLATION_OF_TRANSGRAN_REGISTRATION' },
];

export const events = [
  { label: Translate('Отмена'), value: 'REJECT' },
  { label: Translate('Регистрация'), value: 'REGISTRATION' },
  { label: Translate('Приемка'), value: 'ACCEPTANCE' },
];

export const statuses = [
  { label: Translate('Черновик'), value: 'DRAFT' },
  { label: Translate('Выполняется'), value: 'PROGRESS' },
  { label: Translate('Выполнена успешно'), value: 'ACCEPTED' },
  { label: Translate('Отклонена'), value: 'REJECTED' },
  { label: Translate('Выполнена частично'), value: 'PARTIALLY_FULFILLED' },
];

export const participantStatuses = [
  { label: Translate('Активный'), value: 'ACTIVE' },
  { label: Translate('Заблокирован'), value: 'BLOCKED' },
];

const getTypesWithoutAggregation = () => operationTypes.filter((item) => item.value !== 'AGGREGATION');

export const GetHeader = (goodsGroupsList) => [
  {
    accessor: (row) => row.operationType,
    id: 'operationType',
    Header: Translate('Тип операции'),
    Filter: makeCheckboxGroupFilter(getTypesWithoutAggregation()),
    width: 180,
  },
  {
    accessor: (row) => row.groupName,
    id: 'productGroupMarkingIds',
    Header: Translate('Товарная группа'),
    Filter: makeCheckboxGroupFilter(goodsGroupsList),
  },
  {
    accessor: (row) => row.creationDate,
    id: 'createdAt',
    Header: Translate('Дата создания'),
    Filter: makeDatepickerFilter(),
    width: 200,
  },
  {
    accessor: (row) => row.codeCount,
    id: 'kmsCount',
    Header: Translate('Количество КМ'),
    width: 150,
  },
  {
    accessor: (row) => row.event,
    id: 'lastEventType',
    Header: Translate('Событие'),
    width: 150,
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Header: Translate('Статус'),
    Filter: makeCheckboxGroupFilter(statuses),
    width: 160,
  },
  {
    accessor: (row) => row.endDate,
    id: 'endAt',
    Header: Translate('Дата окончания операции'),
    Filter: makeDatepickerFilter(),
    width: 260,
  },
];

export const GetOperatorHeader = (goodsGroupsList) => [
  {
    accessor: (row) => row.operationType,
    id: 'operationType',
    Header: Translate('Тип операции'),
    Filter: makeCheckboxGroupFilter(getTypesWithoutAggregation()),
    width: 180,
  },
  {
    accessor: (row) => row.groupName,
    id: 'productGroupMarkingIds',
    Header: Translate('Товарная группа'),
    Filter: makeCheckboxGroupFilter(goodsGroupsList),
  },
  {
    accessor: (row) => row.endDate,
    id: 'endAt',
    Header: Translate('Дата окончания операции'),
    Filter: makeDatepickerFilter(),
    width: 260,
  },
  {
    accessor: (row) => row.status,
    id: 'status',
    Header: Translate('Статус'),
    Filter: makeCheckboxGroupFilter(statuses),
    width: 160,
  },
  {
    accessor: (row) => row.participant.inn,
    id: 'participantInn',
    Filter: DefaultFilter,
    Header: Translate('ИНН УОТ'),
    width: 260,
  },
  {
    accessor: (row) => row.participant.shortName,
    id: 'participant.shortName',
    Header: Translate('Краткое наименование УОТ'),
    width: 260,
  },
  {
    accessor: (row) => row.participant.status,
    id: 'participantStatus',
    Header: Translate('Статус УОТ'),
    Filter: makeCheckboxGroupFilter(participantStatuses),
    width: 260,
  },
  {
    accessor: (row) => row.createdBy,
    id: 'createdBy',
    Header: Translate('ФИО пользователя'),
    width: 260,
  },
];
