import { createAction } from './common';
import types from '../types';

export const chooseOperationType = createAction(types.CHOOSE_OPERATION_TYPE);
export const setOperationType = createAction(types.SET_OPERATION_TYPE);
export const createOperation = createAction(types.CREATE_OPERATION);
export const createMarkingOperation = createAction(types.CREATE_MARKING_OPERATION);
export const createMarkCodeOrderOperation = createAction(types.CREATE_MARK_ORDER_OPERATION);
export const setOperation = createAction(types.SET_OPERATION);
export const loadOperation = createAction(types.LOAD_OPERATION);
export const loadOperations = createAction(types.LOAD_OPERATIONS);
export const setOperations = createAction(types.SET_OPERATIONS);
export const loadOperationHistory = createAction(types.LOAD_OPERATION_HISTORY);
export const setOperationHistory = createAction(types.SET_OPERATION_HISTORY);
export const cancelTransgran = createAction(types.CANCEL_TRANSGRAN);
export const downloadXlsx = createAction(types.DOWNLOAD_XLSX);
