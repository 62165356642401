export const palette = {
  primary: {
    main: '#0287B0',
    iconColor: '#0287B0',
    iconColorHover: '#fff',
  },
  secondary: {
    main: '#0287B0',
  },
  text: {
    primary: '#888888',
    secondary: '#868686',
    light: '#b8b8b8',
    white: '#ffffff',
    disabled: '#d0d0d0',
  },
  error: {
    main: '#ff4b4b',
  },
  success: {
    main: '#60e84a',
  },
};
