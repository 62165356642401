/* eslint-disable no-case-declarations */
import types from '../types';

const initialState = {
  list: null,
  attributes: [],
  units: [],
};

const ParsePropertyList = (data) => (data.map(
  (item) => (
    {
      label: item.name,
      value: `${item.id}`,
      code: item.code ? item.code : '',
      subGroupdCode: item?.productSubgroupDto?.code ? item.productSubgroupDto.code : '',
      // mainGroup: item.productGroup,
    }
  ),
));

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GOODS_CARD_PROPERTIES_LIST:
      // let sortedJson = action.$payload.sort((soloAttribute) => {
      //   if (!soloAttribute.multiplication) {
      //     return -1;
      //   }
      //   return 0;
      // });
      return {
        ...state,
        list: ([...action.$payload.filter((soloProperty) => soloProperty.attributeType.code !== '2630'
              && soloProperty.attributeType.code !== '13933'
              && soloProperty.attributeType.code !== '2478')]),
      };
    case types.SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_LIST:
      return {
        ...state,
        attributes: action.$payload.map((soloAttribute) => (
          {
            attributeCode: soloAttribute.attributeCode,
            attributeValues: ParsePropertyList(soloAttribute.attributeValues),
          }
        )),
      };
    case types.SET_GOODS_CARD_PROPERTIES_ATTRIBUTE_UNITS_LIST:
      return {
        ...state,
        units: action.$payload.map((soloUnit) => (
          {
            attributeCode: soloUnit.attributeCode,
            attributeUnits: ParsePropertyList(soloUnit.attributeUnits),
          }
        )),
      };
    case types.ADD_GOODS_CARD_PROPERY:
      //
      console.log('list', state.list);
      console.log('action', action);
      let newAttribute = state.list.find((soloAttribute) => {
        if (soloAttribute.attributeType.code === action.$payload.code) {
          return true;
        }
        return false;
      });
      return {
        ...state,
        list: [...state.list.slice(0, action.$payload.index), newAttribute, ...state.list.slice(action.$payload.index, state.list.length)],

      };
    case types.REMOVE_GOODS_CARD_PROPERY: {
      let Listbuffer = [...state.list];
      Listbuffer.splice(action.$payload.index, 1);
      return {
        ...state,
        list: [...Listbuffer],
      };
    }
    case types.CLEAR_GOODS_CARD_PROPERY: {
      return {
        ...state,
        list: null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
