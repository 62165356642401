import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import fp from 'lodash/fp';
import { connect, useSelector } from 'react-redux';
import { check, logout } from '../../../actions/auth';
import { Spinner } from '../../../components/spinner';
import AuthPage from '../index';
import { useIdle } from '../../../hooks';

const Auth = ({
  check, auth, children, logout, i18n,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const timeToIdle = useSelector((state) => state?.settings?.common?.session || 1000000);
  const countryCode = useSelector((state) => state?.settings?.common.countryCode);

  const isIdle = useIdle({
    timeToIdle,
    inactivityEvents: [],
  });

  useEffect(() => {
    if (isIdle && !auth.isNotAuth) {
      logout();
    }
    // eslint-disable-next-line
    }, [isIdle]);

  useEffect(() => {
    const language = localStorage.getItem('language');
    if (!language) {
      const lang = countryCode === 'AM' ? 'am' : 'ru';
      localStorage.setItem('language', lang);
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage(language);
    }
    check({
      onFinish: () => { setIsLoading(false); },
    });
    // eslint-disable-next-line
    }, []);

  return (
    fp.isEmpty(auth) || isLoading
      ? <Spinner />
      : (auth.isNotAuth)
        ? <AuthPage />
        : children
  );
};

export const mapStateToProps = ({ auth, currentUser }) => ({ auth, currentUser });

const ConnectedComponent = compose(
  withTranslation(),
  connect(mapStateToProps, {
    check,
    logout,
  }),
)(Auth);

export default ConnectedComponent;
