import { createAction } from './common';
import types from '../types';

export const loadMarkCodes = createAction(types.LOAD_CODES);
export const setMarkCodes = createAction(types.SET_CODES);
export const clearMarkCodes = createAction(types.CLEAR_CODES);
export const loadMarkCode = createAction(types.LOAD_CODE);
export const setMarkCode = createAction(types.SET_CODE);
export const loadMarkCodeHistory = createAction(types.LOAD_CODE_HISTORY);
export const setMarkCodesHistory = createAction(types.SET_CODE_HISTORY);
export const downloadMarkCodes = createAction(types.DOWNLOAD_MARK_CODES);
export const printMarkCodes = createAction(types.PRINT_MARK_CODES_PDF);
