/* eslint-disable */
import React, {useEffect, useState} from "react";
import './index.scss';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {saveLanguage} from "../../actions/common";
import {ExpandMore} from "@material-ui/icons";
import DropDown from "../DropDown/DropDown";

export const LanguageSwitcher = () => {
  const [language, setLanguage] = useState('am');
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const country = useSelector((state) => state?.settings?.common?.countryCode);

  useEffect(() => {
    const lang = country === 'AM' ? 'am' : 'ru';
    const language = localStorage.getItem('language') || lang;
    changeLanguage(language);
  },[]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    dispatch(saveLanguage(lang));
    localStorage.setItem('language', lang);
  };

  const btn  = (props) =>
    <button className="language" {...props}>
      {language}
      <ExpandMore />
    </button>;

  const items = [
    {
      title: 'RU',
      onClick: (close) => {
        changeLanguage('ru');
        close();
      },
    },
    {
      title: 'AM',
      onClick: (close) => {
        changeLanguage('am');
        close();
      },
    }];

  return country === 'AM' ? (
    <div className="languageSwitcher">
      <DropDown renderButton={btn} items={items} />
    </div>
  ) : (<></>)
};
