/* eslint-disable */
import * as Icons from '@crpt-ui/icons';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  withStyles,
} from '@material-ui/core';
import React, { Fragment, useCallback, useRef, useState } from 'react';

import Button from './button';

const Menu = withStyles((theme) => ({
  paper: {
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.125)',
    borderRadius: '0px 0px 4px 4px',

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-track': {
      background: '#f2f2f2',
    },

    '&::-webkit-scrollbar-thumb': {
      width: 2,
      borderRadius: 2,
      background: theme.palette.text.primary,
    },
  },
  list: {
    padding: 0,
  },
}))(MuiMenu);

const MenuItem = withStyles((theme) => ({
  root: {
    fontSize: 11,
    lineHeight: 24 / 11,
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    padding: 16,
    height: 48,

    '&:hover, &:focus': {
      background: '#f7f7f7',
    },

    '&$selected, &$selected:hover': {
      background: theme.palette.primary.main,
    },
  },
  selected: {},
}))(MuiMenuItem);

const MenuButton = ({ children, items = [], ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderMenuItem = useCallback(
    ({ label, onClick, icon, ...rest }, key) => (
      <MenuItem
        key={`${label}-${key}`}
        onClick={(event) => {
          if (typeof onClick === 'function') {
            onClick(event);
          }
          handleClose();
        }}
        disableRipple
        disableTouchRipple
        {...rest}
      >
        {icon ? <span style={{ marginRight: 8 }}>{icon}</span> : null}
        {label}
      </MenuItem>
    ),
    [],
  );

  const renderMenuItems = useCallback(
    () =>
      items.map((item, key) => {
        if (React.isValidElement(item)) {
          return item;
        }

        return renderMenuItem(item, key);
      }),
    [items],
  );

  return (
    <Fragment>
      <Button innerRef={buttonRef} onClick={handleClick} {...rest}>
        {children ? (
          <Fragment>
            <span style={{ marginRight: 8 }}>{children}</span>
            <span
              style={{
                marginRight: -8,
                transform: anchorEl ? 'rotate(180deg)' : undefined,
              }}
            >
              <Icons.ChevronDown />
            </span>
          </Fragment>
        ) : null}
      </Button>
      <Menu
        PaperProps={{
          style: {
            minWidth: buttonRef.current
              ? buttonRef.current.offsetWidth
              : undefined,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        keepMounted
        disableAutoFocusItem
        getContentAnchorEl={null}
        marginThreshold={0}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transitionDuration={0}
      >
        {renderMenuItems()}
      </Menu>
    </Fragment>
  );
};

export default MenuButton;
