import { put } from 'redux-saga/effects';
import API from '../api';
import { addError } from '../actions/errors';

import {
  setCountryDictionary,
  setTnvedDictionary, setTnvedRootDictionary,
  setProductTypeList,
  setProductSubTypeList,
  setLoadProductGroupsOUDList, setDictSettings,
} from '../actions/dictionaries';
import { setSettings } from '../actions/common';
import { setParticipants } from '../actions/participants';

export function* loadCountriesDictionarySaga(action) {
  try {
    const json = yield API.rest.dictionaries.countries(action.$payload);
    yield put(setCountryDictionary({ ...json }));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadTnvedDictionarySaga(action) {
  try {
    const json = yield API.rest.dictionaries.tnved(action.$payload.data);
    const settings = {
      page: action.$payload.data.page, size: action.$payload.data.size, totalPages: json.totalPages, totalElements: json.totalElements,
    };
    yield put(setSettings({ ...settings }));
    yield put(setTnvedDictionary({ ...json }));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadTnvedRootDictionarySaga() {
  try {
    const json = yield API.rest.dictionaries.rootTnved();
    yield put(setTnvedRootDictionary({ ...json }));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadProductTypesList() {
  try {
    const json = yield API.rest.dictionaries.productTypes();
    yield put(setProductTypeList(json));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadProductSubTypesList() {
  try {
    const json = yield API.rest.dictionaries.productSubTypes();
    yield put(setProductSubTypeList(json));
  } catch (err) {
    yield put(addError(err));
  }
}
// loadProductGroupsOUDList
export function* loadProductGroupsOUDListSaga(action) {
  try {
    const json = yield API.rest.dictionaries.roductGroupsOUDList({
      countryId: action.$payload.countryId,
      ...action.$payload.aggregation ? { aggregation: action.$payload.aggregation } : {},
    });
    yield put(setLoadProductGroupsOUDList(json));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadParticipantsDictSaga(action) {
  try {
    const json = yield API.rest.participants.list(action.$payload);
    yield put(setParticipants({ ...json }));
    yield put(setDictSettings({ totalPages: json.totalPages }));
  } catch (err) {
    yield put(addError(err));
  }
}
