import React from 'react';
import LogoKZBig from '../../../assets/logos/logoBigKZ.jpg';
import LogoKZSmall from '../../../assets/logos/logoSmallKZ.jpg';
import LogoAM from '../../../assets/logos/logoAM.png';
import LogoSmallAM from '../../../assets/logos/logoSmallAM.png';
import LogoKGBig from '../../../assets/logos/logo.png';
import LogoKGSmall from '../../../assets/logos/logo_short.png';
import LogoUZ from '../../../assets/logos/logo.png';
import LogoRU from '../../../assets/logos/logo_ru.svg';
import LogoRUSmall from '../../../assets/logos/ru_small.svg';

export const Logo = ({ country }) => {
  switch (country) {
    case 'KZ': return (<img src={LogoKZBig} alt="" />);
    case 'AM': return (<img src={LogoAM} alt="" />);
    case 'KG': return (<img src={LogoKGBig} alt="" />);
    case 'RU': return (<img src={LogoRU} alt="" />);
    case 'UZ': return (<img src={LogoUZ} alt="" />);
    default: return (<img src={LogoRU} alt="" />);
  }
};

export const LogoShort = ({ country }) => {
  switch (country) {
    case 'KZ': return (<img src={LogoKZSmall} alt="" />);
    case 'AM': return (<img src={LogoSmallAM} alt="" />);
    case 'KG': return (<img src={LogoKGSmall} alt="" />);
    case 'RU': return (<img src={LogoRUSmall} alt="" />);
    case 'UZ': return (<img src={LogoUZ} alt="" />);
    default: return (<img src={LogoRUSmall} alt="" />);
  }
};
