/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAM from './components/locales/am/translations.json';
import translationRU from './components/locales/ru/translations.json';

const resources = {
  am: {
    translation: translationAM,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
