import { put } from 'redux-saga/effects';
import {
  setPasswordError,
  setUser,
  setUsers,
  updateUsers,
  updateUsersAfterCreate,
} from '../actions/users';
import API from '../api';
import { setIsLoading, setSettings } from '../actions/common';
import { addError } from '../actions/errors';

export function* loadUsersSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.users.list(action.$payload);
    const settings = {
      page: action.$payload.page,
      size: action.$payload.size,
      totalPages: json.totalPages,
      totalElements: json.totalElements,
    };
    yield put(setUsers({ ...json }));
    yield put(setSettings({ ...settings }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    // if (err?.response?.data?.error_description?.indexOf('Access token expired') === 0) {
    //     yield put(reconnect({
    //         onFinish: loadUsers({ ...action.$payload }),
    //         error: err,
    //       }));
    // }
    // yield put(addError(err));
  }
}

export function* loadUserSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.users.item(action.$payload.id);
    yield put(setUser({
      ...json,
    }));
    yield put(setIsLoading(false));
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(setIsLoading(false));
    const e = err;
    console.warn(err);
    yield put(addError(e));
  }
}

export function* createUserSaga(action) {
  try {
    const json = yield API.rest.users.create(action.$payload.body);
    yield put(updateUsersAfterCreate({
      ...json,
    }));
    yield put(setUser({
      ...json,
    }));
    action.$payload.closeFormFunc();
  } catch (err) {
    yield put(addError(err));
  }
}

export function* updateUserSaga(action) {
  try {
    const json = yield API.rest.users.update(action.$payload.data);
    yield put(updateUsers({
      ...json,
    }));
    yield put(setUser({
      ...json,
    }));
    action.$payload.closeFormFunc();
  } catch (err) {
    yield put(addError(err));
  }
}

export function* patchUserSaga(action) {
  try {
    yield put(setPasswordError(null));
    const json = yield API.rest.users.patch(action.$payload.data);
    yield put(setUser({
      ...json,
    }));
    action.$payload.closeFormFunc();
  } catch (err) {
    yield put(setPasswordError(err.response.data.message));
    // yield put(addError(err));
  }
}
