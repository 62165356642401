import {
  all, takeLatest, put, select, takeEvery,
} from 'redux-saga/effects';
import types from '../types';
import {
  createParticipantSaga,
  loadParticipantSaga,
  loadParticipantsSaga,
  updateParticipantSaga,
  patchParticipantSaga,
  getCSVSaga, downloadParticipantXlsxSaga,
} from './participant';
import {
  createUserSaga, loadUserSaga, loadUsersSaga, patchUserSaga, updateUserSaga,
} from './users';
import { clearErrors } from '../actions/errors';
import {
  checkAuthSaga, getCurrentUserSaga, loginSaga, logoutByBlockSaga, logoutSaga, reconnectSaga,
} from './auth';
import {
  cancelAggregationSaga,
  cancelTransgranSaga,
  createAggregationSaga,
  createMarkCodeOrderOperationSaga,
  createMarkingOperationSaga,
  createOperationSaga, downloadXlsxSaga,
  loadOperationHistorySaga,
  loadOperationSaga,
  loadOperationsSaga,
  setOperationTypeSaga,
} from './operations';
import {
  downloadFileSaga, loadSettingsSaga, redirectToSaga, setErrorSaga, uploadFileSaga, uploadFileAnyTypeSaga,
} from './common';
import {
  loadCountriesDictionarySaga,
  loadTnvedDictionarySaga, loadTnvedRootDictionarySaga,
  loadProductTypesList, loadProductSubTypesList,
  loadProductGroupsOUDListSaga, loadParticipantsDictSaga,
} from './dictionaries';
import {
  clearProductSaga,
  createProductSaga,
  loadGoodsSaga,
  loadProductSaga,
  patchProductSaga,
  updateProductSaga,
  getGoodCardSaga,
  getSoloGoodCardPropertyValuesSaga,
} from './goods';
import {
  clearMarkCodesSaga,
  downloadMarkCodesCsv,
  loadMarkCodeHistorySaga,
  loadMarkCodeSaga,
  loadMarkCodesSaga,
  printMarkCodesPdf,
} from './mark-codes';
import {
  loadServicesSaga,
} from './services';
import { loadAccountBalanceSaga, loadPaymentAccountsSaga } from './accounts';
import {
  addAccountPaymentSaga, cancelPaymentSaga, getPaymentsHistorySaga, setFileCommitsSaga,
} from './payments';
import { getCurrentUser } from './selectors';
import { redirect } from '../actions/common';
import routes from '../routes';
import { downloadAccrualsSaga, loadAccrualsSaga } from './accruals';
import {
  checkBeforeEmissionSaga,
  checkBeforeMarkingSaga, checkBeforePrintSaga, checkBeforePrintWithMarkingSaga, loadCodesLimitSaga,
} from './balance';

export function* clearErrorSaga(action) {
  yield put(clearErrors(action?.$payload));
}

const passAppProps = (saga, type) => function* (action) {
  const currentUser = yield select(getCurrentUser);
  const payload = typeof action.$payload === 'object' ? action.$payload : { payloadData: action.$payload };
  if (currentUser.type === type) {
    return yield saga({ ...action, ...{ $payload: { ...payload } } });
  }
  yield redirectToSaga(redirect(routes.home(), 'replace'));
};

function* rootSaga() {
  yield all([
    takeEvery(types.REDIRECT_TO, redirectToSaga),
    takeLatest(types.UPLOAD_FILE_MARK_CODES, uploadFileSaga),
    takeLatest(types.UPLOAD_FILE_ANY_TYPE, uploadFileAnyTypeSaga),

    takeLatest(types.DOWNLOAD_FILE, downloadFileSaga),
    takeLatest(types.LOGIN, loginSaga),
    takeLatest(types.LOGOUT, logoutSaga),
    takeLatest(types.RECONNECT_AUTH_DATA, reconnectSaga),
    takeLatest(types.CHECK_AUTH_DATA, checkAuthSaga),
    takeLatest(types.GET_CURRENT_USER, getCurrentUserSaga),
    takeLatest(types.REMOVE_ERROR, clearErrorSaga),
    takeLatest(types.SET_ERROR, setErrorSaga),
    takeLatest(types.LOAD_SETTINGS, loadSettingsSaga),
    takeLatest(types.LOAD_COUNTRY_DICTIONARY, loadCountriesDictionarySaga),
    takeLatest(types.LOAD_TNVED_DICTIONARY, loadTnvedDictionarySaga),
    takeLatest(types.LOAD_TNVED_ROOT_DICTIONARY, loadTnvedRootDictionarySaga),
    takeLatest(types.LOAD_PRODUCT_TYPES, loadProductTypesList),
    takeLatest(types.LOAD_PRODUCT_SUB_TYPES, loadProductSubTypesList),
    takeLatest(types.LOAD_PRODUCT_GROUPS_UOD_LIST, loadProductGroupsOUDListSaga),
    takeLatest(types.DOWNLOAD_PARTICIPANT_XLSX, downloadParticipantXlsxSaga),
    takeLatest(types.LOAD_PARTICIPANT_DICTIONARY, passAppProps(loadParticipantsDictSaga, 'OPERATOR')),

    takeLatest(types.LOAD_PARTICIPANTS, passAppProps(loadParticipantsSaga, 'OPERATOR')),
    takeLatest(types.LOAD_PARTICIPANT, loadParticipantSaga),
    takeLatest(types.CREATE_PARTICIPANT, passAppProps(createParticipantSaga, 'OPERATOR')),
    takeLatest(types.UPDATE_PARTICIPANT, passAppProps(updateParticipantSaga, 'OPERATOR')),
    takeLatest(types.PATCH_PARTICIPANT, passAppProps(patchParticipantSaga, 'OPERATOR')),

    takeLatest(types.LOAD_USERS, passAppProps(loadUsersSaga, 'OPERATOR')),
    takeLatest(types.LOAD_USER, passAppProps(loadUserSaga, 'OPERATOR')),
    takeLatest(types.UPDATE_USER, passAppProps(updateUserSaga, 'OPERATOR')),
    takeLatest(types.PATCH_USER, patchUserSaga),
    takeLatest(types.CREATE_USER, passAppProps(createUserSaga, 'OPERATOR')),

    takeLatest(types.LOAD_ACCRUALS, passAppProps(loadAccrualsSaga, 'OPERATOR')),
    takeLatest(types.DOWNLOAD_ACCRUALS, passAppProps(downloadAccrualsSaga, 'OPERATOR')),

    takeLatest(types.CHOOSE_OPERATION_TYPE, passAppProps(setOperationTypeSaga, 'PARTICIPANT')),
    takeLatest(types.CREATE_OPERATION, passAppProps(createOperationSaga, 'PARTICIPANT')),
    takeLatest(types.CREATE_MARKING_OPERATION, passAppProps(createMarkingOperationSaga, 'PARTICIPANT')),
    takeLatest(types.CREATE_MARK_ORDER_OPERATION, passAppProps(createMarkCodeOrderOperationSaga, 'PARTICIPANT')),
    takeLatest(types.LOAD_OPERATIONS, loadOperationsSaga),
    takeLatest(types.DOWNLOAD_XLSX, downloadXlsxSaga),
    takeLatest(types.LOAD_OPERATION, passAppProps(loadOperationSaga, 'PARTICIPANT')),
    takeLatest(types.LOAD_OPERATION_HISTORY, passAppProps(loadOperationHistorySaga, 'PARTICIPANT')),

    takeLatest(types.LOAD_GOODS_LIST, loadGoodsSaga),
    takeLatest(types.LOAD_PRODUCT, loadProductSaga),

    takeLatest(types.CREATE_PRODUCT, createProductSaga),
    takeLatest(types.UPDATE_PRODUCT, updateProductSaga),
    takeLatest(types.PATCH_PRODUCT, patchProductSaga),
    takeLatest(types.CLEAR_PRODUCT, clearProductSaga),
    takeLatest(types.GET_GOOD_CARD, getGoodCardSaga),
    takeLatest(types.GET_GOODS_CARD_SOLO_PROPERTY_VALUES, getSoloGoodCardPropertyValuesSaga),

    takeLatest(types.LOAD_CODES, passAppProps(loadMarkCodesSaga, 'PARTICIPANT')),
    takeLatest(types.CLEAR_CODES, passAppProps(clearMarkCodesSaga, 'PARTICIPANT')),
    takeLatest(types.LOAD_CODE_HISTORY, passAppProps(loadMarkCodeHistorySaga, 'PARTICIPANT')),
    takeLatest(types.LOAD_CODE, passAppProps(loadMarkCodeSaga, 'PARTICIPANT')),
    takeLatest(types.DOWNLOAD_MARK_CODES, passAppProps(downloadMarkCodesCsv, 'PARTICIPANT')),
    takeLatest(types.PRINT_MARK_CODES_PDF, passAppProps(printMarkCodesPdf, 'PARTICIPANT')),

    takeLatest(types.GET_OPERATOR_SERVISE, loadServicesSaga),
    takeLatest(types.GET_ACCOUNTS_BALANCES_HISTORY, loadAccountBalanceSaga),

    takeLatest(types.ADD_ACCOUNTS_PAY, addAccountPaymentSaga),
    takeLatest(types.GET_ACCOUNTS_PAY, getPaymentsHistorySaga),
    takeLatest(types.CANCEL_PAYMENT, cancelPaymentSaga),
    takeLatest(types.SET_COMMIT_FILE_PAY, setFileCommitsSaga),
    takeLatest(types.LOAD_PAY_CSV, getCSVSaga),
    takeLatest(types.LOGOUT_BY_BLOCK, logoutByBlockSaga),

    takeLatest(types.LOAD_PAYMENT_ACCOUNTS, loadPaymentAccountsSaga),

    takeLatest(types.LOAD_CODES_LIMIT, loadCodesLimitSaga),
    takeLatest(types.CHECK_BEFORE_EMISSION, checkBeforeEmissionSaga),
    takeLatest(types.CHECK_BEFORE_PRINT, checkBeforePrintSaga),
    takeLatest(types.CHECK_BEFORE_MARKING, checkBeforeMarkingSaga),
    takeLatest(types.CHECK_BEFORE_PRINT_WITH_MARKING, checkBeforePrintWithMarkingSaga),

    takeLatest(types.CREATE_AGGREGATION, createAggregationSaga),
    takeLatest(types.CANCEL_TRANSGRAN, cancelTransgranSaga),
    takeLatest(types.CANCEL_AGGREGATION, cancelAggregationSaga),

  ]);
}

export default rootSaga;
