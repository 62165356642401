import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router';
import routes from '../../routes';
import { setError } from '../../actions/errors';

const AuthRoute = ({
  Component, path, exact = false, requiredRoles, currentUser, setError,
}) => {
  let userHasRequiredRole = requiredRoles.includes(currentUser.type);
  if (currentUser.type === 'PARTICIPANT'
        && path === routes.goods('edit/:id')
        && currentUser.participant.status !== 'ACTIVE') {
    userHasRequiredRole = false;
  }
  if (!userHasRequiredRole) {
    setError({ response: { data: { error_description: 'Недостаточно прав!!!' } } });
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => (userHasRequiredRole
        ? <Component {...props} />
        : <Redirect to={routes.home()} />)}
    />
  );
};

export const mapStateToProps = ({ currentUser }) => ({ currentUser });

const ConnectedComponent = connect(mapStateToProps, {
  setError,
})(AuthRoute);

export default ConnectedComponent;
