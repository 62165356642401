import fp from 'lodash/fp';
import { saveAs } from 'file-saver';
import { get } from 'lodash';

export const CONTENT_TYPES = {
  json: 'application/json',
  formdata: 'multipart/form-data',
  form: 'application/x-www-form-urlencoded',
  patch: 'application/merge-patch+json',
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  default: '*/*',
};

const URL_WITHOUT_VERSION = [
  '/oauth', '/payments', '/mock_recognition',
];

export const saveAsFile = (response, filename) => {
  saveAs(response.data, filename.slice(0, -1).slice(1));
  return response;
};

export const checkContentType = fp.curry(
  (response, contentType) => !!~(fp.split('; ', response.headers['content-type'])[0] || '')
    .toLowerCase()
    .indexOf(contentType),
);

export const isHasBody = (method) => ['POST', 'PUT', 'PATCH'].includes(method.toUpperCase());

export const getContentDispositionFilename = (response, fallback) => (
  fp.pipe(
    fp.defaultTo(''),
    fp.split(';'),
    fp.map(fp.trim),
    fp.find((v) => /^filename=/i.test(v)),
    fp.split('='),
    fp.last,
    fp.defaultTo(fallback),
  )(response.headers['content-disposition'])
);

export const isNeedVersionApi = (url) => fp.filter(
  (v) => ~url.indexOf(v),
)(URL_WITHOUT_VERSION).length > 0;

export const getAuthErrorText = (err) => {
  const status = get(err, 'response.status', '');
  const error = get(err, 'response.data.message', '');
  switch (status) {
    case 403: return getErrorMessage(error);
    case 500: return 'Сервер временно недоступен. Повторите попытку позже..';
    default: return 'Неверный логин и/или пароль';
  }
};

const getErrorMessage = (error) => {
  switch (error) {
    case 'Bad credentials': return 'Неверный логин и/или пароль';
    case 'User account is blocked': return 'Учетная запись заблокирована. Обратитесь в Службу Технической поддержки Оператора';
    case 'Traffic participant in the status "Blocked". Contact the operator\'s support service': return 'УОТ заблокирован. Обратитесь в Службу Технической поддержки Оператора';
    default: return error;
  }
};

export const getAcceptLanguage = () => {
  const locale = localStorage.getItem('language') || 'ru';
  switch (locale) {
    case 'ru': return 'ru-RU,ru';
    case 'am': return 'hy-HY,hy';
    default: return 'en-US,en';
  }
};
