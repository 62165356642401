import { makeStyles } from '@material-ui/styles';

export const usePageHeaderStyles = makeStyles((theme) => ({
  header: {
    boxShadow: 'inset 0 -1px 0px #f2f2f2',
    borderRadius: 0,
    background: 'white',
  },
  wrapper: {
    color: '#63666a',
  },
  icon: {
    '& path': {
      fill: '#888888',
    },
  },
  buttonWrapper: {
    marginLeft: 'auto',
  },
  profileIcon: (props) => ({
    alignItems: 'center',
    background: theme.palette.primary.iconColor,
    borderRadius: 4,
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    width: 40,
    '& path': {
      fill: props.country === 'RU' ? '#63666a' : 'white',
    },
  }),
  testMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    height: 60,
    paddingLeft: 50,
  },
}));
