import { put } from 'redux-saga/effects';
import API from '../api';
import { addError } from '../actions/errors';
import { setServices } from '../actions/services';
import { setIsLoading } from '../actions/common';

export function* loadServicesSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.services.getServiceList(action.$payload.data);
    yield put(setServices(json));
    yield put(setIsLoading(false));
    // action.$payload.onFinish(json.content);
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
