import { createAction } from './common';
import types from '../types';

export const loadCountryDictionary = createAction(types.LOAD_COUNTRY_DICTIONARY);
export const setCountryDictionary = createAction(types.SET_COUNTRY_DICTIONARY);

export const loadTnvedDictionary = createAction(types.LOAD_TNVED_DICTIONARY);
export const setTnvedDictionary = createAction(types.SET_TNVED_DICTIONARY);

export const loadTnvedRootDictionary = createAction(types.LOAD_TNVED_ROOT_DICTIONARY);
export const setTnvedRootDictionary = createAction(types.SET_TNVED_ROOT_DICTIONARY);

export const loadProductTypeList = createAction(types.LOAD_PRODUCT_TYPES);
export const setProductTypeList = createAction(types.SET_PRODUCT_TYPES);

export const loadProductSubTypeList = createAction(types.LOAD_PRODUCT_SUB_TYPES);
export const setProductSubTypeList = createAction(types.SET_PRODUCT_SUB_TYPES);

export const loadProductGroupsOUDList = createAction(types.LOAD_PRODUCT_GROUPS_UOD_LIST);
export const setLoadProductGroupsOUDList = createAction(types.SET_PRODUCT_GROUPS_UOD_LIST);
export const clearProductGroupsOUDList = createAction(types.CLEAR_PRODUCT_GROUPS_UOD_LIST);
export const loadParticipantDictionary = createAction(types.LOAD_PARTICIPANT_DICTIONARY);
export const setDictSettings = createAction(types.SET_DICT_SETTINGS);
