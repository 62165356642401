import qs from 'qs';

function request(url) {
  return (path = '', query = {}) => {
    const query_string = qs.stringify(query);
    return `${url}${path ? `/${path}` : ''}${query_string ? `?${query_string}` : ''}`;
  };
}

export const routes = {
  home: request('/'),
  auth: request('/auth'),
  users: request('/users'),
  goods: request('/goods'),
  payments: request('/payments'),
  goodsGroup: request('/goods_group'),
  turnoverParticipants: request('/participants'),
  operations: request('/operations'),
  markCodes: request('/codes'),
  profile: request('/profile'),
  accruals: request('/charges'),
};

export default routes;
