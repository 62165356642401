import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import './index.scss';
import { Field, Form } from 'react-final-form';
import { Button } from '@crpt-ui/core';
import { connect } from 'react-redux';
import TextField from '../../components/final-form/text-field';
import { login } from '../../actions/auth';
import { Logo } from './logo';
import { LanguageSwitcher } from '../../components/language-switcher/languageSwitcher';
import { Translate } from '../../components/translate';

class AuthPage extends React.Component {
    login = (values) => {
      const { login } = this.props;
      login({ data: values });
    };

    render() {
      const { errors, settings, t } = this.props;
      return (
        <div className="authPage">
          <div className="bg" />
          <div className="loginForm">
            <div className="loginForm__lang">
              <LanguageSwitcher />
            </div>
            <div className="loginForm__logo">
              <Logo country={settings.countryCode} />
            </div>
            <Form
              onSubmit={this.login}
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    fullWidth
                    component={TextField}
                    name="username"
                    type="text"
                    label={t('Логин')}
                    autoFocus
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                  <Field
                    fullWidth
                    component={TextField}
                    name="password"
                    type="password"
                    label={t('Пароль')}
                  />
                  {
                    errors.length > 0 && errors.map((error) => (
                      <p className="customError" key={error.id}>
                        {Translate(error.name)}
                      </p>
                    ))
                                    }
                  <div className="loginForm__actions buttonStyles">
                    <Button size="md" variant="contained" fullWidth type="submit" name="contained" disabled={!values.username || !values.password}>
                      {t('Войти в личный кабинет')}
                    </Button>
                  </div>
                  {
                                        settings.countryCode === 'KG' && (
                                        <>
                                          <p className="support">Контакты тех поддержки +996 555 905 146</p>
                                          <p className="support">whitelabel@megacom.kg</p>
                                        </>
                                        )
                                    }
                </form>
              )}
            />

          </div>
        </div>
      );
    }
}

export const mapStateToProps = (state) => ({
  history: state.router,
  errors: state.errors.errors,
  settings: state.settings.common,
});

const ConnectedComponent = compose(
  withTranslation(),
  connect(mapStateToProps, {
    login,
  }),
)(AuthPage);

export default ConnectedComponent;
