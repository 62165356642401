import fp from 'lodash/fp';
import types from '../types';
import { HELPERS } from '../helpers';
import { operationTypes, statuses } from '../pages/mark-codes/codesDictionary';

const initialState = {
  list: [],
  item: {
    participant: {},
    product: {},
  },
  history: [],
  limits: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CODES:
      return {
        ...state,
        list: parseMarkCodesList(action.$payload),
      };
    case types.SET_CODE:
      return {
        ...state,
        item: parseMarkCode(action.$payload),
      };
    case types.SET_CODE_HISTORY:
      return {
        ...state,
        history: parseMarkCodesHistoryList(action.$payload),
      };
    case types.SET_CODES_LIMIT:
      return {
        ...state,
        limits: action.$payload,
      };
    default:
      return state;
  }
};

export default reducer;

const parseMarkCodesList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => (item.markingCode
      ? parseMarkingCodeListItem(item.markingCode)
      : parseMarkingCodeListItem(item)),
  ),
)(data);

const parseMarkingCodeListItem = (item) => ({
  ...item,
  status: HELPERS.getNameFromDictionary(item.status, statuses),
  receivingDate: HELPERS.formatDate(item.dateCreate),
  country: fp.get('country.name', item),
});

const parseMarkCodesHistoryList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => ({
      ...item,
      startStatus: HELPERS.getNameFromDictionary(item.initialStatus, statuses),
      finishStatus: HELPERS.getNameFromDictionary(item.resultStatus, statuses),
      operationType: HELPERS.getNameFromDictionary(item.operationType, operationTypes),
      changeDate: HELPERS.formatDate(item.timestamp),
      operation: item.operationId,
    }),
  ),
)(data);

const parseMarkCode = (data) => ({
  ...data,
  status: HELPERS.getNameFromDictionary(data.status, statuses),
  statusValue: data.status,
  dateCreate: HELPERS.formatDate(data.dateCreate),
  dateChangeStatus: HELPERS.formatDate(data.dateChangeStatus),
  operationId: fp.get('currentOperation.id', data),
  aggregationId: fp.get('aggregationOperation.id', data),
  product: {
    ...data.product,
    tnvedCode: fp.get('product.tnved.code', data),
    developmentCountry: fp.get('product.manufacturedCountry.name', data),
    shoesType: fp.get('product.type.name', data),
    shoesSize: fp.get('product.size.name', data),
    shoesColor: fp.get('product.color.name', data),
    topShoesMaterial: fp.get('product.upperMaterial.name', data),
    shoesMaterial: fp.get('product.liningMaterial.name', data),
    bottomShoesMaterial: fp.get('product.bottomMaterial.name', data),
  },
});
