import { Button, Modal } from '@crpt-ui/core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../../components/final-form';
import { validators } from '../../../helpers/form-validators';
import { Translate } from '../../../components/translate';
import { setPasswordError } from '../../../actions/users';

const VALIDATORS = {
  password: validators.pipe(
    validators.required,
  ),
  retryPassword: (password) => validators.pipe(
    validators.required,
    validators.eq(password),
  ),
};

export const ChangePasswordModal = ({
  onClose, onSave, text, title, isExpired, role, isProfile,
}) => {
  const error = useSelector((state) => state.users?.error);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setPasswordError(null));
    onClose();
  };

  const save = (values) => {
    dispatch(setPasswordError(null));
    onSave(values);
  };

  return (
    <Modal open onClose={close} maxWidth="sm">
      <h2><strong>{Translate(title)}</strong></h2>
      {
        text && <p>{Translate(text)}</p>
      }
      {
        error && <p className="errorPassword">{error}</p>
      }
      <Form
        onSubmit={save}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {
              // eslint-disable-next-line no-mixed-operators
              (role === 'PARTICIPANT' || (role === 'OPERATOR' && isProfile) || isExpired) && (
                <Field
                  fullWidth
                  component={TextField}
                  name="currentPassword"
                  type="password"
                  label="Текущий пароль"
                  autoComplete="new-password"
                  validate={
                    VALIDATORS.password
                  }
                />
              )
            }
            <Field
              fullWidth
              component={TextField}
              name="password"
              type="password"
              label="Пароль"
              autoComplete="new-password"
              validate={
                  VALIDATORS.password
                }
            />
            <Field
              fullWidth
              component={TextField}
              name="retryPassword"
              type="password"
              label="Повторите пароль"
              autoComplete="new-password"
              validate={
                  VALIDATORS.retryPassword(values.password)
                }
            />
            <div className="buttons mt-20">
              {
                  !isExpired && (
                    <Button size="sm" variant="outlined" width={100} type="button" onClick={close}>
                      {Translate('Отменить')}
                    </Button>
                  )
                }
              <Button size="sm" variant="contained" width={100} type="submit" name="contained">
                {Translate('Сохранить')}
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

ChangePasswordModal.defaultProps = {
  text: null,
  isExpired: false,
  title: 'Введите новый пароль',
};
