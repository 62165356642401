import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../routes';
import './index.scss';
import MainMenu from '../menu';
import { Spinner } from '../spinner';
import ErrorNotification from '../notification';
import AuthRoute from '../router/index';
import PageHeader from './header';
import WarningPasswordModal from '../../pages/users-page/components/warning-password-modal';

const HomePage = lazy(() => import('../../pages/home-page'));
const TurnoverParticipantsPage = lazy(() => import('../../pages/turnover-participants-page'));
const TurnoverParticipantsEditComponent = lazy(() => import('../../pages/turnover-participants-page/components/participant-form'));
const ParticipantCard = lazy(() => import('../../pages/turnover-participants-page/components/participant-card'));
const UsersPage = lazy(() => import('../../pages/users-page'));
const AuthPage = lazy(() => import('../../pages/auth'));
const UsersCardComponent = lazy(() => import('../../pages/users-page/components/user-card'));
const UserEditComponent = lazy(() => import('../../pages/users-page/components/users-form'));
const OperationPage = lazy(() => import('../../pages/operations-page'));
const OperationForm = lazy(() => import('../../pages/operations-page/components/operation-form'));
const AggregationForm = lazy(() => import('../../pages/operations-page/components/forms/aggregation/aggregation'));
const OperationCardComponent = lazy(() => import('../../pages/operations-page/components/operation-card'));
const GoodsPage = lazy(() => import('../../pages/goods-page'));
const GoodsEditComponent = lazy(() => import('../../pages/goods-page/components/form/goods-form'));
const GoodsItemCardComponent = lazy(() => import('../../pages/goods-page/components/goods-card'));
const MarkCodesPage = lazy(() => import('../../pages/mark-codes'));
const MarkCodeCardComponent = lazy(() => import('../../pages/mark-codes/components/mark-code-card'));
const CancellationPage = lazy(() => import('../../pages/payment-page/components/cancelationPage'));

const AddGoodsGroupPage = lazy(() => import('../../pages/goods-group-page/goods-groups-add-form'));
const PaymentPage = lazy(() => import('../../pages/payment-page'));
const AccrualsPage = lazy(() => import('../../pages/charges'));

const Layout = () => (
  <div className="layout">
    <div className="header">
      <PageHeader />
    </div>
    <div className="nav">
      <MainMenu />
    </div>
    <div className="main">
      <ErrorNotification />
      <div className="page">
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={routes.home()} component={HomePage} />
            <Route exact path={routes.auth()} component={AuthPage} />

            <AuthRoute Component={UsersCardComponent} exact path={routes.profile()} requiredRoles={['PARTICIPANT', 'OPERATOR']} />
            <AuthRoute Component={GoodsPage} exact path={routes.goods()} requiredRoles={['PARTICIPANT', 'OPERATOR']} />
            <AuthRoute Component={GoodsEditComponent} exact path={routes.goods('create')} requiredRoles={['PARTICIPANT', 'OPERATOR']} />
            <AuthRoute Component={GoodsEditComponent} exact path={routes.goods('edit/:id')} requiredRoles={['PARTICIPANT', 'OPERATOR']} />
            <AuthRoute Component={GoodsItemCardComponent} exact path={routes.goods(':id')} requiredRoles={['PARTICIPANT', 'OPERATOR']} />
            <AuthRoute Component={OperationPage} exact path={routes.operations()} requiredRoles={['OPERATOR', 'PARTICIPANT']} />
            <AuthRoute Component={OperationForm} exact path={routes.operations('create')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={OperationForm} exact path={routes.operations('order/create')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={OperationForm} exact path={routes.operations('transgran/create')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={OperationForm} exact path={routes.operations('marking/create')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={AggregationForm} exact path={routes.operations('aggregation/create')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={OperationCardComponent} exact path={routes.operations(':id')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={MarkCodesPage} exact path={routes.markCodes()} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={MarkCodeCardComponent} exact path={routes.markCodes(':id')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={AddGoodsGroupPage} exact path={routes.goodsGroup('add_goods_group')} requiredRoles={['PARTICIPANT']} />
            <AuthRoute Component={UsersPage} exact path={routes.users()} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={UserEditComponent} exact path={routes.users('create')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={UserEditComponent} exact path={routes.users('edit/:id')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={UsersCardComponent} exact path={routes.users(':id')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={TurnoverParticipantsPage} exact path={routes.turnoverParticipants()} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={TurnoverParticipantsEditComponent} exact path={routes.turnoverParticipants('create')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={TurnoverParticipantsEditComponent} exact path={routes.turnoverParticipants('edit/:id')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={ParticipantCard} exact path={routes.turnoverParticipants(':id')} requiredRoles={['OPERATOR', 'PARTICIPANT']} />
            <AuthRoute Component={PaymentPage} exact path={routes.payments()} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={CancellationPage} exact path={routes.payments(':id/cancellation')} requiredRoles={['OPERATOR']} />
            <AuthRoute Component={AccrualsPage} exact path={routes.accruals()} requiredRoles={['OPERATOR']} />

            <Route
              path="*"
              render={
                    ({ location }) => (
                      <h3>
                        404:
                        {location.pathname}
                      </h3>
                    )
                }
            />
          </Switch>
        </Suspense>
      </div>
    </div>
    <WarningPasswordModal />
  </div>
);

export const mapStateToProps = ({ currentUser }) => ({ currentUser });

const ConnectedComponent = connect(mapStateToProps, {})(Layout);

export default ConnectedComponent;
