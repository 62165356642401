import { put } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import API from '../api';
import { addError } from '../actions/errors';
import types from '../types';
import {
  setCommonSettings,
} from '../actions/common';

export function* uploadFileSaga(action) {
  try {
    const json = yield API.rest.files.uploadFile([...action.$payload.data.files], '', action.$payload.query, action.$payload.type);
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(addError(err));
    action.$payload.onFinish();
  }
}

export function* uploadFileAnyTypeSaga(action) {
  try {
    const json = yield API.rest.files.uploadFileToAnyType([...action.$payload.data], '', action.$payload.query, action.$payload.type);
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(addError(err));
    action.$payload.onError(err);
  }
}

export function* downloadFileSaga(action) {
  try {
    const json = yield API.rest.files.upload(...action.$payload.data, '');
    action.$payload.onFinish(json);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* redirectToSaga(action) {
  const { pathname, method, search } = action.$payload;
  if (pathname) {
    yield put(addError({ response: { data: { error_description: 'Недостаточно прав!' } } }));
    switch (method) {
      case (types.redirectMethods.replace):
        yield put(replace({ pathname, search }));
        break;
      case (types.redirectMethods.push):
      default:
        yield put(push({ pathname, search }));
    }
  } else {
    throw new ReferenceError('please specify url link');
  }
}

export function* setErrorSaga(action) {
  try {
    yield put(addError(action.$payload));
  } catch (err) {
    yield put(addError(err));
  }
}

export function* loadSettingsSaga(action) {
  try {
    const json = yield API.rest.settings.list();
    yield put(setCommonSettings({ ...json }));
    action.$payload.onFinish(json);
  } catch (err) {
    action.$payload.onFinish(null);
    yield put(addError(err));
  }
}
