import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { removeError } from '../../actions/errors';
import './index.scss';

const ErrorNotification = ({ errors, removeError }) => {
  const onClose = (id) => {
    removeError(id);
  };
  const list = useMemo(() => errors.filter((item) => !!item.name), [errors]);

  return (errors.length > 0
    ? (
      <div className="alertContainer">
        {
          list.map(
            (error) => (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open
                // autoHideDuration={6000}
              >
                <Alert variant="filled" onClose={() => onClose(error.id)} severity="error">
                  {error.name}
                </Alert>
              </Snackbar>
            ),
          )
        }
      </div>
    ) : null);
};

const mapStateToProps = (state) => ({
  errors: state.errors.errors,
});

const ConnectedComponent = connect(mapStateToProps, {
  removeError,
})(ErrorNotification);

export default ConnectedComponent;
