/* eslint-disable */
import { get, isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { Field } from 'react-final-form';

import RangeDatePicker from "../DatePicker/RangeDatePicker";
import {generateFilter} from "./DatepickerFilter";

export const RangeDatepickerFilter = React.memo(({column, form, formState, instance, ...rest}) => {
  const validator = useCallback((value) => {
    if (isEmpty(value)) {
      return 'error';
    }

    return value.startDate && value.endDate ? null : 'error';
  }, []);

  return (
    <Field name="value" validate={validator}>
      {(props) => {
        const { input } = props;

        const fieldValue = get(input, 'value', {});
        const { startDate, endDate } = fieldValue;

        const onChange = useCallback((value) => {
          const nextValue = isEmpty(value) ? {} : value;
          input.onChange(nextValue);
        }, []);

        return (
          <div style={{ margin: '-24px -16px 0' }}>
            <RangeDatePicker
              onDateChange={onChange}
              startDate={startDate}
              endDate={endDate}
              isFilter
              {...rest}
            />
          </div>
        );
      }}
    </Field>
  );
});

export const makeRangeDatepickerFilter = generateFilter(RangeDatepickerFilter);

