import fp from 'lodash/fp';
import types from '../types';

const initialState = {
  countries: [],
  tnvedCodes: [],
  shoesTypes: [],
  shoesSizes: [],
  shoesColors: [],
  shoesMaterials: [],
  productsSubTypesList: [],
  productsGroupsUODList: [],
};
// SET_PRODUCT_GROUPS_UOD_LIST
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COUNTRY_DICTIONARY:
      return {
        ...state,
        countries: parseDictionary(action.$payload),
      };
    case types.SET_TNVED_DICTIONARY:
      return {
        ...state,
        tnvedCodes: parseTnvedDictionary(action.$payload),
      };
    case types.SET_TNVED_ROOT_DICTIONARY:
      return {
        ...state,
        tnvedRootCodes: parseTnvedRootDictionary(action.$payload),
      };
    case types.SET_SHOES_TYPES_DICTIONARY:
      return {
        ...state,
        shoesTypes: parseDictionary(action.$payload),
      };
    case types.SET_SHOES_COLORS_DICTIONARY:
      return {
        ...state,
        shoesColors: parseDictionary(action.$payload),
      };
    case types.SET_SHOES_SIZES_DICTIONARY:
      return {
        ...state,
        shoesSizes: parseDictionary(action.$payload),
      };
    case types.SET_SHOES_MATERIALS_DICTIONARY:
      return {
        ...state,
        shoesMaterials: parseDictionary(action.$payload),
      };
    case types.SET_PRODUCT_TYPES: {
      return {
        ...state,
        productsTypeList: parseDictionary(action.$payload),
      };
    }
    case types.SET_PRODUCT_SUB_TYPES: {
      return {
        ...state,
        productsSubTypesList: parseProductSubTypesDictionary(action.$payload),
      };
    }
    case types.SET_PRODUCT_GROUPS_UOD_LIST: {
      return {
        ...state,
        productsGroupsUODList: parseProductGroupsUODList(action.$payload.content),
      };
    }
    case types.CLEAR_PRODUCT_GROUPS_UOD_LIST: {
      return {
        ...state,
        productsGroupsUODList: [],
      };
    }

    default:
      return state;
  }
};

export default reducer;

const parseDictionary = (data) => fp.map(
  (item) => (
    {
      label: item.name,
      value: `${item.id}`,
      code: item.code ? item.code : '',
    }
  ),
)(data);

const parseProductSubTypesDictionary = (data) => fp.map(
  (item) => (
    {
      label: item.name,
      value: item.code,
      code: item.code ? item.code : '',
      mainGroup: item.productGroup,
    }
  ),
)(data);
const parseProductGroupsUODList = (data) => fp.map(
  (item) => (
    {
      ...item,
      label: item.name,
      value: `${item.id}`,
      code: item.code ? item.code : '',
      // mainGroup: item.productGroup,
      // id: 'wobobobob'
    }
  ),
)(data);

const parseTnvedRootDictionary = (data) => fp.pipe(
  fp.get('rootCodes'),
  fp.map(
    (item) => (
      {
        label: item,
        value: `${item}`,
      }
    ),
  ),
)(data);

const parseTnvedDictionary = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => (
      {
        ...item,
        label: item.code,
        value: `${item.id}`,
        groupCode: item.rootCode,
      }
    ),
  ),
)(data);
