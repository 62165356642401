import axios from 'axios';
import qs from 'qs';

import {
  checkContentType,
  CONTENT_TYPES, getAcceptLanguage,
  getContentDispositionFilename,
  isHasBody,
  isNeedVersionApi,
  saveAsFile,
} from '../helpers/http';
import Errors from './errors';
import { URL_BASE } from './constants';

const VERSION_API = '';
const BASIC = {
  username: 'browser', password: 'secret',
};

const resolve = ({ response, saveAs, fileName }) => {
  const isContentType = checkContentType(response);
  const response_json = isContentType(CONTENT_TYPES.json) && response.data;
  switch (response.status) {
    case 200:
    case 201:
    case 202:
      return (
        saveAs
          ? saveAsFile(
            response, fileName || getContentDispositionFilename(response, saveAs),
          )
          : (response_json || response)
      );
    case 204:
      return null;
    default:
      console.warn(`Unknown response status: ${response.status}\n`, response);
      return null;
  }
};

const reject = (response) => {
  if (response.request.responseType === 'blob' && response.data instanceof Blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        response.data = JSON.parse(reader.result);
        resolve(Promise.reject(response));
      };
      reader.onerror = () => {
        reject(response);
      };
      reader.readAsText(response.data);
    });
  }
  switch (response.status) {
    case 401: { return; }
    case 403:
      throw new Errors.Forbidden(response);
    case 400:
      throw new Errors.BadRequest(response);
    case 500:
      throw new Errors.Backend(response);
    default:
      throw new Errors.Unknown(response, response.data);
  }
};

const options = ({
  access_token,
  // accept,
  basic,
  body,
  content_type = CONTENT_TYPES.json,
  headers = {},
  method = 'POST',
  onDownloadProgress,
  onUploadProgress,
  path = '',
  query = {},
  responseType,
  token_type,
  url = (() => { throw new Error('Url is not specified'); })(),
  hideError,
}) => {
  const hasBody = isHasBody(method);
  const data = body && hasBody && (
    content_type === CONTENT_TYPES.json
      ? JSON.stringify(body)
      : content_type === CONTENT_TYPES.form
        ? qs.stringify(body)
        : body
  );
  const url_path = path ? `/${path}` : '';
  const url_query = qs.stringify({ ...query, ...!hasBody && body }, { arrayFormat: 'repeat' });
  const url_version_api = isNeedVersionApi(url) ? '' : VERSION_API;
  const url_base = url === '/oauth' ? '' : URL_BASE;
  const url_request = [url_base, url_version_api, url, url_path, url_query ? '?' : '', url_query].join('');

  return {
    method,
    ...data && { data },
    ...basic && { auth: basic },
    headers: {
      // Accept: accept ? accept : content_type ? content_type : CONTENT_TYPES.json,
      ...hasBody && { 'Content-Type': content_type },
      ...access_token && { Authorization: `${token_type} ${access_token}` },
      ...headers,
      'Accept-language': getAcceptLanguage(),
    },
    responseType,
    onUploadProgress,
    onDownloadProgress,
    url: url_request,
    hideError,
    timeout: 600000,
  };
};

const request = ({
  parent,
  reconnect = reject,
  saveAs,
  fileName,
  ...props
}) => (
  axios(options(props))
    .then(
      (response) => resolve({ response, saveAs, fileName }),
      parent
        ? reject
        : ({ response }) => reject(response),
    )
);

const connect = (props) => {
  const { reconnect } = props;
  const { access_token, refresh_token, token_type } = props.auth || {};
  const method = ({ name, ...opts }) => (args) => request({
    access_token, token_type, ...opts, ...args, reconnect, method: name,
  });

  const get = method({ name: 'GET' });
  const post = method({ name: 'POST' });
  const put = method({ name: 'PUT' });
  const patch = method({ name: 'PATCH', content_type: CONTENT_TYPES.patch });
  const remove = method({ name: 'DELETE' });

  const crud = (url) => ({
    create: (body) => post({ url, body }),
    item: (id) => get({ url, path: id }),
    list: (query = {}) => get({ url, query }),
    patch: ({ id, body }) => patch({ url, path: id, body }),
    remove: (id) => remove({ url, path: id }),
    update: ({ id, body }) => put({ url, path: id, body }),
    download: (id, onDownloadProgress) => get({
      url,
      path: id,
      responseType: 'blob',
      onDownloadProgress,
      saveAs: `download-${id}.file`,
    }),
    upload: (files, onUploadProgress) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.formdata,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
  });

  const auth = (url) => ({
    check: (token) => (
      post({
        url,
        basic: BASIC,
        path: 'check_token',
        query: { token },
      })
    ),
    reconnect: () => (
      post({
        url,
        parent: true,
        path: 'token',
        basic: BASIC,
        content_type: CONTENT_TYPES.form,
        body: { grant_type: 'refresh_token', refresh_token },
      })
    ),
    signin: ({ username, password }) => (
      post({
        url,
        path: 'token',
        basic: BASIC,
        content_type: CONTENT_TYPES.form,
        accept: 'application/json, text/plain, */*',
        body: { username, password, grant_type: 'password' },
      })
    ),
    signout: () => remove({ url, path: 'token' }),
  });

  const users = (url) => ({
    ...crud(url),
    patch: ({ id, body }) => patch({
      url, path: id, body, hideError: true,
    }),
    checkPassword: (id) => (get({ url, path: `${id}/check-password` })),
    current: () => (
      get({
        url,
        path: 'current',
      })
    ),
  });

  const operations = (url) => ({
    ...crud(url),
    markCodes: (id, query) => (
      get({
        url,
        path: `${id}/marking_codes`,
        query,
      })
    ),
    xlsx: (query) => (
      get({
        url,
        path: 'xlsx',
        query,
        content_type: CONTENT_TYPES.xlsx,
        responseType: 'blob',
        saveAs: 'operations.xlsx',
      })
    ),
    history: (id, query) => (
      get({
        url,
        path: `${id}/history`,
        query,
      })
    ),
    cancelAggregation: (body) => (post({
      url,
      path: 'aggregates/disaggregation',
      body,
    })),
  });

  const markCodes = (url) => ({
    ...crud(url),
    marking: (body) => (
      post({
        url,
        path: 'registration',
        body,
      })
    ),
    history: (id, query) => (
      get({
        url,
        path: `${id}/history`,
        query,
      })
    ),
    csv: (query) => (
      get({
        url,
        path: 'csv',
        query,
        content_type: CONTENT_TYPES.csv,
        responseType: 'blob',
        saveAs: 'mark-codes.csv',
      })
    ),
    pdf: (query) => (
      get({
        url,
        path: 'pdf',
        query,
        content_type: CONTENT_TYPES.pdf,
        responseType: 'blob',
        saveAs: 'mark-codes.pdf',
      })
    ),
  });

  const dictionaries = (url) => ({
    countries: () => (get({ url, path: 'refs/countries' })),
    tnved: (query) => (get({ url, path: 'refs/tnved', query })),
    rootTnved: () => (get({ url, path: 'refs/tnved/root_codes' })),
    productTypes: () => (get({ url, path: 'product_groups' })),
    productSubTypes: () => (get({ url, path: 'product_subgroups' })),
    roductGroupsOUDList: (query) => (get({ url, path: 'product_groups_marking', query })),
  });
  const file = (url) => ({
    uploadFile: (files, onUploadProgress, query, type) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            query,
            path: type === 'codes' ? 'mark_code' : 'serial_number',
            content_type: CONTENT_TYPES.default,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
    uploadFileToAnyType: (files, onUploadProgress, query, type) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            query,
            path: type,
            content_type: CONTENT_TYPES.default,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
  });

  const cards = (url) => ({
    getAllCard: (id) => (get({ url, path: `products_attribute_templates?productSubgroupId=${id}` })),
    getSoloCard: (id) => (get({ url, path: `products_attribute_templates/${id}` })),
    getSoloPropertyValues: (code) => (get({ url, path: `attribute_values_domains?attributeTypeCode=${code}` })),
    getSoloPropertyValueUnits: (id) => (get({ url, path: `units?attributeTypeId=${id}` })),
    getAttributeInfoFromId: (id) => (get({ url, path: `attribute_types?code=${id}` })),
  });
  const services = (url) => ({
    getServiceList: (query) => (get({ url, path: 'service', query })),
  });
  const accounts = (url) => ({
    getAccountsHistory: (({ id, ...query }) => get({ url, path: `${id}/balances_history`, query })),
    csv: (accountId, query = {}, fileName) => (
      get({
        url,
        query,
        path: `${accountId}/balances_history/csv`,
        content_type: CONTENT_TYPES.csv,
        responseType: 'blob',
        saveAs: 'account_balance_history.csv',
        fileName,
      })
    ),
  });
  const paymentAccounts = (url) => ({
    list: () => (
      get({
        url,
        path: 'account',
      })
    ),
  });
  const payments = (url) => ({
    setNewPayment: (body) => (
      post({
        url,
        body,
      })
    ),
    getAllPayments: (body) => (
      get({
        url,
        body,
      })
    ),
    commitFilePayments: (body, headers) => (
      post({
        url,
        body: { fileId: body.fileId },
        content_type: CONTENT_TYPES.form,
      })
    ),
    cancelPayment: (id) => (
      post({
        url,
        path: `${id}/cancellation`,
      })
    ),
  });

  const charges = (url) => ({
    list: (query) => (get({ url, query })),
    codesCount: (query) => (get({ url, path: 'counts', query })),
    csv: (query, fileName) => (
      get({
        url,
        path: 'csv',
        query,
        content_type: CONTENT_TYPES.csv,
        responseType: 'blob',
        saveAs: 'mark-codes.csv',
        fileName,
      })
    ),
    xlsx: (query) => (
      get({
        url,
        path: 'xlsx',
        query,
        content_type: CONTENT_TYPES.xlsx,
        responseType: 'blob',
        saveAs: 'operations.xlsx',
      })
    ),
  });

  const balance = (url) => ({
    operator_check: (query) => (get({ url, path: 'operator/check', query })),
    limit_check: (body) => (post({ url, path: 'limit_check', body })),
    debt_check: (body) => (post({ url, path: 'debt_check', body })),
    balance_sufficiency_check: (body) => (post({ url, path: 'balance_sufficiency_check', body })),
  });

  const aggregation = (url) => ({
    ...crud(url),
    cancel: (id) => (post({ url, pah: `${id}` })),
  });

  const participants = (url) => ({
    ...crud(url),
    xlsx: (query) => (
      get({
        url,
        path: 'xlsx',
        query,
        content_type: CONTENT_TYPES.xlsx,
        responseType: 'blob',
        saveAs: 'operations.xlsx',
      })
    ),
  });

  return {
    get,
    remove,
    post,
    put,
    patch,
    participants: participants('/participants'),
    users: users('/users'),
    current: crud('/users'),
    auth: auth('/oauth'),
    files: file('/files'),
    operations: operations('/operations'),
    products: crud('/products'),
    settings: crud('/settings'),
    markCodesHistory: crud('/mark_codes/history'),
    dictionaries: dictionaries(''),
    markCodes: markCodes('/mark_codes'),
    accounts: accounts('/accounts'),
    goodsCard: cards(''),
    services: services(''),
    payments: payments('/payments'),
    paymentAccounts: paymentAccounts('/balance'),
    accruals: charges('/charges'),
    balance: balance('/balance'),
    aggregation: aggregation('/aggregation'),

  };
};

export default connect;
