import { createStyles } from '@material-ui/core';

export const TitleStyles = (theme) => createStyles({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    color: theme.palette.text.primary,
    lineHeight: '12px',
    fontWeight: 600,
  },
  title: {
    fontSize: 14,
  },
});

export const CloseStyles = () => createStyles({
  root: {
    padding: '24px 16px 16px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginRight: '24px',
    fontWeight: 'bold',
  },
});
