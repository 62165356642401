import types from '../types';
import { add_, update_ } from '../helpers/redux';
import { HELPERS } from '../helpers';

const initialState = {
  list: [],
  item: {
    participant: {},
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  const update = update_(state.list);
  const add = add_(state.list);
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        list: action.$payload.content,
      };
    case types.SET_USER:
      return {
        ...state,
        item: parseUserItem(action.$payload),
      };
    case types.UPDATE_USERS:
      return {
        ...state,
        item: update(parseUserItem(action.$payload)),
      };

    case types.UPDATE_USERS_AFTER_CREATE:
      return {
        ...state,
        item: add(parseUserItem(action.$payload)),
      };
    case types.SET_PASSWORD_ERROR:
      return {
        ...state,
        error: action.$payload,
      };
    default:
      return state;
  }
};

export default reducer;

const parseUserItem = (data) => ({
  ...data,
  login: data.login ? data.login : '-',
  registrationDate: HELPERS.formatDate(data.registrationDate),
  lastPasswordUpdateDate: HELPERS.formatDate(data.lastPasswordUpdateDate),
  lastUpdateDate: HELPERS.formatDate(data.lastUpdateDate),
});
