import fp from 'lodash/fp';
import types from '../types';
import { HELPERS } from '../helpers';
import { statuses } from '../pages/goods-page/goods-dictionaries';

const initialState = {
  list: [],
  item: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GOODS_LIST:
      return {
        ...state,
        list: parseGoodsList(action.$payload),
      };
    case types.SET_PRODUCT:
      return {
        ...state,
        item: parseProduct(action.$payload.data, action.$payload.type),
      };
    case types.SET_CLEAR_PRODUCT:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

export default reducer;

const parseGoodsList = (data) => fp.pipe(
  fp.get('content'),
  fp.map(
    (item) => ({
      ...item,
      tnvedGroup: fp.get('tnved.rootCode', item),
      tnvedCode: fp.get('tnved.code', item),
      manufacturedCountry: item.manufacturedCountry.name,
      manufacturedCountryId: item.manufacturedCountry.id,
      status: HELPERS.getNameFromDictionary(item.status, statuses),
    }),
  ),
)(data);

const parseProduct = (item, type) => ({
  ...item,
  participantName: fp.get('participant.fullName', item),
  participantPhone: fp.get('participant.phone', item),
  participantId: fp.get('participant.id', item),
  tnvedGroup: fp.get('tnved.rootCode', item),
  tnved: fp.get('tnved.code', item),
  tnvedName: fp.get('tnved.name', item),
  tnvedRootName: fp.get('tnved.rootName', item),
  manufacturedCountry: item.manufacturedCountry.name,
  manufacturedCountryId: item.manufacturedCountry.id,
  status: HELPERS.getNameFromDictionary(item.status, statuses),
  statusValue: item.status,
  subGroup: fp.get('tnved.productSubgroup', item),
  ...getAttributes(item, type),
  statusDate: HELPERS.formatDate(item.statusDate),
});

const getAttributes = (item, type) => {
  const key = type === 'edit' ? 'id' : 'name';
  return {
    type: `${fp.get(`type.${key}`, item)}`,
    size: `${fp.get(`size.${key}`, item)}`,
    color: `${fp.get(`color.${key}`, item)}`,
    upperMaterial: `${fp.get(`upperMaterial.${key}`, item)}`,
    liningMaterial: `${fp.get(`liningMaterial.${key}`, item)}`,
    bottomMaterial: `${fp.get(`bottomMaterial.${key}`, item)}`,
  };
};
